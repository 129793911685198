import * as React from 'react';
import {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle,
    useMediaQuery
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import pix from '@/assets/img/pix.png';
import {Order, PixPayment} from '@/business/order/model';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SecurityUpdateGoodIcon from '@mui/icons-material/SecurityUpdateGood';
import Timer from '@/features/components/Timer';
import {MessageUtils} from '@/utils/message';
import {OrderStatus} from '@/business/order/domain';
import {getOrderStatus, payWithPix} from '@/business/order/service';
import message, {ignoreAxios} from '@/utils/errors';
import PixCode from '@/features/checkout/components/payment/PixCode';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useForm} from 'react-hook-form';
import Patterns from '@/utils/patterns';
import ControlledTextField from '@/features/components/ControlledTextField';
import {maskCpf} from '@/utils/masks/user-data';
import {updateUser} from '@/business/security/service';
import OrderCoupon from '@/features/checkout/components/OrderCoupon';
import {useAccountContext} from "@/utils/context/AccountContext";

const styles = {
    td: {
        paddingBottom: '10px'
    }
}

export interface Props {
    onComplete: VoidFunction,
    onBack: () => void,
    order: Order,
    defaultDocument?: string,
    onNewOrderSet: (order: Order) => void
}

interface ExpProps {
    onComplete: (p: PixPayment) => void,
    onBack: () => void,
    order: Order
    defaultDocument?: string,
    onNewOrderSet: (order: Order) => void
}

interface DataProps {
    onComplete: VoidFunction,
    order: number,
    payment: PixPayment,
    onNewOrderSet: (order: Order) => void
}

const PixPaymentExplanation = ({onComplete, onBack, order, defaultDocument, onNewOrderSet}: ExpProps) => {

    const isMobile = useMediaQuery('(max-width:475px)');
    const {user, setUser} = useAccountContext();

    const placeOrder = (data: any) => {
        const {cpf} = data;

        const place = () => {
            payWithPix(order.id).then(value => {
                onComplete(value);
            }).catch(ignoreAxios);
        }

        if (user && cpf !== user.cpf) {
            updateUser({
                name: user.name,
                cpf: cpf,
                email: user.email,
                phone: user.phone,
                gender: user.gender,
                birthDate: user.birthDate
            }).then(value => {
                setUser(value);
                place();
            }).catch(ignoreAxios);
        } else {
            place();
        }
    }

    const {
        control,
        handleSubmit,
        formState: {errors, isSubmitted}
    } = useForm({
        defaultValues: {
            cpf: user?.cpf ?? defaultDocument ?? ''
        }
    });

    return (
        <Box>
            <Card>
                <CardHeader
                    avatar={
                        <img src={pix} alt="" style={{
                            padding: '5px',
                            maxHeight: '32px',
                            border: '1px lightgray solid',
                            borderRadius: '50%'
                        }}/>
                    }
                    title="Pagamento instantâneo"
                    subheader="Confirmação em Segundos"
                />
                <CardContent>

                    <Typography variant="subtitle1">
                        <b>Dados do Comprador</b>
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit(placeOrder)} noValidate>
                        <ControlledTextField
                            style={{marginTop: '5px'}}
                            control={control}
                            name="cpf"
                            error={isSubmitted && !!errors.cpf}
                            helperText={message(errors.cpf, {patternMsg: 'O CPF informado é inválido'})}
                            margin="normal"
                            required
                            fullWidth
                            label="CPF"
                            formatter={maskCpf}
                            inputProps={{maxLength: 14}}
                            rules={{pattern: Patterns.cpf, required: true}}
                        />
                    </Box>

                    <Typography variant="subtitle1">
                        <b>Como pagar usando o Pix?</b>
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Ao finalizar o pedido, será gerado um <b>QR Code de pagamento</b>. Use o aplicativo do seu banco
                        ou aplicativo de pagamentos para escaneá-lo e realizar o pagamento.
                    </Typography>
                    <OrderCoupon order={order} onNewOrderSet={onNewOrderSet} containerSx={{mt: '20px'}}/>
                </CardContent>
            </Card>
            {!isMobile && (
                <Box sx={{textAlign: 'right', paddingTop: '15px'}}>
                    {onBack && (
                        <Button sx={{marginRight: '15px'}} variant="outlined"
                                onClick={onBack}>Voltar</Button>
                    )}
                    <Button variant="contained" type="submit" onClick={handleSubmit(placeOrder)}
                            startIcon={<QrCode2Icon/>}>
                        Gerar PIX para Pagamento
                    </Button>
                </Box>
            )}

            {isMobile && (
                <Box sx={{textAlign: 'center', paddingTop: '5px'}}>
                    <Button variant="contained" type="submit" onClick={handleSubmit(placeOrder)}
                            startIcon={<QrCode2Icon/>}
                            sx={{width: '100%'}}>
                        Gerar PIX para Pagamento
                    </Button>

                    {onBack && (
                        <Button sx={{mt: '10px'}} variant="outlined"
                                onClick={onBack}>Voltar</Button>
                    )}

                </Box>
            )}
        </Box>
    )
}

const PixPaymentData = ({onComplete, order, payment}: DataProps) => {

    const [waiting, setWaiting] = useState(true);
    const [expanded, setExpanded] = useState(true);

    const handleExpire = () => {
        MessageUtils.showError('O código do PIX expirou, você precisará solicitar um novo código, ou realizar novamente a compra caso ela tenha expirado.', 'PIX expirado').then(() => {
            window.location.reload();
        });
    }

    useEffect(() => {
        const interval = setInterval(() => checkOrderStatus(), 3000);
        return () => clearInterval(interval);
    });

    const checkOrderStatus = () => {
        if (!waiting) {
            return;
        }
        getOrderStatus(order).then((status) => {
            if (status === OrderStatus.PAID) {
                onComplete();
                setWaiting(false);
                return;
            }
            if (status === OrderStatus.CANCELLED) {
                setWaiting(false);
                MessageUtils.showError("O pedido foi cancelado. Pedidos que ficam sem pagamento por muito tempo podem ser cancelados automaticamente pelo sistema, assim como pedidos com problemas de pagamento. Você pode tentar realizar novamente o pedido e revisar as informações de pafamento.", "Pedido Cancelado").then(() => {
                    window.location.reload();
                })
            }
        });
    }

    return (
        <Dialog open={true} maxWidth="lg">
            <DialogTitle id="alert-dialog-title">
                Concluir Pagamento via PIX
            </DialogTitle>

            <DialogContent>
                <Box maxWidth="600px">
                    <Box sx={{
                        textAlign: 'center',
                        border: "1px lightgray solid",
                        paddingX: '10px',
                        paddingY: '5px',
                        borderRadius: '8px',
                        mt: '10px',
                        mb: '10px'
                    }}>

                        <Typography variant="subtitle2">
                            <b>Código para pagamento do Pedido</b>
                        </Typography>

                        <PixCode pix={payment}/>

                        {waiting && (
                            <Box mt="10px">
                                <b>Fique Atento:</b> Este código expira em <Timer expiresIn={payment.expiresIn}
                                                                                  onExpired={handleExpire}
                                                                                  style={{
                                                                                      fontSize: '2rem',
                                                                                      fontWeight: 'bold',
                                                                                      color: 'blue'
                                                                                  }}/>
                            </Box>
                        )}

                        <hr/>

                        <Box mb="10px">
                            <Typography variant="body1" color="text.secondary">
                                <b> Não feche essa janela</b>, seus ingressos serão apresentados aqui e enviados
                                para o seu Email após o pagamento.
                            </Typography>
                        </Box>
                    </Box>

                    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <b>Como pagar seu PIX?</b>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body1" color="text.secondary" component="div">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td style={styles.td}>
                                            <PhoneAndroidIcon/>
                                        </td>
                                        <td style={styles.td}>
                                            Abra o App do seu banco ou aplicativo de pagamentos e escolha <b>pagar
                                            com Pix</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={styles.td}>
                                            <QrCode2Icon/>
                                        </td>
                                        <td style={styles.td}>
                                            Selecione a opção <b>Pagar com QR Code e escaneie o código</b> ao lado
                                            ou <b>copie o código e selecione a opção Pix Copia e Cola</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={styles.td}>
                                            <SecurityUpdateGoodIcon/>
                                        </td>
                                        <td style={styles.td}>
                                            Confirme as informações e <b>finalize o pagamento</b>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Typography>

                            <Typography variant="body1" color="text.secondary">
                                Tudo pronto! Não precisa fechar essa janela, a compra será finalizada automaticamente
                                após a
                                realização do Pagamento.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

const PaymentWithPix = ({onComplete, onBack, order, defaultDocument, onNewOrderSet}: Props) => {
    const [pixInfo, setPixInfo] = useState<PixPayment | undefined>(order.openPayment);

    const handlePixCreated = (p: PixPayment) => {
        setPixInfo(p);
    }

    const handleComplete = () => {
        setPixInfo(undefined);
        onComplete();
    }

    return (
        <Box>
            <PixPaymentExplanation defaultDocument={defaultDocument} onComplete={handlePixCreated} onBack={onBack}
                                   order={order} onNewOrderSet={onNewOrderSet}/>
            {pixInfo && (
                <PixPaymentData onComplete={handleComplete} order={order.id} payment={pixInfo}
                                onNewOrderSet={onNewOrderSet}/>
            )}
        </Box>);
}

export default PaymentWithPix
