import {TicketDisplay, TicketUser} from '@/business/tickets/model';
import * as React from 'react';
import {useState} from 'react';
import {MessageUtils} from '@/utils/message';
import Box from '@mui/material/Box';
import {Divider} from '@mui/material';
import EditUser from '@/features/tickets/components/EditUser';
import TicketItem from '@/features/tickets/components/tickets-modal/TicketItem';

interface Props {
    initialTickets: TicketDisplay[],
    disabled: boolean,
}

const FreeTickets = ({initialTickets, disabled}: Props) => {

    const [tickets, setTickets] = useState(initialTickets);
    const [editing, setEditing] = useState<TicketDisplay | undefined>();

    if (!tickets.length) {
        return <></>
    }

    const handleUserUpdated = (id: number, user: TicketUser) => {
        tickets.find(value => value.id === id)!.ticketUser = {...user, valid: true};
        setTickets([...tickets]);
        MessageUtils.notifySuccess("Usuário do ingresso atualizado com sucesso");
        setEditing(undefined);
    }

    const handleDelete = (id: number) => {
        setTickets(tickets.filter(a => a.id !== id));
    }

    return (
        <fieldset style={{margin: '10px 0 0 0'}}>
            <legend>Ingressos Promocionais / Grátis</legend>
            {tickets.map((ticket, i) => (
                <Box key={ticket.id}>
                    <TicketItem ticket={ticket} onEdit={setEditing}
                                disabled={disabled}
                                onDelete={() => handleDelete(ticket.id)}/>
                    {i < tickets.length - 1 && (
                        <Divider sx={{mb: '10px', mt: '10px'}}/>
                    )}
                </Box>
            ))}
            {editing && (
                <EditUser ticket={editing} onCancel={() => setEditing(undefined)}
                          onUpdated={(user) => handleUserUpdated(editing!.id, user)}/>
            )}
        </fieldset>
    )
}


export default FreeTickets;
