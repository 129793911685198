import * as React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import Box from '@mui/material/Box';
import theme, {darkTheme} from '@/config/theme/theme';
import Card from '@mui/material/Card';
import LoginModal from '@/features/login/components/LoginModal';

interface Props {
    children: React.ReactNode,
}

const CenteredLayout = (props: Props) => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <Box sx={{flex: 1, display: 'flex', backgroundColor: '#272727', p: '10px'}}>
                    <Box style={{maxWidth: '600px', margin: 'auto'}}>
                        <Card sx={{padding: '20px'}}>
                            {props.children}
                        </Card>
                    </Box>
                </Box>
            </ThemeProvider>
            <ThemeProvider theme={darkTheme}>
                <LoginModal/>
            </ThemeProvider>
        </>
    )
}

export default CenteredLayout
