import React, {useEffect} from "react";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {PaymentMethod} from "@/business/payment/model";
import {formatDecimal} from "@/utils/formatters";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useSelectedPaymentMethodContext} from "@/features/checkout/contexts/selected-payment-method";

interface Props {
    methods: PaymentMethod[];
}

export const InstallmentSelection = ({methods}: Props) => {

    const {selectedPayment, setSelectedPayment, showTaxes} = useSelectedPaymentMethodContext();

    useEffect(() => {
        if (methods.length === 1 && !selectedPayment) {
            setSelectedPayment(methods[0]);
        }
    }, [methods, selectedPayment, setSelectedPayment]);

    if (methods.length < 2) {
        return null;
    }

    const installments = selectedPayment?.installments ?? "";

    const handleChange = (installments: number) => {
        const config = methods.find(value => value.installments === installments) ?? null;
        setSelectedPayment(config);
    }

    const getLabel = (config: PaymentMethod) => {
        if (!config.taxRate || !showTaxes) {
            return (
                <Typography variant="subtitle1" component="div">
                    {config.installments}x
                </Typography>
            );
        }

        return (
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" component="span">
                    {config.installments}x
                </Typography>
                <Typography variant="body2" component="span" sx={{fontSize: '10px', fontWeight: 300, ml: "15px"}}>
                    Com Taxa ({formatDecimal(config.taxRate * 100)}%)
                </Typography>
            </Box>
        );
    }

    return (
        <FormControl fullWidth sx={{mt: '10px'}}>
            <InputLabel id="form-label">Parcelamento</InputLabel>
            <Select label="Parcelamento" value={installments} onChange={(e) => handleChange(e.target.value as number)}>
                {methods.map(value => <MenuItem value={value.installments}>{getLabel(value)}</MenuItem>)}
            </Select>
        </FormControl>
    );
}