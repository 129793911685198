import {PixPayment} from '@/business/order/model';
import CopiableText from '@/features/components/CopiableText';
import * as React from 'react';
import {useState} from 'react';
import {useMediaQuery} from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {preventDefault} from '@/utils/functions';

interface Props {
    pix: PixPayment
}

const PixCode = ({pix}: Props) => {

    const isMobile = useMediaQuery('(max-width:475px)');
    const [expanded, setExpanded] = useState(!isMobile);

    return (
        <>
            {expanded && (
                <img src={pix.codeUrl} style={{maxWidth: '100%'}} alt="PIX QR Code"/>
            )}
            <div style={{overflowWrap: 'break-word'}}>
                <CopiableText text={pix.qrCode}/>
            </div>
            <Link onClick={preventDefault(() => setExpanded(!expanded))}>
                <Typography component="span" variant="body2"
                            color="info.light"
                            sx={{
                                display: 'block',
                                cursor: 'pointer'
                            }}>
                    {expanded ? "Ocultar QRCode" : "Ver QRCode"}
                </Typography>
            </Link>
        </>
    )
}

export default PixCode;
