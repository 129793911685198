import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogHeader from '@/features/components/DialogHeader';
import {Dialog, DialogActions, DialogContent} from '@mui/material';
import Button from '@mui/material/Button';

interface Props {
    open: boolean;
    onClose: VoidFunction
}

const Rule = ({title, text}: { title: string, text: string }) => {
    return (
        <Box sx={{mb: '10px'}}>
            <Typography variant="subtitle1">
                <b>{title}</b>
            </Typography>
            <Typography variant="body2">
                {text}
            </Typography>
        </Box>
    )
}

const TablesRules = ({open, onClose}: Props) => {

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogHeader onClose={onClose}>
                Reserva de Mesas
            </DialogHeader>
            <DialogContent>
                <Box>
                    <Typography variant="body2" sx={{mb: '10px'}}>
                        A Reserva de Mesa é a melhor forma de você curtir o evento do jeito que você quer, a vontade e
                        com o conforto de ter uma mesa só para você e seus amigos.
                    </Typography>
                    <Typography variant="body2" sx={{mb: '10px'}}>
                        E aí, vai ficar de fora? Reserve agora mesmo a sua mesa e curta o evento como um rei.
                    </Typography>

                    <Rule title="A Mesa é Sua"
                          text="Ao reservar a mesa, seu nome ficará vinculado à sua mesa escolhida, o organizador se comprometerá em deixar reservada. Ao chegar no local, avise na portaria que reservou a mesa através do aplicativo MyPromoter e informe seu nome, que sua mesa estará esperando você!"/>
                    <Rule title="Chegue Cedo"
                          text="Ao reserva sua mesa, ela ficará te esperando, porém não pra sempre. Cada evento tem um limite de tempo que a mesa ficará te esperando. Caso você não chegue na hora correta, a casa poderá disponibilizar para outros clientes que já estão no evento. Há também a possibilidade de a Casa não por tempo limite, mas fique de olho!"/>
                    <Rule title="Remova Quando Quiser"
                          text="Remova quando quiser sua reserva de mesa, mas seja legal, saiba que a Casa forneceu essa forma de curtir a festa pensando em você. Então seja legal e não cancele de última hora!"/>
                    <Rule title="Responsabilidade"
                          text="A disponibilização da mesa no dia e hora informado é responsabilidade única e exclusiva da Casa que sediará o evento. A MyPromoter somente auxilia as partes para melhor comunicação."/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>Fechar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TablesRules
