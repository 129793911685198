import * as React from 'react';
import {useState} from 'react';
import Typography from '@mui/material/Typography';
import {leaveVipList} from '@/business/tickets/service';
import {ignoreAxios} from '@/utils/errors';
import {Dialog, DialogActions, DialogContent, DialogContentText} from '@mui/material';
import Button from '@mui/material/Button';
import {formatDateTime} from '@/utils/formatters';
import Link from '@mui/material/Link';
import DialogHeader from '@/features/components/DialogHeader';

export interface Props {
    event: number,
    onLeft: () => void;
    limit: Date;
}

const LeaveVipList = ({event, onLeft, limit}: Props) => {

    const [leaving, setLeaving] = useState(false);

    const handleConfirm = () => {
        leaveVipList(event).then(() => {
            setLeaving(false);
            onLeft();
        }).catch(ignoreAxios);
    }

    const handleOpenConfirm = (e: any) => {
        e.preventDefault();
        setLeaving(true);
    }

    return (
        <>
            <Typography variant="body1">
                Teve um imprevisto? Não se preocupe, você pode &nbsp;
                <Link href="#" variant="body1" onClick={handleOpenConfirm}>
                    <b>retirar seu nome da lista.</b>
                </Link>
            </Typography>
            <Dialog
                open={leaving}
                onClose={() => setLeaving(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogHeader onClose={() => setLeaving(false)}>
                    Sair da Lista VIP?
                </DialogHeader>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sentimos muito de vê-lo partir, mas lembre-se, você pode incluir seu nome novamente
                        até {formatDateTime(limit)}. Retirar nome da lista?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLeaving(false)}>Não, vou pensar melhor</Button>
                    <Button onClick={handleConfirm} autoFocus>
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default LeaveVipList
