import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {ignoreAxios} from '@/utils/errors';
import {NotificationOptions, User} from '@/business/security/model';
import {getNotificationOptions, saveNotificationOptions} from '@/business/security/service';
import {FormControlLabel, Skeleton, Switch} from '@mui/material';

interface Props {
    user: User;
}

const UserNotificationOptions = ({user}: Props) => {

    const [options, setOptions] = useState<NotificationOptions | undefined>();

    useEffect(() => {
        setOptions(undefined);
        getNotificationOptions()
            .then(value => setOptions(value))
            .catch(ignoreAxios);
    }, [user]);

    if (!options) {
        return <Skeleton/>
    }

    const handleChange = (changes: any) => {
        saveNotificationOptions({...options, ...changes})
            .then(() => {
                setOptions({...options, ...changes});
            })
            .catch(ignoreAxios)
    }

    return (
        <Box display="flex" gap="15px">
            <Box>
                <FormControlLabel control={<Switch checked={options.emailsFromVisitedClients}
                                                   onChange={(e) => handleChange({
                                                       emailsFromVisitedClients: e.target.checked
                                                   })}/>}
                                  label="Receber Email de Organizadores e Casas nas quais já comprei ingressos"/>
            </Box>
        </Box>
    )
}

export default UserNotificationOptions
