import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import AccountDataForm from '@/features/account/components/AccountDataForm';
import {User} from '@/business/security/model';
import Button from '@mui/material/Button';
import ChangePasswordModal from '@/features/account/components/ChangePasswordModal';

export interface Props {
    user: User

}

const AccountData = ({user}: Props) => {

    const [changePassword, setChangePassword] = useState(false);

    return (
        <Box>
            <AccountDataForm user={user} enabled={false}
                             actions={<Button variant="outlined" onClick={() => setChangePassword(true)}>Alterar
                                 Senha</Button>}/>
            {changePassword && <ChangePasswordModal onClose={() => setChangePassword(false)}/>}
        </Box>
    )
}

export default AccountData
