import * as React from 'react';
import Box from '@mui/material/Box';
import {Client} from '@/business/client/model';
import EventList from '@/features/event/components/listing/EventList';
import {EventFilters} from '@/business/event/service';

export interface Props {
    client: Client;
    current: boolean;
}

const ClientEvents = ({client, current}: Props) => {
    const filter: EventFilters = {
        client: client.id,
        past: !current
    };
    return (
        <Box sx={{pt: '10px'}}>
            <EventList filters={filter} loader={false}></EventList>
        </Box>
    )
}

export default ClientEvents;
