import * as React from 'react';
import {useState} from 'react';
import {Event} from '@/business/event/model';
import Card from '@mui/material/Card';
import {CardHeader} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {createOrder} from '@/business/order/service';
import {ignoreAxios} from '@/utils/errors';
import {CreateOrderItemModel} from '@/business/order/model';
import TicketBlockSelection, {clearSavedState} from '@/features/event/components/commons/TicketBlockSelection';
import {ProcessingFeeInfo} from "@/features/checkout/components/utils/ProcessingFeeInfo";
import {useAccountContext} from "@/utils/context/AccountContext";

export interface Props {
    event: Event;
    link: string | null
}

const EventTickets = ({event, link}: Props) => {

    const [items, setItems] = useState<CreateOrderItemModel[]>([]);
    const navigate = useNavigate();
    const {login, user} = useAccountContext();

    if (!event.services.tickets || !event.ticketsConfiguration) {
        return <></>
    }

    const handleOrderClick = () => {
        if (!items.length) {
            return;
        }
        if (!user) {
            login.show();
            return;
        }
        createOrder({event: event.id, items: items}, link).then(value => {
            clearSavedState(event.id);
            navigate(`/pedido/${value.id}`, {state: value});
        }).catch(ignoreAxios);
    }

    return (
        <Card sx={{borderRadius: '10px'}}>
            <CardHeader title={
                <Typography sx={{color: 'white', fontWeight: 'bold'}}>
                    INGRESSOS
                </Typography>
            } sx={{backgroundColor: "#4C576C", color: 'white', fontWeight: 'bold !important'}}/>
            <CardContent sx={{paddingTop: 0}}>
                <TicketBlockSelection event={event.id} blocks={event.ticketsConfiguration.ticketBlocks}
                                      onChange={setItems}/>
                <ProcessingFeeInfo/>
                <Button variant="contained" onClick={handleOrderClick} sx={{width: '100%'}} disabled={!items.length}
                        id="close-order">
                    Fechar Pedido
                </Button>
            </CardContent>
        </Card>
    )
}

export default EventTickets
