import * as React from 'react';
import Box from '@mui/material/Box';
import logo from "@/assets/img/logo-full-alt.png";
import logoCollapsed from "@/assets/img/logo-collapsed.png";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import UserMenu from '@/layouts/header/user';
import {useMobile} from '@/utils/hooks/useMobile';
import {useWhiteLabelClientContext} from "@/layouts/contexts/white-label-client/WhiteLabelClientContext";

export interface Props {
    logged?: boolean;
}

const Logo = () => {
    const isMobile = useMobile();
    const {client} = useWhiteLabelClientContext();
    if (!client) {
        return <img src={isMobile ? logoCollapsed : logo} style={{maxHeight: '45px'}}
                    className="sm"
                    alt="myPromoter - A sua plataforma de eventos"/>
    }
    return <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
        <img src={client.logo} style={{maxHeight: '45px'}} className="sm" alt={client.name}/>
        {!isMobile && (
            <div style={{fontWeight: 'bold', fontSize: '1.2rem'}}>{client.name}</div>
        )}
    </div>
}

const Header = ({logged}: Props) => {
    return (
        <div>
            <AppBar position="fixed">
                <Box sx={{padding: '10px 20px'}}>
                    <Toolbar disableGutters>
                        <Box display="flex" justifyContent="space-between" flexGrow="1" flexWrap="wrap" gap="10px">
                            <Box>
                                <a href="/" style={{textDecoration: 'none', color: 'unset'}}>
                                    <Logo/>
                                </a>
                            </Box>
                            <UserMenu logged={logged}></UserMenu>
                        </Box>
                    </Toolbar>
                </Box>
            </AppBar>
            <Box sx={{height: '84px'}}/>
        </div>
    )
}

export default Header
