export function setWithExpiry(key: string, value: any, ttlSeconds: number) {
    const now = new Date()
    const item = {
        value: value,
        expiry: now.getTime() + (ttlSeconds * 1000),
    }
    localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key: string): any | undefined {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
        return undefined;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return undefined
    }
    return item.value
}
