import * as React from 'react';
import {TicketDisplay, TicketUser} from '@/business/tickets/model';
import {Dialog, DialogContent} from '@mui/material';
import DialogHeader from '@/features/components/DialogHeader';
import TicketUserForm from '@/features/checkout/components/TicketUserForm';

interface Props {
    ticket: TicketDisplay,
    onCancel: () => void,
    onUpdated: (user: TicketUser) => void,
}

const EditUser = ({ticket, onCancel, onUpdated}: Props) => {
    return (
        <Dialog onClose={onCancel} open={true}>
            <DialogHeader onClose={onCancel}>
                Editar Usuário do ingresso {ticket.code}
            </DialogHeader>
            <DialogContent>
                <TicketUserForm id={ticket.id} onComplete={onUpdated} autoFillLabel="O ingresso é pra você?"
                                user={ticket.ticketUser}
                                onBack={onCancel} nextText="Salvar" backText="Cancelar"/>
            </DialogContent>
        </Dialog>
    )
}

export default EditUser;
