import {Ticket2, TicketUser} from '@/business/tickets/model';
import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import * as React from 'react';
import TicketUserForm from '@/features/checkout/components/TicketUserForm';

interface Props {
    ticket: Ticket2;
    onCreated: (guest: Ticket2) => void,
    onCancel: VoidFunction
}

const TicketGuestsCreate = ({ticket, onCreated, onCancel}: Props) => {

    const handleSaved = (user: TicketUser) => {
        ticket.ticketUser = user;
        onCreated(ticket);
    }

    return <Dialog
        open={true}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            Adicionar Convidado
        </DialogTitle>
        <DialogContent>
            <TicketUserForm id={ticket.id} onComplete={handleSaved} user={ticket.ticketUser} backText="Cancelar"
                            onBack={onCancel} nextText="Salvar"/>
        </DialogContent>
    </Dialog>
}

export default TicketGuestsCreate


