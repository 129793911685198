import * as React from 'react';
import {useEffect, useState} from 'react';
import MainLayout from '@/layouts/main';
import {Container, Divider, Grid, useMediaQuery, useTheme} from '@mui/material';
import {useParams} from 'react-router-dom';
import {findById} from '@/business/event/service';
import {Event} from '@/business/event/model';
import EventDetailsCover from '@/features/event/components/details/EventDetailsCover';
import EventInfo from '@/features/event/components/details/EventInfo';
import EventDescription from '@/features/event/components/details/EventDescription';
import EventFooter from '@/features/event/components/details/EventFooter';
import EventTickets from '@/features/event/components/details/EventTickets/EventTickets';
import Box from '@mui/material/Box';
import EventVipList from '@/features/event/components/details/EventVipList/EventVipList';
import EventTables from '@/features/event/components/details/EventTables';
import EmptyList from '@/features/components/EmptyList';
import EventPromoterOptions from '@/features/event/components/details/EventPromoterOptions';
import {useQuery} from '@/utils/hooks/useQuery';
import {setTrackedEvent} from '@/utils/tracking';
import {getLink} from '@/utils/link-storage';
import {SelectedPaymentMethodProvider} from "@/features/checkout/contexts/selected-payment-method";
import {YourPromoter} from "@/features/event/components/commons/YourPromoter";

const Description = ({event}: { event: Event }) => {
    return (
        <>
            {(event.description || event.ticketsConfiguration?.map || event.videoUrl) && (
                <fieldset style={{marginTop: '20px', borderColor: "rgba(0, 0, 0, 0.12)"}}>
                    <EventDescription event={event}/>
                </fieldset>
            )}
        </>
    );
}

const Services = ({event, link}: { event: Event, link: string | null }) => {
    return (
        <SelectedPaymentMethodProvider event={event.id} showTaxes={!event.taxPaidByClient}>
            {event.services.promoters && link && (
                <YourPromoter link={link}/>
            )}
            <EventPromoterOptions event={event}/>
            {event.services.tickets && event.ticketsConfiguration && (
                <Box sx={{mb: '20px'}}>
                    <EventTickets event={event} link={link}></EventTickets>
                </Box>
            )}
            {event.services.tables && event.tablesConfiguration && (
                <Box sx={{mb: '20px'}}>
                    <EventTables event={event} link={link}></EventTables>
                </Box>
            )}
            {event.services.vipList && event.vipListConfiguration && (
                <Box sx={{mb: '20px'}}>
                    <EventVipList event={event} link={link}></EventVipList>
                </Box>
            )}
        </SelectedPaymentMethodProvider>
    )
}

const Mobile = ({event, link}: { event: Event, link: string | null }) => {
    return (
        <>
            <EventInfo {...event} host={event.client.name}/>
            <Box mt="10px">
                <Services event={event} link={link}/>
            </Box>
            <Description event={event}/>
        </>
    )
}

const Desktop = ({event, link}: { event: Event, link: string | null }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={8}>
                <Box sx={{paddingRight: '50px'}}>
                    <EventInfo {...event} host={event.client.name}/>
                    <Description event={event}/>
                </Box>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
                <Services event={event} link={link}/>
            </Grid>
        </Grid>
    )
}

const EventPage = () => {
    const theme = useTheme();
    const [loaded, setLoaded] = useState(false);
    const [event, setEvent] = useState<Event | undefined>();
    let {id} = useParams();
    const isSm = useMediaQuery(theme.breakpoints.down('md'));
    const link = useQuery().get('link') ?? getLink(id ?? "");

    useEffect(() => {
        setLoaded(false);
        if (id) {
            findById(id, link).then(value => {
                setEvent(value);
                setLoaded(true);
                setTrackedEvent(value);
            });
        } else {
            setEvent(undefined);
            setLoaded(true);
        }
    }, [id, link]);

    return (
        <MainLayout paddingTop={0}>
            {event && (
                <>
                    <EventDetailsCover event={event}></EventDetailsCover>
                    <Container sx={{paddingTop: '20px'}}>

                        {isSm ? <Mobile event={event} link={link}/> : <Desktop event={event} link={link}/>}

                        <Divider sx={{my: '2rem'}}></Divider>

                        <EventFooter event={event!}/>
                    </Container>
                </>
            )}
            {!event && loaded && (
                <EmptyList title="Evento não encontrado"
                           text="O evento que você está procurando não existe, ou não está mais disponível na plataforma"/>
            )}
        </MainLayout>
    )
}

export default EventPage
