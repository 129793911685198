import {AxiosError} from 'axios';
import {MessageUtils} from '@/utils/message';

function show(message: string) {
    MessageUtils.notifyError(message);
}

export function handle400(error: AxiosError) {
    const data: any = error.response?.data;
    if (!data) {
        show("Tivemos um erro inexperado ao realizar a operação")
        return;
    }
    if (data.validations && data.validations.length) {
        for (const value of data.validations) {
            show(value);
        }
    } else {
        show(data.message)
    }
}

export function handle500(error: AxiosError) {
    const data: any = error.response?.data;
    show(data.message ?? 'Tivemos um erro ao realizar a operação, tente novamente mais tarde.')
}
