import * as React from 'react';
import {useState} from 'react';
import {TokenMetadata} from '@/business/security/model';
import RecoveryEmailForm from '@/features/login/components/recovery/RecoveryEmailForm';
import RecoveryTokenForm from '@/features/login/components/recovery/RecoveryTokenForm';
import RecoveryPasswordForm from '@/features/login/components/recovery/RecoveryPasswordForm';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export interface Props {
    onComplete: () => void
    onCancel: () => void,
    onCreateAccount: () => void,
    email?: string
}

enum Step {
    EMAIL,
    CODE,
    PASSWORD
}

const PasswordRecoveryFlow = ({onCancel, onComplete, onCreateAccount, email}: Props) => {
    const [step, setStep] = useState(Step.EMAIL);
    const [meta, setMeta] = useState<TokenMetadata | undefined>();
    const [token, setToken] = useState('');

    const onTokenSent = (meta: TokenMetadata) => {
        setMeta(meta);
        setStep(Step.CODE);
    }

    const onTokenValidated = (token: string) => {
        setToken(token);
        setStep(Step.PASSWORD);
    }

    return <>
        {step === Step.EMAIL &&
            <RecoveryEmailForm onSent={onTokenSent} onCreateAccount={onCreateAccount} email={email}/>}
        {step === Step.CODE && meta && <RecoveryTokenForm metadata={meta} onValidated={onTokenValidated}/>}
        {step === Step.PASSWORD && meta && token &&
            <RecoveryPasswordForm metadata={meta} token={token} onComplete={onComplete}/>}
        <Box sx={{textAlign: "center"}}>
            <Button variant="text" sx={{mb: 2}} onClick={onCancel}>
                Voltar para o Login
            </Button>
        </Box>

    </>
}

export default PasswordRecoveryFlow;
