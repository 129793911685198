export enum OrderStatus {
    OPEN = 'OPEN',
    PAID = 'PAID',
    CANCELLED = 'CANCELLED'
}

export enum ItemType {
    TICKET = 'TICKET',
    TABLE = 'TABLE',
    VIP_LIST = 'VIP_LIST'
}
