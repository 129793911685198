import axios from 'axios';
import {handle400, handle500} from './handlers';
import {getToken} from '@/business/security/service';

let CLIENT_ID: number | null = null;

export const setClientId = (clientId: number | null) => {
    CLIENT_ID = clientId;
}

const configureResponseInterceptors = () => {
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (400 === error.response?.status) {
            handle400(error)
        }
        if (500 === error.response?.status) {
            handle500(error)
        }
        if (503 === error.response.status || 502 === error.response.status) {
            if (!error.request.responseURL.endsWith('/account')) {
                return window.location.href = '/503'
            }
        }
        return Promise.reject(error);
    });
}

const configureRequestInterceptors = () => {
    axios.interceptors.request.use((config) => {
        if (!config.headers["no_auth"]) {
            const token = getToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        if (CLIENT_ID) {
            config.headers['x-client-id'] = CLIENT_ID;
            config.headers['x-platform'] = 'WHITE_LABEL';
        } else {
            config.headers['x-platform'] = 'PUBLIC_PLATFORM';
        }
        return config;
    });
}

const configureAxios = (baseUrl: string) => {
    axios.defaults.baseURL = baseUrl;
    configureResponseInterceptors();
    configureRequestInterceptors();
}

export default configureAxios
