import * as React from 'react';
import {useState} from 'react';
import {Event} from '@/business/event/model';
import Card from '@mui/material/Card';
import {CardHeader, Chip} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {createLink} from '@/business/event/service';
import {ignoreAxios} from '@/utils/errors';
import CopiableText from '@/features/components/CopiableText';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {deepOrange, deepPurple, green} from '@mui/material/colors';
import {LocalActivityOutlined} from "@mui/icons-material";


export interface Props {
    event: Event
}

const EventPromoterOptions = ({event}: Props) => {

    const [link, setLink] = useState("");

    if (!event.services.promoters || !event.userStatus.promoter.active) {
        return <></>;
    }
    const generate = () => {
        createLink(event.id).then(link => {
            setLink(link);
        }).catch(ignoreAxios);
    }

    return (
        <Card sx={{borderRadius: '10px', mb: '20px'}}>
            <CardHeader title={
                <Typography sx={{color: 'white', fontWeight: 'bold'}}>
                    INFORMAÇÕES DO PROMOTER
                </Typography>
            } sx={{backgroundColor: "#4C576C", color: 'white', fontWeight: 'bold !important'}}/>
            <CardContent>
                <Box display='flex' justifyContent="space-evenly" mb={2}>
                    <Box textAlign="center">
                        <Avatar
                            sx={{
                                margin: 'auto',
                                bgcolor: green[500]
                            }}>{event.userStatus.promoter.vipList?.quantity}</Avatar>
                        <Chip label="Lista VIP" icon={<LocalActivityOutlined/>} sx={{mt: 1}}/>
                    </Box>
                    <Box textAlign="center">
                        <Avatar
                            sx={{margin: 'auto', bgcolor: deepOrange[500]}}>{event.userStatus.promoter.views}</Avatar>
                        <Chip label="Views" icon={<RemoveRedEyeIcon/>} sx={{mt: 1}}/>
                    </Box>
                    <Box textAlign="center">
                        <Avatar
                            sx={{margin: 'auto', bgcolor: deepPurple[500]}}>{event.userStatus.promoter.sells}</Avatar>
                        <Chip label="Vendas" icon={<ShoppingCartIcon/>} sx={{mt: 1}}/>
                    </Box>
                </Box>
                {!link && (
                    <Button variant="contained" onClick={generate} sx={{width: '100%'}}>
                        Criar Link de Indicação
                    </Button>
                )}
                {link && (
                    <>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>Código de Indicação</Typography>
                        <CopiableText text={link}/>
                    </>
                )}
            </CardContent>
        </Card>
    )
}

export default EventPromoterOptions
