import * as React from "react";
import {useMemo} from "react";
import {formatDecimal} from "@/utils/formatters";
import Typography from "@mui/material/Typography";
import {useTaxRate} from "@/features/checkout/hooks/useTaxRate";

interface Props {
    value: number;
}

export const TaxLabel = ({value}: Props) => {

    const {getTaxValue} = useTaxRate();
    const tax = useMemo(() => getTaxValue(value), [getTaxValue, value]);

    if (!tax) {
        return null;
    }

    return <Typography variant="body2" fontSize="1.2vh" color="text.secondary"
                       component="span">
        (+
        R$ {formatDecimal(tax)} taxa)
    </Typography>
}