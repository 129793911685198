import * as React from 'react';
import {useEffect, useState} from 'react';
import MainLayout from '@/layouts/main';
import {Container, Tab, Tabs} from '@mui/material';
import {Client} from '@/business/client/model';
import {findClientById} from '@/business/client/service';
import {useParams} from 'react-router-dom';
import {ignoreAxios} from '@/utils/errors';
import ClientInfo from '@/features/client/ClientData';
import ClientEvents from '@/features/client/ClientEvents';
import Box from '@mui/material/Box';

const ClientPage = () => {

    const [client, setClient] = useState<Client>();
    let {id} = useParams();
    const [index, setIndex] = React.useState(0);
    const [past, setPast] = useState(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 1 && !past) {
            setPast(true);
        }
        setIndex(newValue);
    };

    useEffect(() => {
        findClientById(parseInt(id ?? "", 10)).then(value => {
            setClient(value);
        }).catch(ignoreAxios);
    }, [id]);

    return (
        <MainLayout logged={true}>
            {client && (
                <>
                    <Container>
                        <ClientInfo client={client}/>
                        <Tabs value={index} onChange={handleChange} aria-label="Eventos">
                            <Tab label="Disponíveis"/>
                            <Tab label="Encerrados"/>
                        </Tabs>

                        <Box display={index === 0 ? 'block' : 'none'}>
                            <ClientEvents client={client} current={true}/>
                        </Box>
                        {past && (
                            <Box display={index === 1 ? 'block' : 'none'}>
                                <ClientEvents client={client} current={false}/>
                            </Box>
                        )}
                    </Container>
                </>
            )}
        </MainLayout>
    )
}

export default ClientPage
