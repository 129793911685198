import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {formatDateTime, formatEventAddress} from '@/utils/formatters';
import StoreIcon from '@mui/icons-material/Store';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Button from '@mui/material/Button';
import {Address} from '@/business/location/model';
import Classification from '@/features/event/components/commons/Classification';

export interface Props {
    address: Address;
    classification: number;
    name: string;
    date: Date;
    host: string;
}

const EventInfo = (event: Props) => {

    let line1 = formatEventAddress(event.address)

    return (
        <Box>
            <Typography variant="h1">
                {event.name}
            </Typography>

            <Box sx={{paddingBottom: '10px', marginTop: '20px'}}>
                <Classification classification={event.classification}></Classification>
                <Typography variant="body1" component="span" paddingLeft="5px">
                    <b>Classificação Indicativa</b>
                </Typography>
            </Box>

            <table>
                <tbody>
                <tr>
                    <td style={{textAlign: 'center'}}>
                        <CalendarTodayIcon/>
                    </td>
                    <td>
                        <Typography variant="body2">
                            {formatDateTime(event.date)}
                        </Typography>
                    </td>
                </tr>
                <tr>
                    <td style={{textAlign: 'center'}}>
                        <StoreIcon/>
                    </td>
                    <td>
                        <Typography variant="body2">
                            Organizado por {event.host}
                        </Typography>
                    </td>
                </tr>
                <tr>
                    <td style={{verticalAlign: 'top', textAlign: 'center'}}>
                        <PinDropIcon/>
                    </td>
                    <td>
                        <Typography variant="body2">
                            {line1}
                        </Typography>
                        <Button
                            href={`https://www.google.com/maps/search/?api=1&query=${event.address.lat},${event.address.lng}`}
                            target="_blank" rel="noreferrer"
                            size="small">Localização do endereço
                        </Button>
                    </td>
                </tr>
                </tbody>
            </table>
        </Box>
    )
}

export default EventInfo
