import {useTheme} from '@mui/material';
import * as React from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import tiktok from '@/assets/img/tiktok.svg';

export enum Network {
    FACEBOOK,
    INSTAGRAM,
    LINKED_IN,
    TIKTOK,
    WEBSITE
}

const NetworkIcons: { [key in Network]: () => React.ReactNode } = {
    [Network.FACEBOOK]: () => <FacebookIcon/>,
    [Network.INSTAGRAM]: () => <InstagramIcon/>,
    [Network.LINKED_IN]: () => <LinkedInIcon/>,
    [Network.TIKTOK]: () => (
        <img src={tiktok} alt="TikTok"
             width={18}
             height={18}
             style={{margin: '2px'}}/>),
    [Network.WEBSITE]: () => <LanguageIcon/>,
};

export interface Props {
    network: Network;
    link: string;
    margin?: string,
}

const SocialButton = ({network, link, margin}: Props) => {
    const theme = useTheme();
    return (
        <a href={link} target="_blank"
           style={{color: theme.palette.text.primary, margin: margin}} rel="noreferrer">
            {NetworkIcons[network]()}
        </a>
    )
}

export default SocialButton;
