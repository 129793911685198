import * as React from 'react';
import {Environment} from '@/config';
import message from '@/utils/errors';
import TextField from '@mui/material/TextField';
import {useForm} from 'react-hook-form';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {SxProps} from '@mui/system/styleFunctionSx';
import {Theme} from '@mui/material/styles';

interface Props {
    current?: string,
    onApply: (code: string) => void,
    onRemove: VoidFunction,
    containerSx?: SxProps<Theme>;
}

const CouponInput = ({current, onApply, onRemove, containerSx}: Props) => {

    const {register, handleSubmit, formState: {errors, isSubmitted}} = useForm({defaultValues: {code: ''}});

    if (!Environment.Features.Coupon) {
        return <></>
    }

    const apply = ({code}: any) => {
        onApply(code);
    }

    const remove = () => {
        onRemove();
    }

    if (!current) {
        return (
            <Box component="form" onSubmit={handleSubmit(apply)} noValidate display="flex" sx={containerSx}>
                <Box flex="1">
                    <TextField
                        style={{margin: 0}}
                        error={isSubmitted && !!errors.code}
                        helperText={message(errors.code)}
                        margin="normal"
                        fullWidth
                        id="code"
                        label="Cupom de Desconto"
                        autoComplete="coupon"
                        {...register("code", {required: true})}
                    />
                </Box>
                <Box sx={{paddingLeft: '10px'}}>
                    <Button variant="outlined" onClick={handleSubmit(apply)}
                            sx={{height: '100%', maxHeight: '55px'}}>Aplicar</Button>
                </Box>
            </Box>
        )
    }

    return (
        <Box display="flex" sx={containerSx}>
            <Box flex="1">
                <Typography variant="subtitle1">
                    Cupom Aplicado: <b>{current}</b>
                </Typography>
            </Box>
            <Box sx={{paddingLeft: '10px'}}>
                <Button variant="outlined" onClick={remove}
                        sx={{height: '100%', maxHeight: '55px'}}>Remover</Button>
            </Box>
        </Box>
    )
}

export default CouponInput;
