import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {OverridableStringUnion} from '@mui/types';
import {Variant} from '@mui/material/styles/createTypography';
import {TypographyPropsVariantOverrides} from '@mui/material/Typography/Typography';
import {SxProps} from '@mui/system';
import {Theme} from '@mui/material/styles';

interface Props {
    label: string;
    value: string | React.ReactNode;
    marginTop?: string | number;
    valueVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
    valueSx?: SxProps<Theme>;
}

const ValueLabel = ({label, value, marginTop, valueVariant, valueSx = {}}: Props) => {
    return (
        <Box>
            <Typography variant="subtitle1"
                        sx={{lineHeight: '1', marginTop: marginTop}}>
                <b>{label}</b>
            </Typography>

            <Typography variant={valueVariant ?? 'subtitle1'} sx={{lineHeight: '1.2', ...valueSx}}>
                {value}
            </Typography>
        </Box>
    )
}

export default ValueLabel;
