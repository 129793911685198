import Button from '@mui/material/Button';
import * as React from 'react';
import {useState} from 'react';
import {Dialog, DialogContent, DialogContentText} from '@mui/material';
import logo from '@/assets/img/logo-full-alt.png';
import Box from '@mui/material/Box';
import {variables} from '@/config';

const BecomeAProducerButton = () => {

    const [visible, setVisible] = useState(false);

    return <>
        <Button variant="contained" sx={{mr: '10px'}} onClick={() => setVisible(true)}>
            Produzir Eventos
        </Button>
        {visible && (
            <Dialog onClose={() => setVisible(false)} open={true}>
                <DialogContent>
                    <Box textAlign={'center'}>
                        <img src={logo} style={{maxHeight: '70px'}} alt="myPromoter Logo"/>
                    </Box>
                    <DialogContentText sx={{textAlign: 'center', mt: '15px'}}>
                        Transforme a Gestão de Eventos com a myPromoter! Integre todos os principais serviços da
                        gestão
                        do seu evento em uma plataforma única, moderna e pensada para facilitar a vida do
                        organizador de
                        eventos noturnos e entregar a melhor experiência ao usuário.
                    </DialogContentText>
                    <DialogContentText>
                        <ul>
                            <li>
                                Gestão e desempenho de promoters
                            </li>
                            <li>
                                Lista VIP e Reserva de Mesas do Evento
                            </li>
                            <li>
                                Ingresso Presencial via Plataforma
                            </li>
                            <li>
                                Venda online de ingressos através de PIX e Cartão de Crédito
                            </li>
                            <li>
                                Gestão de entrada e análise em tempo real do público
                            </li>
                        </ul>
                    </DialogContentText>
                    <DialogContentText sx={{textAlign: 'center'}}>
                        Entre em contato com a gente para entendermos como podemos te ajudar a transformar o seu
                        evento
                        em um sucesso.
                    </DialogContentText>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 1}}
                        href={variables.contactUrl} rel="noreferrer" target="_blank"
                    >
                        Entrar em contato
                    </Button>
                </DialogContent>
            </Dialog>
        )}
    </>
}

export default BecomeAProducerButton;
