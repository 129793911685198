import * as React from 'react';
import {Divider, Grid} from '@mui/material';
import Typography from '@mui/material/Typography';
import diners from '@/assets/img/cards/diners.png';
import american_express from '@/assets/img/cards/american_express.png';
import mastercard from '@/assets/img/cards/mastercard.png';
import visa from '@/assets/img/cards/visa.png';
import discover from '@/assets/img/cards/discover.png';
import google from '@/assets/img/google-safe.png';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {variables} from '@/config';
import {Event} from '@/business/event/model';
import Avatar from '@mui/material/Avatar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {styled} from '@mui/system';
import {useNavigate} from 'react-router-dom';

export interface Props {
    event: Event
}

const ClientAvatar = styled(Avatar)(({theme}) => ({
    width: 96,
    height: 96,

    [theme.breakpoints.down('sm')]: {
        margin: 'auto',
        marginBottom: '24px',
    },
}));

const EventFooter = ({event}: Props) => {
    const navigate = useNavigate();
    return (
        <Box>
            <Box sx={{paddingBottom: '2rem'}}>
                <Typography variant="h5" sx={{marginBottom: '24px'}}>
                    Sobre o Organizador
                </Typography>
                <Box sx={{display: {xs: 'block', sm: 'flex'}, textAlign: 'center'}}>
                    <ClientAvatar alt="" src={event.client.logo}/>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        marginLeft: '24px',
                    }}>
                        <Typography variant="h5">
                            {event.client.name}
                        </Typography>
                        <Button variant="outlined" startIcon={<CalendarMonthIcon/>}
                                onClick={() => navigate(`/organizador/${event.client.id}`)}>
                            Mais Eventos
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Divider sx={{my: '2rem'}}></Divider>
            <Grid container spacing={10}>
                <Grid item xs={12} md={4}>
                    <Typography variant="h5">
                        Métodos de pagamento
                    </Typography>
                    <Box sx={{display: 'flex', mt: '10px', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                        <img src={mastercard} alt="Mastercard" style={{marginRight: '10px', marginBottom: '10px'}}/>
                        <img src={visa} alt="Visa" style={{marginRight: '10px', marginBottom: '10px'}}/>
                        <img src={american_express} alt="American Express"
                             style={{marginRight: '10px', marginBottom: '10px'}}/>
                        <img src={diners} alt="Diners" style={{marginRight: '10px', marginBottom: '10px'}}/>
                        <img src={discover} alt="Discover"/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h5">
                        Compre com total segurança
                    </Typography>
                    <Typography variant="body1" sx={{marginTop: '10px'}}>
                        Os dados sensíveis são criptografados e não serão salvos em nossos servidores.
                    </Typography>
                    <img src={google} height={40} style={{marginTop: '10px'}} alt="Google"/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h5">
                        Precisando de ajuda?
                    </Typography>
                    <Typography variant="body1" sx={{marginTop: '10px'}}>
                        Estamos aqui para te ajudar e tirar todas as suas dúvidas, fale com nosso time.
                    </Typography>
                    <Box marginTop="20px" textAlign="center">
                        <Button variant="contained" href={variables.contactUrl} rel="noreferrer" target="_blank">
                            Entrar em contato
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}

export default EventFooter
