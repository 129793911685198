import {CreateOrderModel, FastCheckoutOrderModel, Order, OrderBase, PixPayment} from '@/business/order/model';
import {trackPromise} from 'react-promise-tracker';
import axios from 'axios';
import {OrderStatus} from '@/business/order/domain';

export function createOrder(model: CreateOrderModel, link?: string | null): Promise<Order> {
    return trackPromise(
        axios.post<Order>(`order`, model, {params: {link}}).then(response => {
            return response.data;
        })
    );
}

export function createFastCheckoutOrder(model: FastCheckoutOrderModel, link?: string | null): Promise<Order> {
    return trackPromise(
        axios.post<Order>(`order/fast-checkout`, model, {params: {link}}).then(response => {
            return response.data;
        })
    );
}

export function getOrders(): Promise<OrderBase[]> {
    return trackPromise(
        axios.get<OrderBase[]>(`order`).then(response => {
            return response.data;
        })
    );
}

export function findOrderById(id: number): Promise<Order> {
    return trackPromise(
        axios.get<Order>(`order/${id}`).then(response => {
            return response.data;
        })
    );
}

export function findFastCheckoutOrder(key: string): Promise<Order> {
    return trackPromise(
        axios.get<Order>(`order/fast-checkout`, {params: {'public-key': key}}).then(response => {
            return response.data;
        })
    );
}

export function payWithCreditCard(id: number, card: number, installments: number): Promise<void> {
    return trackPromise(
        axios.post<void>(`order/${id}/payment/card/${card}`, null, {params: {installments}}).then(_ => {
        })
    );
}

export function payWithPix(id: number): Promise<PixPayment> {
    return trackPromise(
        axios.post<PixPayment>(`order/${id}/payment/pix`).then(r => r.data)
    );
}

export function getOrderStatus(id: number): Promise<OrderStatus> {
    return axios.get<{ value: OrderStatus }>(`order/${id}/status`).then(r => r.data.value);
}

export async function applyCoupon(order: number, code: string): Promise<Order> {
    return trackPromise(
        axios.post<Order>(`order/${order}/coupon`, null, {params: {code}}).then(r => r.data)
    );
}

export async function removeCoupon(order: number): Promise<Order> {
    return trackPromise(
        axios.delete<Order>(`order/${order}/coupon`).then(r => r.data)
    );
}
