import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import {Client} from '@/business/client/model';

export function findClientById(id: number): Promise<Client> {
    return trackPromise(
        axios.get<Client>(`client/${id}`).then(value => {
            return value.data
        })
    );
}

export function findClientByWhiteLabelUrl(url: string): Promise<Client | null> {
    return axios.get<Client | null>(`client/by-white-label-url`, {params: {url}}).then(value => {
        return value.data
    });
}
