import * as React from 'react';
import {Dialog, DialogActions, DialogContent, Grid} from '@mui/material';
import Box from '@mui/material/Box';
import {useDispatch} from 'react-redux';
import {InvoiceAddress} from '@/business/payment/model';
import {createInvoiceAddress} from '@/business/payment/service';
import {add} from '@/features/checkout/stores/address-store';
import message, {ignoreAxios} from '@/utils/errors';
import {useForm} from 'react-hook-form';
import CepField from '@/features/components/CepField';
import {AddressBase} from '@/business/location/model';
import ControlledTextField from '@/features/components/ControlledTextField';
import Button from '@mui/material/Button';
import {toast} from 'react-toastify';
import {toInt} from '@/utils/parsers/number';
import DialogHeader from '@/features/components/DialogHeader';

interface Props {
    onSave: (address: InvoiceAddress) => void;
    onCancel: () => void;
}

const DEFAULT = {
    cep: '',
    city: '',
    number: '',
    uf: '',
    street: '',
    complement: ''
}

const CreateInvoiceAddressModal = ({onSave, onCancel}: Props) => {

    const dispatch = useDispatch()
    const {
        handleSubmit,
        setValue,
        control,
        formState: {errors, isSubmitted}
    } = useForm({defaultValues: DEFAULT});

    const handleSave = (data: any) => {
        createInvoiceAddress({...data, cep: toInt(data.cep)})
            .then(address => {
                toast.success("Endereço incluído com sucesso");
                dispatch(add(address));
                onSave(address);
            })
            .catch(ignoreAxios)
    }

    const handleAddressLoaded = (address: AddressBase) => {
        setValue('city', address.city?.name ?? '', {shouldValidate: true})
        setValue('uf', address.state?.uf ?? '', {shouldValidate: true})
        setValue('street', address.street ?? '', {shouldValidate: true})
        setValue('complement', address.complement ?? '', {shouldValidate: true})
    }

    return (
        <Dialog onClose={onCancel} open={true}>
            <DialogHeader onClose={onCancel}>
                Cadastrar endereço de cobrança
            </DialogHeader>
            <DialogContent>
                <Box component="form" noValidate onSubmit={handleSubmit(handleSave)}>
                    <CepField
                        control={control}
                        error={isSubmitted && !!errors.cep}
                        helperText={message(errors.cep, {minLength: 9})}
                        addressLoaded={handleAddressLoaded}
                    />

                    <Grid container columnSpacing={2}>
                        <Grid item xs={12}>
                            <ControlledTextField
                                name="street"
                                control={control}
                                error={isSubmitted && !!errors.street}
                                helperText={message(errors.street)}
                                margin="normal"
                                required
                                fullWidth
                                label="Nome da rua"
                                rules={{required: true}}
                                inputProps={{maxLength: 250}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ControlledTextField
                                name="number"
                                control={control}
                                error={isSubmitted && !!errors.number}
                                helperText={message(errors.number)}
                                margin="normal"
                                fullWidth
                                label="Número"
                                inputProps={{maxLength: 20}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ControlledTextField
                                name="complement"
                                control={control}
                                error={isSubmitted && !!errors.complement}
                                helperText={message(errors.complement)}
                                margin="normal"
                                fullWidth
                                label="Complemento"
                                inputProps={{maxLength: 20}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ControlledTextField
                                name="city"
                                control={control}
                                rules={{required: true}}
                                error={isSubmitted && !!errors.city}
                                helperText={message(errors.city)}
                                margin="normal"
                                fullWidth
                                required
                                label="Cidade"
                                inputProps={{maxLength: 45}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ControlledTextField
                                name="uf"
                                control={control}
                                rules={{required: true}}
                                error={isSubmitted && !!errors.uf}
                                helperText={message(errors.uf)}
                                margin="normal"
                                fullWidth
                                required
                                label="Estado (UF)"
                                inputProps={{maxLength: 2}}
                            />
                        </Grid>
                    </Grid>
                    <input type="submit" style={{display: 'none'}}/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="outlined">Cancelar</Button>
                <Button onClick={handleSubmit(handleSave)} variant="contained" autoFocus>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default CreateInvoiceAddressModal
