import {Order} from '@/business/order/model';
import {Ticket2, TicketDisplay} from '@/business/tickets/model';
import Box from '@mui/material/Box';
import TicketItem from '@/features/tickets/components/tickets-modal/TicketItem';
import {Alert, AlertTitle, Divider} from '@mui/material';
import * as React from 'react';
import {useState} from 'react';
import {preventDefault} from '@/utils/functions';
import Link from '@mui/material/Link';
import {exportTicketByOrderPublicKey} from '@/business/tickets/service';
import {download} from '@/utils/download';
import {ignoreAxios} from '@/utils/errors';

interface Props {
    order: Order;
    tickets: Ticket2[];
}

const FastCheckoutTickets = ({order, tickets}: Props) => {

    const [alert, setAlert] = useState(true);

    const parse = (ticket: Ticket2): TicketDisplay => {
        return {
            id: ticket.id,
            freeTicket: false,
            code: ticket.code,
            qrCodeUrl: ticket.qrCodeUrl,
            usedAt: ticket.usedAt,
            label: ticket.label,
            ticketUser: ticket.ticketUser!,
            guests: ticket.guests ? ticket.guests.map(value => parse(value)) : undefined
        }
    }

    const t = tickets.map(value => parse(value));

    const none = () => {
    }

    const handleDownload = () => {
        exportTicketByOrderPublicKey(order.publicKey!).then(value => {
            download(value, `Pedido ${order.number}.pdf`).catch(ignoreAxios);
        }).catch(ignoreAxios);
    }

    return <Box>
        {alert && (
            <Alert severity="success" sx={{mb: '10px'}} onClose={() => setAlert(false)}>
                <AlertTitle>Compra Concluída!</AlertTitle>
                Aqui estão seus ingressos! Mandamos eles para o seu Email e você pode acessá-los fazendo login ou
                criando uma conta usando esse mesmo email. Agora é só aproveitar a festa!
            </Alert>
        )}
        {t.map((ticket, i2) => (
                <Box key={ticket.id}>
                    <TicketItem ticket={ticket} disabled={true} onEdit={none} onDelete={none} display={{}}/>
                    {(i2 < t.length - 1) && (
                        <Divider sx={{mb: '10px', mt: '10px'}}/>
                    )}
                </Box>
            )
        )}
        <Box sx={{mt: '10px', textAlign: 'center'}}>
            <Link onClick={preventDefault(handleDownload)} href="#">
                Baixar Ingressos
            </Link>
        </Box>
    </Box>
}

export default FastCheckoutTickets
