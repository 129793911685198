import {Order} from '@/business/order/model';
import * as React from 'react';
import {ignoreAxios} from '@/utils/errors';
import {applyCoupon, removeCoupon} from '@/business/order/service';
import CouponInput from '@/features/components/CouponInput';
import {SxProps} from '@mui/system/styleFunctionSx';
import {Theme} from '@mui/material/styles';

interface Props {
    order: Order,
    onNewOrderSet: (order: Order) => void,
    containerSx?: SxProps<Theme>;
}

const OrderCoupon = ({order, onNewOrderSet, containerSx}: Props) => {

    const apply = (code: string) => {
        applyCoupon(order.id, code)
            .then(order => onNewOrderSet(order))
            .catch(ignoreAxios)
    }

    const remove = () => {
        removeCoupon(order.id)
            .then(order => onNewOrderSet(order))
            .catch(ignoreAxios)
    }

    return <CouponInput onApply={apply} onRemove={remove} current={order.coupon?.code} containerSx={containerSx}/>
}

export default OrderCoupon;
