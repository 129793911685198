import {PaymentMethodType} from "@/business/payment/model";
import PixIcon from "@mui/icons-material/Pix";
import Button from "@mui/material/Button";
import * as React from "react";
import {ReactNode} from "react";
import CreditCardIcon from "@mui/icons-material/CreditCard";

interface Props {
    type: PaymentMethodType;
    selected: boolean;
    onClick: VoidFunction;
}

const ICONS: { [key in PaymentMethodType]: ReactNode } = {
    [PaymentMethodType.CREDIT_CARD]: <CreditCardIcon/>,
    [PaymentMethodType.PIX]: <PixIcon/>
};

const LABELS: { [key in PaymentMethodType]: string } = {
    [PaymentMethodType.CREDIT_CARD]: "Cartão de Crédito",
    [PaymentMethodType.PIX]: "PIX"
};

export const PaymentMethodButton = ({type, selected, onClick}: Props) => {
    const icon = ICONS[type];
    const label = LABELS[type];
    return <Button variant={selected ? 'contained' : 'outlined'}
                   onClick={onClick} startIcon={icon}>
        {label}
    </Button>
}