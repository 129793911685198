import {TicketDisplay, TicketOrder, TicketUser} from '@/business/tickets/model';
import * as React from 'react';
import {useState} from 'react';
import {MessageUtils} from '@/utils/message';
import Box from '@mui/material/Box';
import ValueLabel from '@/features/components/ValueLabel';
import {formatDateTime} from '@/utils/formatters';
import {Divider} from '@mui/material';
import EditUser from '@/features/tickets/components/EditUser';
import TicketItem from '@/features/tickets/components/tickets-modal/TicketItem';

interface Props {
    initialTickets: TicketOrder[],
    disabled: boolean
}

const OrderTickets = ({initialTickets, disabled}: Props) => {

    const [orders, setOrders] = useState(initialTickets);
    const [editing, setEditing] = useState<TicketDisplay | undefined>();

    if (!orders.length) {
        return <></>
    }

    const handleDeleted = (order: TicketOrder, itemIndex: number, id: number) => {
        const item = order.items[itemIndex];
        item.tickets = item.tickets.filter(a => a.id !== id);
        if (!item.tickets.length) {
            order.items = order.items.filter(a => a.id !== item.id);
        }
        setOrders(orders.filter(a => a.items.length))
    }

    const setUser = (id: number, user: TicketUser) => {
        for (let order of orders) {
            for (let item of order.items) {
                const ticket = item.tickets.find(value => value.id === id);
                if (ticket) {
                    ticket.ticketUser = user;
                    return;
                }
            }
        }
        setOrders([...orders]);
    }

    const handleUserUpdated = (id: number, user: TicketUser) => {
        setUser(id, {...user, valid: true});
        MessageUtils.notifySuccess("Usuário do ingresso atualizado com sucesso");
        setEditing(undefined);
    }

    return (
        <>
            {orders.map(order =>
                <fieldset style={{margin: '10px 0 0 0'}} key={order.id}>
                    <Box sx={{display: 'flex', mb: '10px'}}>
                        <ValueLabel label="Número do Pedido" value={order.number}/>
                        <Box paddingLeft="15px">
                            <ValueLabel label="Data do Pedido" value={formatDateTime(order.date)}/>
                        </Box>
                    </Box>
                    {order.items.map((item, itemIndex) => (
                        <Box key={item.id}>
                            {item.tickets.map((ticket, i2) => (
                                    <Box key={ticket.id}>
                                        <TicketItem ticket={ticket} disabled={disabled}
                                                    onEdit={(t) => setEditing(t)}
                                                    onDelete={() => handleDeleted(order, itemIndex, ticket.id)}
                                        />
                                        {(itemIndex < order.items.length - 1 || i2 < item.tickets.length - 1) && (
                                            <Divider sx={{mb: '10px', mt: '10px'}}/>
                                        )}
                                    </Box>
                                )
                            )}
                        </Box>
                    ))}
                </fieldset>
            )}
            {editing && (
                <EditUser ticket={editing} onCancel={() => setEditing(undefined)}
                          onUpdated={(user) => handleUserUpdated(editing!.id, user)}/>
            )}
        </>
    )
}

export default OrderTickets;
