import {TokenResponse, useGoogleLogin} from "@react-oauth/google";
import {MessageUtils} from "@/utils/message";
import {loginByAuthCode, loginToken} from "@/business/security/service";
import {ignoreAxios} from "@/utils/errors";
import {useCallback} from "react";
import {User} from "@/business/security/model";
import {useIsInstagramBrowser} from "@/utils/hooks/useIsInstagramBrowser";
import {useAccountContext} from "@/utils/context/AccountContext";

export const useRedirectGoogleLogin = () => {

    const load = useCallback(async () => {
        const query = new URLSearchParams(window.location.search);
        const code = query.get("code");
        const state = query.get("state");

        if (code) {
            try {
                const user = await loginByAuthCode(code, `${window.location.origin}/code`, 'GOOGLE_OAUTH_AUTH_CODE');
                let redirect = "";
                if (state) {
                    try {
                        redirect = JSON.parse(state).redirect
                    } catch (e) {
                    }
                }
                return {
                    user, redirect
                }
            } catch (e) {
                ignoreAxios(e);
            }
        }
        return null;
    }, []);

    const login = useGoogleLogin({
        ux_mode: 'redirect',
        flow: 'auth-code',
        redirect_uri: `${window.location.origin}/code`,
        state: JSON.stringify({
            redirect: window.location.href.replace(window.location.origin, '')
        }),
        onError: (error) => {
            console.log(error);
            MessageUtils.notifyError("Não conseguimos autenticar com o Google :(");
        }
    });

    return {
        login,
        load
    }
}

export const useModalGoogleLogin = (onSuccess: (user: User) => void) => {

    const handleSuccess = (tokenResponse: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
        loginToken(tokenResponse.access_token, 'GOOGLE_OAUTH_TOKEN').then(user => {
            onSuccess(user);
        }).catch(ignoreAxios)
    }

    const login = useGoogleLogin({
        onSuccess: handleSuccess,
        onError: (error) => {
            console.log(error);
            MessageUtils.notifyError("Não conseguimos autenticar com o Google :(");
        }
    });

    return {login};
}

export const useGoogleAuth = (onSuccess?: (user: User) => void) => {

    const {setUser} = useAccountContext();
    const isInstagramBrowser = useIsInstagramBrowser();

    const handleLogin = (user: User) => {
        setUser(user);
        if (onSuccess) {
            onSuccess(user);
        }
    }

    const {login: redirect, load: loadRedirect} = useRedirectGoogleLogin();
    const {login: modal} = useModalGoogleLogin(handleLogin);

    const login = useCallback(async () => {
        if (isInstagramBrowser) {
            redirect();
        } else {
            modal();
        }
    }, [isInstagramBrowser, modal, redirect]);

    const load = useCallback(async () => {
        if (isInstagramBrowser) {
            return await loadRedirect();
        }
        return null;
    }, [isInstagramBrowser, loadRedirect]);

    return {
        login,
        load
    }
}
