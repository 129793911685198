import {UnTokenizedCard} from '@/business/payment/model';
import {trackPromise} from 'react-promise-tracker';
import axios, {AxiosError} from 'axios';
import {getPaymentSettings} from '@/business/payment/service';
import {BusinessError} from '@/business/utils/BusinessError';

interface TokenizedCardResponse {
    id: string;
}

const ERRORS: { [key: string]: string } = {
    'card expired.': 'Cartão Expirado',
    'the field exp_month must be between 1 and 12.': 'O Mês de Validade deve estar entre 1 e 12',
    'the number field is not a valid card number': 'O Número do cartão é inválido',
};

function getError(error: AxiosError): string {
    if (!error.response?.data) {
        console.log(error);
        return "Erro ao criar token do cartão.";
    }
    const payload: any = error.response.data;
    const keys = Object.keys(payload.errors ?? {});
    if (keys.length) {
        const errors: any[] = payload.errors[keys[0]];
        return ERRORS[errors[0].toString().trim().toLowerCase()] ?? errors[0];
    }
    return payload.message ?? "Erro ao criar token do cartão.";
}

export function tokenize(card: UnTokenizedCard): Promise<string> {
    return trackPromise(
        getPaymentSettings().then(settings => {
            const dates = card.validTrough.split("/");
            const payload = {
                type: "card",
                card: {
                    type: "credit_card",
                    holder_name: card.holder,
                    cvv: card.code,
                    exp_month: dates[0],
                    exp_year: dates[1],
                    number: card.number.replaceAll(" ", "")
                }
            };
            const instance = axios.create({
                baseURL: settings.api,
                params: {appId: settings.key}
            });
            return instance.post<TokenizedCardResponse>(`/tokens`, payload)
                .then(value => value.data.id)
                .catch((error) => {
                    if (axios.isAxiosError(error)) {
                        throw new BusinessError(
                            getError(error)
                        )
                    }
                    throw error;
                });
        })
    );
}
