import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {featuredList} from '@/business/event/service';
import {EventListing} from '@/business/event/model';
import Slider from "react-slick";
import FeaturedItem from '@/features/event/components/listing/FeaturedItem';
import {useMediaQuery, useTheme} from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Featured = () => {
    const [events, setEvents] = useState<EventListing[]>([]);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const settings = {
        dots: true,
        autoplay: true,
        speed: 700,
        autoplaySpeed: 5000,
        cssEase: "linear",
        infinite: true,
        slidesToShow: events.length < 2 || isMd ? 1 : 2,
        centerMode: false,
        slidesToScroll: 1,
    };

    useEffect(() => {
        featuredList().then(value => {
            setEvents(value);
        })
    }, []);

    if (!events.length) {
        return (<></>)
    }

    return (
        <Box>
            <Typography variant="h1">
                Principais eventos
            </Typography>
            <Slider {...settings}>
                {events.map(event =>
                    <Box key={event.id}>
                        <Box sx={{backgroundColor: 'black', height: '200px', margin: '10px', borderRadius: '20px'}}>
                            <FeaturedItem event={event}></FeaturedItem>
                        </Box>
                    </Box>)}
            </Slider>
        </Box>
    )
}

export default Featured
