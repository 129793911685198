import {maskInt} from '@/utils/masks/number';

export function maskMonth(value: string): string {
    const m1 = parseInt(value.substring(0, 1), 10);
    const m2 = parseInt(value.substring(1, 2), 10) ?? 9;

    if (isNaN(m1) || m1 > 1) {
        return '';
    }

    if (isNaN(m2) || (m1 === 1 && m2 > 2)) {
        return `${m1}`;
    }

    return `${m1}${m2}`;
}


export function maskDate(value?: string): string {
    value = maskInt(value);
    if (!value) {
        return ''
    }
    const d1 = parseInt(value[0], 10);
    if (d1 > 3) {
        return '';
    }

    const d2 = parseInt(value.substring(1, 2), 10);
    if (isNaN(d2) || (d1 === 3 && d2 > 1)) {
        return `${d1}`
    }

    const month = maskMonthYear(value.substring(2));
    return month ? `${d1}${d2}/${month}` : `${d1}${d2}`;
}

export function maskMonthYear(value?: string): string {
    value = maskInt(value);
    if (!value) {
        return ''
    }
    let month = `${maskMonth(value)}`;
    if (month) {
        const y1 = parseInt(value.substring(2, 3), 10);
        if (isNaN(y1) || !y1 || y1 > 2) {
            return month;
        }
        month += `/${y1}${value.substring(3, 6)}`;
    }
    return month;
}
