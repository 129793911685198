import moment from 'moment';
import {InvoiceAddress} from '@/business/payment/model';
import {Address} from '@/business/location/model';

export function formatDateTime(date: Date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
}

export function formatDate(date?: Date) {
    if (!date) {
        return '';
    }
    return moment(date).format('DD/MM/YYYY');
}

export function formatDecimal(value: number, prefix?: string): string {
    return (prefix ?? "") + value.toLocaleString('pt-br', {minimumFractionDigits: 2});
}

export function formatEventAddress(address: Address): string {
    let line1 = address.street + ", ";
    if (!address.number) {
        line1 += "S/N";
    } else {
        line1 += address.number;
    }
    if (address.complement) {
        line1 += (", " + address.complement);
    }
    line1 += (", " + address.neighborhood);
    line1 += (", " + address.city?.name);
    line1 += (", " + address.state?.uf);
    return line1;
}

export function formatInvoiceAddress(address: InvoiceAddress): { line1: string, line2: string } {
    let line1 = address.street + ", ";
    if (!address.number) {
        line1 += "S/N";
    } else {
        line1 += address.number;
    }
    if (address.complement) {
        line1 += (", " + address.complement);
    }
    let line2 = `${address.city}, ${address.uf}. CEP: ${address.cep}`;

    return {line1, line2}
}
