export function upperCase(value?: string): string {
    if (!value) {
        return '';
    }
    return value.toUpperCase();
}

export function trim(value?: string): string {
    if (!value) {
        return '';
    }
    return value.trim();
}
