import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {CouponResponse, TicketBlock, TicketBlockGroup} from '@/business/event/model';
import {Divider, List, ListItem} from '@mui/material';
import TicketBlockDefaultItem from '@/features/event/components/commons/TicketBlockDefaultItem';
import Box from '@mui/material/Box';
import {EventUtils} from '@/business/event/utils';
import {TicketBlockStatus} from '@/business/event/domain';
import {CreateOrderItemModel} from '@/business/order/model';

const STORAGE_KEY = "TICKET";

type State = {
    event: number;
    map: Map<number, number>;
}

interface EventBlock {
    group: TicketBlockGroup,
    block: TicketBlock
}

export interface Props {
    event: number;
    blocks: TicketBlockGroup[];
    onChange: (items: CreateOrderItemModel[]) => void;
    coupon?: CouponResponse;
}

const removeInvalid = (map: Map<number, number>, blocks: TicketBlockGroup[]) => {
    const invalids: number[] = [];
    map.forEach((_, block) => {
        const config = EventUtils.findBlockById(blocks, block);
        if (config?.status !== TicketBlockStatus.DISPONIVEL) {
            invalids.push(block);
        }
    });
    invalids.forEach(value => map.delete(value));
}

const loadSavedState = (event: number, blocks: TicketBlockGroup[]): State => {
    let state: State = {
        event: event,
        map: new Map<number, number>()
    };

    try {
        const saved = sessionStorage.getItem(STORAGE_KEY + event);
        if (saved) {
            const array: number[][] = JSON.parse(saved);
            const map = new Map<number, number>();
            for (let i = 0; i < array.length; i++) {
                const item = array[i];
                map.set(item[0], item[1]);
            }
            removeInvalid(map, blocks);
            state.map = map;
        }
        return state;
    } catch (ignored) {
        return state;
    }
}

export const clearSavedState = (event: number) => {
    sessionStorage.removeItem(STORAGE_KEY + event);
}

const TicketBlockSelection = ({event, blocks: tickets, onChange, coupon}: Props) => {

    const items = useRef<State | undefined>();
    const [empty, setEmpty] = useState(true);
    const [blocks, setBlocks] = useState<EventBlock[]>([]);

    const triggerChange = useCallback(() => {
        const list: CreateOrderItemModel[] = [];
        items.current?.map.forEach((value, key) => {
            list.push({
                ticketBlock: key,
                quantity: value
            });
        });
        onChange(list);
    }, [onChange]);

    useEffect(() => {
        const list: EventBlock[] = [];
        tickets.forEach(group => {
            list.push(...group.configurations
                .map(block => {
                    return {
                        block: block,
                        group: group,
                    };
                }));
        });
        setBlocks(list);
    }, [tickets]);

    useEffect(() => {
        items.current = loadSavedState(event, tickets);
        setEmpty(!!items.current.map.size);
        triggerChange();
    }, [triggerChange, event, tickets]);

    const setItemQuantity = (block: number, increment: number) => {
        const value = (items.current!.map.get(block) ?? 0) + increment;
        if (value <= 0) {
            items.current!.map.delete(block);
        } else {
            items.current!.map.set(block, value);
        }
        sessionStorage.setItem(STORAGE_KEY + event, JSON.stringify(items.current?.map));
        triggerChange();
        return value;
    }

    const handleAddItem = (block: number) => {
        const value = setItemQuantity(block, 1);
        if (empty) {
            setEmpty(false);
        }
        return value;
    }

    const handleRemoveItem = (block: number) => {
        const value = setItemQuantity(block, -1);
        if (!items.current?.map?.size) {
            setEmpty(true);
        }
        return value;
    }

    return (
        <List sx={{width: '100%'}}>
            {blocks.map((a, index) =>
                <Box key={a.block.id}>
                    <ListItem sx={{paddingX: 0}}>
                        <TicketBlockDefaultItem block={a.block} onAdd={handleAddItem}
                                                onRemove={handleRemoveItem}
                                                discountPercent={coupon?.discount}
                                                discount={!coupon ? undefined : coupon.blockDiscounts[`${a.block.id}`]}
                                                initial={items.current?.map?.get(a.block.id)}/>
                    </ListItem>
                    {index < blocks.length - 1 && (
                        <Box sx={{paddingBottom: '8px'}}>
                            <Divider></Divider>
                        </Box>
                    )}
                </Box>
            )}
        </List>
    )
}

export default TicketBlockSelection
