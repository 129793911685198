import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import {UseTerms} from '@/business/commons/model';

export function getUseTerms(): Promise<UseTerms[]> {
    return trackPromise(
        axios.get<UseTerms[]>(`use-terms`).then(value => {
            return value.data
        })
    );
}
