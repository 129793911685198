import * as React from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {OrderItem} from '@/business/order/model';
import {formatDecimal} from '@/utils/formatters';
import Typography from '@mui/material/Typography';

interface Props {
    items: OrderItem[],
}

const OrderItemDetails = ({items}: Props) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{width: '100%'}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Quantidade</TableCell>
                        <TableCell>Lote</TableCell>
                        <TableCell>Valor Unitário</TableCell>
                        <TableCell>Valor Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row) => {
                        const sx = {};
                        return (
                            <TableRow key={row.id} sx={{':hover': {backgroundColor: '#F5F7F8'}}}>
                                <TableCell sx={sx}>
                                    {row.quantity}
                                </TableCell>
                                <TableCell sx={sx}>
                                    <Typography sx={{textTransform: 'uppercase'}}>
                                        {row.label}
                                    </Typography>
                                </TableCell>
                                <TableCell sx={sx}>
                                    {formatDecimal(row.unitValue - (row.discount ?? 0), 'R$ ')}
                                    {row.userTax && (
                                        (<Typography variant="body2" component="span" sx={{
                                            fontSize: '10px',
                                            fontWeight: 300
                                        }}>
                                            &nbsp;+TAXA: {formatDecimal(row.userTax, 'R$ ')}
                                        </Typography>)
                                    )}
                                </TableCell>
                                <TableCell sx={sx}>
                                    {formatDecimal(row.chargedValue, 'R$ ')}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OrderItemDetails;
