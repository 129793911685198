import * as React from 'react';
import {useEffect} from 'react';
import MainLayout from '@/layouts/main';
import {LoadingPage} from "@/utils/components/LoadingPage";
import Box from "@mui/material/Box";
import {useGoogleAuth} from "@/features/login/hooks/useGoogleAuth";
import {useAccountContext} from "@/utils/context/AccountContext";
import {useNavigate} from "react-router-dom";

const OAuthCallbackPage = () => {

    const {load} = useGoogleAuth();
    const {setUser} = useAccountContext();
    const navigate = useNavigate();

    useEffect(() => {
        load().then(value => {
            setUser(value?.user ?? null);
            if (value?.redirect) {
                navigate(value?.redirect);
            } else {
                navigate("/");
            }
        })
    }, [load, navigate, setUser]);

    return (
        <MainLayout>
            <Box display="flex" flexDirection="column" height="100%">
                <LoadingPage/>
            </Box>
        </MainLayout>
    )
}

export default OAuthCallbackPage;
