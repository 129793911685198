import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import {getAdminToken} from '@/business/security/service';
import {Divider, ListItemIcon, ListItemText, MenuList} from '@mui/material';
import LoginModal from '@/features/login/components/LoginModal';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import {useNavigate} from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DiamondIcon from '@mui/icons-material/Diamond';
import TableBarIcon from '@mui/icons-material/TableBar';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import {Environment} from '@/config';
import Box from '@mui/material/Box';
import BecomeAProducerButton from '@/layouts/header/components/BecomeAProducerButton';
import {useWhiteLabelClientContext} from "@/layouts/contexts/white-label-client/WhiteLabelClientContext";
import {useAccountContext} from "@/utils/context/AccountContext";

export interface Props {
    logged?: boolean;
}

const UserMenu = ({logged}: Props) => {

    const admin = getAdminToken();
    const navigate = useNavigate();
    const {logout, login, user} = useAccountContext();

    const handleLogout = () => {
        setAnchorElUser(null);
        logout();
        if (logged) {
            navigate("/")
        }
    }

    const handleLogin = () => {
        setAnchorElUser(null);
        login.show();
    }

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const {client} = useWhiteLabelClientContext();

    if (user) {
        return (
            <Box whiteSpace="nowrap" flex={1} textAlign="right">
                {admin && !client && (
                    <Button variant="contained" sx={{mr: '10px'}} target="_blank"
                            href={`${Environment.BackOfficeUrl}?session_id=${admin}`}>
                        Gerenciar Eventos
                    </Button>
                )}
                {!admin && !client && <BecomeAProducerButton/>}
                <Tooltip title="Abrir Menu">
                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                        {user.avatar && (<Avatar alt={user.name ?? 'User Menu'} src={user.avatar}/>)}
                        {!user.avatar && (<AccountCircleIcon sx={{fontSize: 45}}/>)}
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={{mt: '45px'}}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <MenuList>
                        <MenuItem onClick={() => {
                            navigate("/ingressos")
                        }}>
                            <ListItemIcon>
                                <ConfirmationNumberIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Ingressos</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            navigate("/listas")
                        }}>
                            <ListItemIcon>
                                <DiamondIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Listas VIP</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            navigate("/mesas")
                        }}>
                            <ListItemIcon>
                                <TableBarIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Reserva de Mesas</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            navigate("/conta")
                        }}>
                            <ListItemIcon>
                                <AccountCircleIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Minha Conta</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => {
                            navigate("/pedidos")
                        }}>
                            <ListItemIcon>
                                <DescriptionIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Meus Pedidos</ListItemText>
                        </MenuItem>
                        <Divider/>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>Sair</ListItemText>
                        </MenuItem>
                    </MenuList>

                </Menu>
            </Box>
        )
    }

    return (
        <>
            <Button onClick={handleLogin} variant="outlined">
                Entrar
            </Button>
            <LoginModal/>
        </>
    )
}

export default UserMenu
