import * as React from 'react';
import {useEffect, useState} from 'react';
import MainLayout from '@/layouts/main';
import {ignoreAxios} from '@/utils/errors';
import EmptyList from '@/features/components/EmptyList';
import {useNavigate, useParams} from 'react-router-dom';
import {getLinkData} from '@/business/event/service';
import {CHECKOUT_TYPE} from '@/business/event/domain';
import {saveLink} from '@/utils/link-storage';
import {LoadingPage} from "@/utils/components/LoadingPage";
import Box from "@mui/material/Box";

const LinkLoadingPage = () => {

    //http://localhost:3002/link/gVzZ
    const [empty, setEmpty] = useState(false);
    const [loading, setLoading] = useState(true);

    let {code} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (code) {
            setLoading(true);
            getLinkData(code).then(value => {
                if (value) {
                    if (value.checkout === CHECKOUT_TYPE.FAST_CHECKOUT) {
                        navigate(`/fast-checkout/${value.eventKey}?link=${code}`);
                    } else {
                        navigate(`/evento/${value.eventKey}?link=${code}`);
                    }
                    saveLink(value.eventKey, code ?? "");
                    return;
                }
                setEmpty(true);
            }).catch(reason => {
                setEmpty(true);
                ignoreAxios(reason);
            }).finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
            setEmpty(true);
        }
    }, [code, navigate]);

    let component = null;
    if (empty) {
        component = <EmptyList title="Link ou Evento não encontrado"
                               text="Não conseguimos encontrar o Link, ou ele não está relacionado à um evento válido. Confirme o link com o seu Promoter para não ficar de fora."/>;
    }
    if (loading) {
        component = <Box display="flex" flexDirection="column" height="100%">
            <LoadingPage/>
        </Box>
    }

    return (
        <MainLayout>
            {component}
        </MainLayout>
    )
}

export default LinkLoadingPage;
