import * as React from 'react';
import image from '@/assets/img/party.jpg';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';

export interface Props {
    src?: string;
    radius?: string;
    ratio?: number;
    label?: string;
    maxHeight?: string
}

const EventCover = ({src, radius, ratio, label, maxHeight}: Props) => {

    const img = <img src={src ?? image} alt=""
                     style={{
                         aspectRatio: ratio ?? 16 / 9,
                         objectFit: 'contain',
                         width: '100%',
                         borderRadius: radius,
                         backgroundColor: 'black',
                         maxHeight: maxHeight
                     }}/>;
    if (!label) {
        return img;
    }

    return <Box sx={{position: 'relative'}}>
        {img}
        <Box sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(0,0,0,0.6)',
            padding: '10px 15px'
        }}>
            <Typography variant="subtitle1" sx={{color: 'white', fontWeight: 'bold'}}>
                {label}
            </Typography>
        </Box>
    </Box>
}

export default EventCover
