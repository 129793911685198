import {configureStore} from '@reduxjs/toolkit'
import addressReducer from '../../features/checkout/stores/address-store'
import cardReducer from '../../features/checkout/stores/card-store'

export const store = configureStore({
    reducer: {
        address: addressReducer,
        card: cardReducer
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
