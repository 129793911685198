import {OrderStatus} from './domain';

export const OrderStatusLabels: { [key in OrderStatus]: any } = {
    [OrderStatus.OPEN]: 'Aguardando Pagamento',
    [OrderStatus.PAID]: 'Pago',
    [OrderStatus.CANCELLED]: 'Cancelada'
};

export const OrderStatusColors: { [key in OrderStatus]: any } = {
    [OrderStatus.OPEN]: 'warning',
    [OrderStatus.PAID]: 'success',
    [OrderStatus.CANCELLED]: 'error',
};

