import * as React from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {formatDateTime} from '@/utils/formatters';
import Card from '@mui/material/Card';
import {Divider} from '@mui/material';
import Button from '@mui/material/Button';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EventCover from '@/features/components/EventCover';
import {EventBase} from '@/business/event/model';

interface Props {
    event: EventBase,
    onClick: () => void
}

const TicketsPage = ({event, onClick}: Props) => {
    return (
        <Card sx={{width: '100%'}}>
            <EventCover src={event.coverSmall ?? event.cover}/>
            <CardContent sx={{textAlign: 'left'}}>
                <Typography variant="subtitle1" color="info.light" component="div">
                    <b>
                        {formatDateTime(event.date)}
                    </b>
                </Typography>
                <Typography component="div" variant="h6">
                    <b>
                        {event.name}
                    </b>
                </Typography>

                <Divider sx={{marginTop: '10px', marginBottom: '20px'}}/>

                <Button variant="text" startIcon={<CelebrationIcon/>} sx={{width: '100%'}}
                        href={`/evento/${event.key}`}>
                    Ver Evento
                </Button>
                <Button variant="contained" startIcon={<ConfirmationNumberIcon/>}
                        sx={{width: '100%', marginTop: '20px'}} onClick={onClick}>
                    Ver Ingressos
                </Button>
            </CardContent>
        </Card>
    )
}

export default TicketsPage;
