import * as React from 'react';
import {useForm} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import message, {ignoreAxios} from '@/utils/errors';
import Box from '@mui/material/Box';
import {ValidateResult} from 'react-hook-form/dist/types/validator';
import {resetPassword} from '@/business/security/service';
import {toast} from 'react-toastify';
import {TokenMetadata} from '@/business/security/model';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export interface Props {
    onComplete: () => void,
    metadata: TokenMetadata,
    token: string
}

const RecoveryPasswordForm = ({onComplete, metadata, token}: Props) => {

    const {
        handleSubmit,
        register,
        formState: {errors, isSubmitted}
    } = useForm({defaultValues: {password: '', verify: ''}});

    const submit = (data: { password: string }) => {
        resetPassword(metadata.session, token, data.password)
            .then(() => {
                toast.success("Senha alterada com sucesso");
                onComplete();
            })
            .catch(ignoreAxios)
    }

    const validateConfirm = (value: any, values: any): ValidateResult => {
        const {password} = values;
        if (!password || !value || password === value) {
            return undefined;
        }
        return "As senhas não conferem"
    }

    return (
        <Box component="form" onSubmit={handleSubmit(submit)} noValidate>
            <Typography variant="subtitle1" sx={{pt: '10px', textAlign: 'center', pb: '10px'}}>
                Tudo certo! Agora basta informar a sua nova senha de acesso na plataforma. Você vai usar essa senha
                sempre que quiser entrar.
            </Typography>

            <TextField
                error={isSubmitted && !!errors.password}
                helperText={message(errors.password, {minLength: 5})}
                margin="normal"
                required
                fullWidth
                label="Nova Senha"
                type="password"
                {...register("password", {required: true, minLength: 5})}
            />
            <TextField
                error={isSubmitted && !!errors.verify}
                helperText={message(errors.verify)}
                margin="normal"
                required
                fullWidth
                label="Confirme a Nova Senha"
                type="password"
                {...register("verify", {required: true, validate: validateConfirm})}
            />

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
            >
                Alterar Senha
            </Button>
        </Box>
    )
}

export default RecoveryPasswordForm
