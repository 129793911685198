import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import * as React from 'react';
import Button from '@mui/material/Button';

export interface Props {
    title?: string,
    text: string | React.ReactNode,
    onConfirm: () => void,
    onCancel: () => void
}

const Confirm = ({title, text, onConfirm, onCancel}: Props) => {
    return (
        <Dialog onClose={onCancel} open={true} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            {title && (
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
            )}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancelar</Button>
                <Button onClick={onConfirm} variant="contained" autoFocus>Sim</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Confirm;
