import {Event} from "@/business/event/model";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LeaveVipList from "@/features/event/components/commons/LeaveVipList";
import * as React from "react";

interface Props {
    event: Event,
    onStatusChange: () => void,
}

export const VipListStatusAlreadyOnList = ({event, onStatusChange}: Props) => {
    return <Box>
        <Typography variant="body1" sx={{marginBottom: '10px'}}>
            Boa! O seu nome já está na lista VIP do Evento. Chame seus amigos para curtir ainda mais esta experiência.
        </Typography>
        <LeaveVipList event={event.id} limit={event.vipListConfiguration?.insertDateLimit!} onLeft={onStatusChange}/>
    </Box>
}
