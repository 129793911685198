import * as React from 'react';
import {useMemo} from 'react';
import PaymentWithCreditCard from '@/features/checkout/components/payment/PaymentWithCreditCard';
import Box from '@mui/material/Box';
import PaymentWithPix from '@/features/checkout/components/payment/PaymentWithPix';
import Typography from '@mui/material/Typography';
import {Order} from '@/business/order/model';
import {PaymentMethodType} from "@/business/payment/model";
import {PaymentMethodButton} from "@/features/checkout/components/payment/PaymentMethodButton";
import {useSelectedPaymentMethodContext} from "@/features/checkout/contexts/selected-payment-method";
import {useGetPaymentMethodsGroups} from "@/business/payment/hooks/useGetPaymentMethodsGroups";

interface Props {
    onComplete: VoidFunction,
    onBack: () => void,
    order: Order,
    defaultDocument?: string,
    onNewOrderSet: (order: Order) => void
}

const OrderPayment = ({onComplete, onBack, order, defaultDocument, onNewOrderSet}: Props) => {
    const {
        setSelectedPayment,
        setSelectedPaymentType,
        selectedPaymentType
    } = useSelectedPaymentMethodContext();
    const {methods} = useGetPaymentMethodsGroups(order.event.id);

    const creditCardConfigs = useMemo(() => {
        return methods.find(value => value.type === PaymentMethodType.CREDIT_CARD)?.options ?? []
    }, [methods]);

    const handleMethodChange = (m: PaymentMethodType) => {
        const method = methods.find(value => value.type === m);
        if (method?.options?.length === 1) {
            setSelectedPayment(method.options[0]);
        } else {
            setSelectedPaymentType(m);
        }
    }

    return (
        <Box>
            {!order.openPayment && (
                <Box>
                    <Typography variant="subtitle1">
                        <b>PAGAR COM</b>
                    </Typography>

                    <Box sx={{mb: '15px'}} display="flex" gap="15px">
                        {methods.map(value => <PaymentMethodButton
                            type={value.type}
                            key={value.type}
                            onClick={() => handleMethodChange(value.type)}
                            selected={selectedPaymentType === value.type}/>
                        )}
                    </Box>
                </Box>
            )}
            {selectedPaymentType === PaymentMethodType.CREDIT_CARD && (
                <PaymentWithCreditCard onComplete={onComplete} onBack={onBack} order={order}
                                       onNewOrderSet={onNewOrderSet} methods={creditCardConfigs}/>
            )}
            {selectedPaymentType === PaymentMethodType.PIX && (
                <PaymentWithPix defaultDocument={defaultDocument} onComplete={onComplete} onBack={onBack}
                                order={order} onNewOrderSet={onNewOrderSet}/>
            )}
        </Box>
    )
}

export default OrderPayment
