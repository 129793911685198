import axiosConfig from './axios';
import {getEnv} from '@/config/envs/environment';
import {sentryConfig} from '@/config/sentry';

export const Environment = getEnv(process.env.REACT_APP_ENVIRONMENT_NAME)

export const variables = {
    contactUrl: 'https://wa.me/5547996533664'
}

const config = () => {
    axiosConfig(Environment.ApiUrl ?? '');
    if (Environment.Sentry) {
        sentryConfig(Environment.Sentry);
    }
}

export default config
