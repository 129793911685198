import * as React from 'react';
import {CSSProperties, useEffect, useState} from 'react';

interface Props {
    expiresIn: number;
    onExpired: VoidFunction;
    style?: CSSProperties | undefined;
}

const Timer = ({expiresIn, onExpired, style}: Props) => {

    const [time, setTime] = useState<number | undefined>(expiresIn);

    const update = () => {
        if (time === undefined) {
            return;
        }
        if (time <= 1) {
            setTime(undefined);
            onExpired();
            return;
        }
        setTime(time - 1);
    }

    useEffect(() => {
        const interval = setInterval(() => update(), 1000);
        return () => clearInterval(interval);
    });

    let label: string;
    if (time && time < 3600) {
        label = new Date(time * 1000).toISOString().substring(14, 19);
    } else {
        label = time ? new Date(time * 1000).toISOString().substring(11, 16) : "00:00";
    }
    return (
        <span style={style}>{label}</span>
    )
}

export default Timer;
