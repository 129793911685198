import {TicketType} from './domain';
import {TicketBlock, TicketBlockGroup} from '@/business/event/model';

export const TicketTypeLabels: { [key in TicketType]: any } = {
    [TicketType.FEMININO]: 'Feminino',
    [TicketType.MASCULINO]: 'Masculino',
    [TicketType.ESTUDANTE]: 'Estudante'
};

export class EventUtils {
    static findBlockById(ticketBlocks: TicketBlockGroup[], block: number): TicketBlock | undefined {
        for (let group of ticketBlocks) {
            const config = group.configurations.find(config => config.id === block);
            if (config) {
                return config;
            }
        }
        return undefined;
    }
}
