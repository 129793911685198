import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import {EventTickets, Ticket2, TicketUser, VipListName} from '@/business/tickets/model';
import {EventBase} from '@/business/event/model';
import {ItemType} from '@/business/order/domain';

export function getAllVipList(): Promise<VipListName[]> {
    return trackPromise(
        axios.get<VipListName[]>(`vip-list-name`).then(response => {
            return response.data;
        })
    );
}

export function joinVipList(event: number, link?: string | null): Promise<void> {
    const params = {event, link};
    return trackPromise(
        axios.post<void>(`vip-list-name`, null, {params}).then(_ => {
            return undefined
        })
    );
}

export function leaveVipList(event: number): Promise<void> {
    return trackPromise(
        axios.delete<void>(`vip-list-name?event=${event}`).then(_ => {
            return undefined
        })
    );
}

export function getVipListState(event: number): Promise<boolean> {
    return trackPromise(
        axios.get<{ reserved: boolean }>(`event/${event}/vip-list/status`).then(value => {
            return value.data.reserved
        })
    );
}

export function reserveTable(table: number): Promise<void> {
    return trackPromise(
        axios.post<void>(`table-reservation?table=${table}`).then(value => {
            return value.data
        })
    );
}


export function getEventsWithTickets(type: ItemType): Promise<EventBase[]> {
    return trackPromise(
        axios.get<EventBase[]>(`tickets/events`, {params: {type}}).then(value => {
            return value.data
        })
    );
}

export function getTickets(event: number, type: ItemType): Promise<EventTickets> {
    return trackPromise(
        axios.get<EventTickets>(`tickets/events/${event}`, {params: {type}}).then(value => {
            return value.data
        })
    );
}

export function deleteTicket(id: number): Promise<void> {
    return trackPromise(
        axios.delete<void>(`tickets/${id}`).then(value => {
            return value.data
        })
    );
}

export async function exportTicketById(ids: number[]): Promise<string> {
    return trackPromise(
        axios.get<{ url: string }>(`/tickets/download`, {params: {ids: ids.join(",")}}).then(value => {
            return value.data.url
        })
    );
}

export async function exportTicketByOrderPublicKey(key: string): Promise<string> {
    return trackPromise(
        axios.get<{ url: string }>(`/tickets/download/by-public-order-key`, {params: {key}}).then(value => {
            return value.data.url
        })
    );
}

export async function findTicketsByOrderItems(itemIds: number[]): Promise<Ticket2[]> {
    return trackPromise(
        axios.get<Ticket2[]>(`/tickets/by-order-items?items=${itemIds.join(",")}`).then(value => value.data)
    );
}

export async function findTicketsByOrderPublicKey(key: string): Promise<Ticket2[]> {
    return trackPromise(
        axios.get<Ticket2[]>(`/tickets/by-public-order-key`, {params: {key}}).then(value => value.data)
    );
}

export async function updateTicketUser(id: number, user: TicketUser) {
    return trackPromise(
        axios.post<void>(`/tickets/${id}/users`, user).then(() => {
        })
    );
}

export async function deleteTicketUser(id: number) {
    return trackPromise(
        axios.delete<void>(`/tickets/${id}/users`).then(() => {
        })
    );
}
