import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {createCreditCard, getCreditCards} from '@/business/payment/service';
import {ignoreAxios} from '@/utils/errors';
import {PaymentMethod, UnTokenizedCard} from '@/business/payment/model';
import CreditCardItem from '@/features/checkout/components/payment/CreditCardItem';
import {FormProvider, useForm} from 'react-hook-form';
import CreateCardForm, {DEFAULTS} from '@/features/checkout/components/payment/CreateCardForm';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/redux';
import {addCard, selectCard, setCards} from '@/features/checkout/stores/card-store';
import {toast} from 'react-toastify';
import {payWithCreditCard} from '@/business/order/service';
import BuyerDateForm from '@/features/checkout/components/BuyerDateForm';
import {Order} from '@/business/order/model';
import OrderCoupon from '@/features/checkout/components/OrderCoupon';
import {tokenize} from '@/business/payment/gateway';
import {InstallmentSelection} from "@/features/checkout/components/payment/InstallmentSelection";
import {useSelectedPaymentMethodContext} from "@/features/checkout/contexts/selected-payment-method";

export interface Props {
    order: Order;
    onComplete: VoidFunction,
    onBack: () => void,
    onNewOrderSet: (order: Order) => void,
    methods: PaymentMethod[];
}

const CreditCardSelection = ({onComplete, onBack, order, onNewOrderSet, methods}: Props) => {
    const {items: cards, selected} = useSelector((state: RootState) => state.card);
    const {items: addresses} = useSelector((state: RootState) => state.address);
    const dispatch = useDispatch();
    const {selectedPayment} = useSelectedPaymentMethodContext();

    const form = useForm({defaultValues: DEFAULTS});

    const placeOrder = (card: number) => {
        if (!selectedPayment) {
            throw Error("Payment not set");
        }
        payWithCreditCard(order.id, card, selectedPayment.installments).then(() => {
            onComplete();
        }).catch(ignoreAxios)
    }

    useEffect(() => {
        if (!cards) {
            getCreditCards()
                .then(value => dispatch(setCards(value)))
                .catch(ignoreAxios)
        }
    }, [cards, dispatch]);

    const tokenizeCard = (data: any) => {
        const {address, ...card} = data;
        card.address = (addresses ?? []).find(a => a.id === address);
        tokenize(card).then(token => {
            createCard(token, card);
        }).catch(ignoreAxios);
    }

    const createCard = (token: string, card: UnTokenizedCard) => {
        createCreditCard(token, card)
            .then(value => {
                toast.success("Cartão incluído com sucesso");
                dispatch(addCard(value));
            })
            .catch(ignoreAxios)
    }

    const handleSelect = (card: number) => {
        dispatch(selectCard(card));
    }

    const canSubmit = selectedPayment && (selected ?? -1) > 0

    return (
        <Box>
            {(cards ?? []).map((card) => (<Box key={card.id} sx={{paddingBottom: '10px'}}>
                <CreditCardItem card={card} onSelect={() => handleSelect(card.id)}
                                selected={card.id === selected}/>
            </Box>))}

            <CreditCardItem onSelect={selected !== -1 ? () => handleSelect(-1) : undefined} selected={selected === -1}>
                {selected === -1 && (
                    <FormProvider {...form} >
                        <CreateCardForm onSubmit={tokenizeCard}/>
                    </FormProvider>
                )}
            </CreditCardItem>

            <Box sx={{mt: '10px'}}>
                <InstallmentSelection methods={methods}/>
            </Box>

            <OrderCoupon order={order} onNewOrderSet={onNewOrderSet} containerSx={{mt: '20px'}}/>

            <Box sx={{textAlign: 'right', paddingTop: '15px'}}>
                {onBack && (
                    <Button sx={{marginRight: '15px'}} variant="outlined"
                            onClick={onBack}>Voltar</Button>
                )}
                {selected !== -1 && (
                    <Button variant="contained" type="submit" disabled={!canSubmit}
                            onClick={() => placeOrder(selected)}>Finalizar Pedido</Button>
                )}
                {selected === -1 && (
                    <Button variant="contained" type="submit" onClick={form.handleSubmit(tokenizeCard)}>Salvar
                        Cartão</Button>
                )}
            </Box>
        </Box>
    )
}

const PaymentWithCreditCard = (props: Props) => {

    const [step, setStep] = useState(0);

    return (
        <Box>
            <BuyerDateForm onBack={props.onBack} onComplete={() => setStep(1)}/>
            {step === 1 && (
                <Box mt="15px">
                    <CreditCardSelection {...props}/>
                </Box>
            )}
        </Box>
    )
}

export default PaymentWithCreditCard
