import {formatDecimal} from "@/utils/formatters";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Box from "@mui/material/Box";
import {useSelectedPaymentMethodContext} from "@/features/checkout/contexts/selected-payment-method";

export const ProcessingFeeInfo = () => {
    const {selectedPayment} = useSelectedPaymentMethodContext();

    if (!selectedPayment?.processingFee) {
        return null;
    }

    return (<Box textAlign="right" mb="10px">
        <Typography variant="body2" color="text.secondary" component="span">
            + Taxa de Processamento: R$ {formatDecimal(selectedPayment.processingFee)}
        </Typography>
    </Box>);
}