import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogHeader from '@/features/components/DialogHeader';
import {Dialog, DialogActions, DialogContent} from '@mui/material';
import Button from '@mui/material/Button';

interface Props {
    open: boolean;
    onClose: VoidFunction
}

const Rule = ({title, text}: { title: string, text: string }) => {
    return (
        <Box sx={{mb: '10px'}}>
            <Typography variant="subtitle1">
                <b>{title}</b>
            </Typography>
            <Typography variant="body2">
                {text}
            </Typography>
        </Box>
    )
}

const VipListRules = ({open, onClose}: Props) => {

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogHeader onClose={onClose}>
                Nome na Lista
            </DialogHeader>
            <DialogContent>
                <Box>
                    <Typography variant="body2" sx={{mb: '10px'}}>
                        Colocar o nome na lista é a melhor forma de curtir aquele evento tão aguardado com os melhores
                        preços,
                        através dela os organizadores podem disponibilizar um lote de ingressos com preços especiais
                        para quem
                        confirmar antecipadamente a apresença no evento. Além disso, alguns organizadores podem oferecer
                        vantagens especiais para os clientes desta lista, para conferir leia a descrição do evento.
                    </Typography>
                    <Typography variant="body2" sx={{mb: '10px'}}>
                        E aí, vai ficar de fora? Inclua o seu nome na lista daquela festa que você está esperando pra
                        conhecer,
                        ou daquele point que mora no seu coração e aproveite.
                    </Typography>
                    <Typography variant="body2" sx={{mb: '10px'}}>
                        Mas atenção, temos algumas regras importantes que você precisa conhecer:
                    </Typography>
                    <Rule title="Curta com os amigos"
                          text="Cada usuário pode incluir apenas o próprio nome na lista. Quer curtir com os amigos? Avise pra eles acessarem o site e incluírem seus nomes também."/>
                    <Rule title="Pague no Evento"
                          text="Ao incluir seu nome na lista, o aplicativo irá gerar um código único pra você. Depois basta apresentar este código na bilheteiria no dia do evento para gerar o seu ingresso, o pagamento é feito apenas neste momento."/>
                    <Rule title="Chegue Cedo"
                          text="A lista possui um horário de limite de chegada no evento, não adianta incluir o nome e chegar no fim da festa hein? Ao colocar o nome na lista confira o horário limite, após este horário a oferta pode se tornar inválida."/>
                    <Rule title="Inclua Agora"
                          text="A lista possui um horário de limite para inclusão do nome, que pode ser algumas horas ou dias antes da realização do evento. Para não perder a oportunidade, inclua o seu nome agora mesmo."/>
                    <Rule title="Remova Quando Quiser"
                          text="Incluiu seu nome na lista e teve um imprevisto? Não se preocupe, você pode tirar o nome da lista a qualquer momento pelo site na opção Listas VIP ou na página do evento."/>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>Fechar</Button>
            </DialogActions>
        </Dialog>


    )
}

export default VipListRules
