import {maskInt} from '@/utils/masks/number';

export function maskCep(value?: string): string {
    value = maskInt(value);
    if (!value) {
        return ''
    }
    if (value.length > 5) {
        return value.substring(0, 5) + '-' + value.substring(5);
    }
    return value;
}
