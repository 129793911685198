import {createTheme} from '@mui/material/styles';

const {palette} = createTheme()

const theme = createTheme({
    palette: {},
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    '&[disabled]': {
                        color: palette.action.disabled,
                        pointerevents: 'none',
                        cursor: 'not-allowed'
                    },
                },
            }
        }
    }
});

theme.typography.h1 = {
    fontWeight: 700,
    fontSize: '28px',
    color: "rgb(25, 31, 40)",
    wordBreak: 'break-word',
};

theme.typography.h2 = {
    fontWeight: 600,
    fontSize: '25px',
    color: "rgb(25, 31, 40)",
};

theme.typography.h3 = {
    fontWeight: 600,
    fontSize: '18px',
    color: "rgb(25, 31, 40)",
};

theme.typography.body1 = {
    fontSize: '16px',
    color: 'rgb(25, 31, 40)'
}

theme.typography.body2 = {
    fontWeight: 400,
    fontSize: '14px',
}

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export default theme;
