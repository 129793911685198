import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {Order} from '@/business/order/model';
import {OrderStatus} from '@/business/order/domain';
import paid from '@/assets/img/receipt_success.svg';
import cancelled from '@/assets/img/receipt_cancel.svg';

interface Props {
    order: Order
}

const ClosedOrder = ({order}: Props) => {

    let success = order.status === OrderStatus.PAID;

    return (
        <>
            <Box textAlign="center">
                <img src={success ? paid : cancelled} alt=""
                     style={{maxHeight: '300px', maxWidth: '100%'}}/>
                <Typography variant="h3">
                    {success ? "O Pedido já foi pago" : "O pedido foi cancelado"}
                </Typography>
                {success && (
                    <Typography variant="subtitle1" sx={{marginTop: '20px', maxWidth: '600px', margin: 'auto'}}>
                        Ótima notícia, o pedido já foi pago.
                    </Typography>
                )}
                {!success && (
                    <Typography variant="subtitle1" sx={{marginTop: '20px', maxWidth: '600px', margin: 'auto'}}>
                        Sentimos muito, mas o pedido foi cancelado. Pedidos pode ser cancelados por solicitação do
                        usuário ou por terem ficado muito tempo aguardando pagamento.
                    </Typography>
                )}
                <br/>
                <Typography variant="subtitle1" sx={{marginTop: '20px', maxWidth: '600px', margin: 'auto'}}>
                    Para visualizar os detalhes dos seus pedidos pagos e pendentes de pagamento, acesse a opção
                    "Pedidos" no menu da aplicação, ou use o botão abaixo.
                </Typography>
                <Button variant="outlined" href="/pedidos" sx={{marginTop: '30px'}}>
                    Ver Pedidos
                </Button>
            </Box>
        </>
    )
}

export default ClosedOrder;
