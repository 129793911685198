import {PaymentMethod, PaymentMethodType} from "@/business/payment/model";
import {useEffect, useState} from "react";
import {getPaymentMethods} from "@/business/payment/service";

export interface PaymentMethodGroup {
    type: PaymentMethodType,
    options: PaymentMethod[]
}

export const useGetPaymentMethodsGroups = (event: number) => {

    const [methods, setMethods] = useState<PaymentMethodGroup[]>([]);

    useEffect(() => {
        getPaymentMethods(event).then(value => {
            const list: PaymentMethodGroup[] = [];
            const loaded: PaymentMethodType[] = [];
            value.forEach(method => {
                if (!loaded.includes(method.type)) {
                    loaded.push(method.type);
                    list.push({
                        type: method.type,
                        options: value.filter(m => m.type === method.type)
                    });
                    return;
                }
            });
            setMethods(list);
        });
    }, [event]);

    return {methods};
}