import {Event} from "@/business/event/model";
import * as React from "react";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import JoinVipListModal from "@/features/event/components/commons/JoinVipListModal";
import {useAccountContext} from "@/utils/context/AccountContext";

interface Props {
    event: Event,
    onStatusChange: () => void,
    link?: string | null
}

export const VipListStatusAvailable = ({event, onStatusChange, link}: Props) => {
    const [modal, setModal] = useState(false);
    const {login, user} = useAccountContext();
    const navigate = useNavigate();

    const handleAddList = () => {
        if (!user) {
            login.show();
        }
        setModal(true);
    }

    const handleJoined = () => {
        onStatusChange();
        setModal(false);
        navigate("/listas");
    }

    return <Box>
        <Typography variant="body1">
            Neste evento você pode pôr seu nome na lista. Inclua seu nome agora e garanta a melhor experiência com
            preços especiais.
        </Typography>
        <Button variant="contained" onClick={handleAddList} sx={{width: '100%', marginTop: '10px'}}>
            Incluir Nome na Lista
        </Button>
        <JoinVipListModal visible={modal && !!user} event={event} onClose={() => setModal(false)}
                          onJoined={handleJoined} link={link}/>
    </Box>
}
