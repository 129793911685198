import * as React from 'react';
import {useState} from 'react';
import {CouponResponse, Event, TicketBlockGroup} from '@/business/event/model';
import Box from '@mui/material/Box';
import {CreateOrderItemModel, Order} from '@/business/order/model';
import {BuyerData} from '@/features/fast-checkout/components/checkout/BuyerData';
import TicketBlockSelection, {clearSavedState} from '@/features/event/components/commons/TicketBlockSelection';
import {createOrder} from '@/business/order/service';
import {ignoreAxios} from '@/utils/errors';
import EventCoupon from '@/features/fast-checkout/components/checkout/EventCoupon';
import PayWithCreditCardButton from '@/features/fast-checkout/components/checkout/buttons/PayWithCreditCardButton';
import {useNavigate} from 'react-router-dom';
import {ProcessingFeeInfo} from "@/features/checkout/components/utils/ProcessingFeeInfo";
import {PayWithPixButton} from "@/features/fast-checkout/components/checkout/buttons/PayWithPixButton";

interface Props {
    event: Event;
    blocks: TicketBlockGroup[];
    buyer: BuyerData;
    link: string | null;
    onReady: (order: Order) => void
}

const SelectTickets = ({buyer, onReady, event, blocks, link}: Props) => {

    const [items, setItems] = useState<CreateOrderItemModel[]>([]);
    const [coupon, setCoupon] = useState<CouponResponse | undefined>();
    const navigate = useNavigate();

    const constHandlePayWithCreditCard = () => {
        createOrder({event: event.id, items, coupon: coupon?.code}, link).then(value => {
            clearSavedState(event.id);
            navigate(`/pedido/${value.id}`);
        }).catch(ignoreAxios);
    }

    return (
        <Box>
            <TicketBlockSelection event={event.id} blocks={blocks} onChange={setItems} coupon={coupon}/>

            <ProcessingFeeInfo/>

            <EventCoupon event={event.id} blocks={blocks} onCouponApplied={setCoupon} containerSx={{mt: '15px'}}/>

            <PayWithPixButton event={event.id} items={items} buyer={buyer} link={link} onReady={onReady}
                              coupon={coupon}/>
            <PayWithCreditCardButton buyer={buyer} createOrder={constHandlePayWithCreditCard} disabled={!items.length}/>
        </Box>
    )
}

export default SelectTickets
