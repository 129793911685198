import * as React from "react";
import {useMemo} from "react";
import {PaymentMethodType} from "@/business/payment/model";
import {MessageUtils} from "@/utils/message";
import {createFastCheckoutOrder} from "@/business/order/service";
import {clearSavedState} from "@/features/event/components/commons/TicketBlockSelection";
import {ignoreAxios} from "@/utils/errors";
import {CouponResponse} from "@/business/event/model";
import {BuyerData} from "@/features/fast-checkout/components/checkout/BuyerData";
import {CreateOrderItemModel, Order} from "@/business/order/model";
import Button from "@mui/material/Button";
import {useGetPaymentMethodsGroups} from "@/business/payment/hooks/useGetPaymentMethodsGroups";
import {useSelectedPaymentMethodContext} from "@/features/checkout/contexts/selected-payment-method";

interface Props {
    event: number;
    items: CreateOrderItemModel[];
    buyer: BuyerData;
    link: string | null;
    onReady: (order: Order) => void;
    coupon?: CouponResponse
}

export const PayWithPixButton = ({event, items, buyer, link, onReady, coupon}: Props) => {
    const {methods} = useGetPaymentMethodsGroups(event);
    const {setSelectedPayment} = useSelectedPaymentMethodContext();

    const pixConfig = useMemo(() => {
        const method = methods.find(value => value.type === PaymentMethodType.PIX);
        if (!method?.options?.length) {
            return null;
        }
        return method.options[0];
    }, [methods]);

    if (!pixConfig) {
        return null;
    }

    const constHandlePayWithPix = () => {
        if (!pixConfig) {
            MessageUtils.notifyError("PIX não habilitado para este evento");
            return;
        }
        setSelectedPayment(pixConfig);
        createFastCheckoutOrder({
            event: event,
            items,
            buyer: buyer,
            coupon: coupon?.code
        }, link).then(value => {
            clearSavedState(event);
            onReady(value);
        }).catch(ignoreAxios);
    }

    if (!pixConfig) {
        return null;
    }

    return (<Button variant="contained" sx={{mb: '5px', width: '100%', mt: '15px'}} disabled={!items.length}
                    onClick={constHandlePayWithPix}>
        Pagar com PIX
    </Button>);
}
