import * as Sentry from "@sentry/react";
import {SentryConfig} from '@/config/envs/environment';
import {User} from '@/business/security/model';


export function sentryConfig(config: SentryConfig) {
    Sentry.init({
        dsn: config.DSN,
        integrations: [
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: config.TracesSampleRate,
        // Session Replay
        replaysSessionSampleRate: config.ReplaysSessionSampleRate,
        replaysOnErrorSampleRate: config.ReplaysOnErrorSampleRate,
        environment: config.Environment
    });
}

export function setSentryUser(user: User | undefined) {
    if (user) {
        Sentry.setUser({email: user.email, id: user.id});
    } else {
        Sentry.setUser(null);
    }
}
