import * as React from 'react';
import {useEffect, useState} from 'react';
import {Alert, AlertTitle, Box, Container} from '@mui/material';
import MainLayout from '@/layouts/main';
import DownloadTheApp from '@/layouts/banners/downloadTheApp';
import LoggedPage from '@/layouts/logged';
import PageTitle from '@/features/components/PageTitle';
import {ignoreAxios} from '@/utils/errors';
import {Order, OrderBase} from '@/business/order/model';
import {findOrderById, getOrders} from '@/business/order/service';
import {OrderStatus} from '@/business/order/domain';
import OrdersTable from '@/features/orders/components/OrdersTable';
import OrderDetails from '@/features/orders/components/OrderDetails';
import {useNavigate} from 'react-router-dom';
import EmptyList from '@/features/components/EmptyList';

const TicketsPage = () => {

    const [orders, setOrders] = useState<OrderBase[] | undefined>();
    const [open, setOpen] = useState(false);
    const [select, setSelected] = useState<Order | undefined>();
    const navigate = useNavigate();

    const handleCheckoutClick = (order: number) => {
        navigate(`/pedido/${order}`);
    }

    useEffect(() => {
        getOrders().then(value => {
            const openOrders = value.filter(a => a.status === OrderStatus.OPEN);
            if (openOrders.length) {
                setOpen(true);
            }
            const orders = [
                ...openOrders.sort((a, b) => `${b.date}`.localeCompare(`${a.date}`)),
                ...value.filter(a => a.status !== OrderStatus.OPEN).sort((a, b) => `${b.date}`.localeCompare(`${a.date}`))
            ];
            setOrders(orders);
        }).catch(ignoreAxios);
    }, []);

    const handleSelected = (item: OrderBase) => {
        findOrderById(item.id).then(value => {
            setSelected(value);
        }).catch(ignoreAxios);
    }

    return (
        <MainLayout logged={true}>
            <LoggedPage builder={() => {
                return (
                    <>
                        <Container>
                            <PageTitle text="Pedidos"/>
                            <DownloadTheApp
                                text="Controle seus pedidos, pague com segurança e tenha tudo na palma da mão."/>
                            {!!orders?.length && (
                                <Box>
                                    {open && (
                                        <Alert severity="warning" sx={{mb: '10px'}}>
                                            <AlertTitle>Você possui pedidos em aberto.</AlertTitle>
                                            Você possui alguns pedidos em aberto com pagamento pendente. Pedidos em
                                            aberto podem ser cancelados automaticamente pela plataforma, liberando os
                                            ingressos para compra por outras pessoas. <b>Finalize agora os seus pedido
                                            para garantir seus ingressos.</b>
                                        </Alert>
                                    )}
                                    <OrdersTable orders={orders} onSelect={handleSelected}
                                                 onCheckoutClick={item => handleCheckoutClick(item)}/>
                                </Box>
                            )}
                            {orders && !orders.length && (
                                <EmptyList title="Nenhum Pedido Realizado"
                                           text="Parece que você ainda não realizou nenhum pedido na plataforma, mas ainda dá tempo, corre lá que tem muita coisa legal por aqui."/>)}
                            {select && (
                                <OrderDetails order={select} onClose={() => setSelected(undefined)}
                                              onCheckoutClick={() => handleCheckoutClick(select.id)}/>
                            )}
                        </Container>
                    </>
                )
            }}/>
        </MainLayout>
    )
}

export default TicketsPage;
