import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Container, Grid} from '@mui/material';
import MainLayout from '@/layouts/main';
import DownloadTheApp from '@/layouts/banners/downloadTheApp';
import TicketGroupItem from '@/features/tickets/components/TicketGroupItem';
import TicketsModal from '@/features/tickets/components/TicketsModal';
import LoggedPage from '@/layouts/logged';
import PageTitle from '@/features/components/PageTitle';
import {getEventsWithTickets, getTickets} from '@/business/tickets/service';
import {ignoreAxios} from '@/utils/errors';
import EmptyList from '@/features/components/EmptyList';
import Box from '@mui/material/Box';
import {EventBase} from '@/business/event/model';
import {EventTickets} from '@/business/tickets/model';
import {ItemType} from '@/business/order/domain';
import {useQuery} from '@/utils/hooks/useQuery';

export const TITLES: { [key in ItemType]: any } = {
    [ItemType.TABLE]: 'Mesas Reservadas',
    [ItemType.TICKET]: 'Ingressos',
    [ItemType.VIP_LIST]: 'Lista VIP'
};

export const EMPTY_TITLES: { [key in ItemType]: any } = {
    [ItemType.TABLE]: 'Nenhuma reserva de mesa encontrada',
    [ItemType.TICKET]: 'Nenhum ingresso disponível',
    [ItemType.VIP_LIST]: 'Você não possui nenhuma Lista VIP ativa'
};

export const EMPTY_DESCRIPTIONS: { [key in ItemType]: any } = {
    [ItemType.TABLE]: 'Parece que você ainda não reservou nenhuma mesa para um evento, mas ainda dá tempo de aproveitar essa super vantagem, corre lá no seu evento favorito pra ver se essa função está disponível.',
    [ItemType.TICKET]: 'Não contramos nenhum ingresso ativo, mas ainda dá tempo de aproveitar e garantir um ingresso para o melhor evento hoje, corre lá.',
    [ItemType.VIP_LIST]: 'Parece que você ainda não incluiu seu nome na lista VIP de nenhum evento, mas ainda dá tempo de aproveitar essa super vantagem, corre lá no seu evento favorito.'
};

export const EDIT_USER_LABEL: { [key in ItemType]: any } = {
    [ItemType.TABLE]: 'Editar Responsável',
    [ItemType.TICKET]: 'Editar Usuário',
    [ItemType.VIP_LIST]: null
};

const TicketsViewPage = ({type}: { type: ItemType }) => {

    const [tickets, setTickets] = useState<EventTickets | undefined>();
    const [events, setEvents] = useState<EventBase[] | undefined>();
    const [selected, setSelected] = useState<EventBase | undefined>();
    const prev = useQuery().get('event');

    const handleSelect = useCallback((event: EventBase) => {
        getTickets(event.id, type).then(value => {
            setSelected(event);
            setTickets(value);
        }).catch(ignoreAxios);
    }, [type]);

    useEffect(() => {
        getEventsWithTickets(type).then(value => {
            setEvents(value);
            if (prev) {
                const id = parseInt(prev, 10);
                const event = value.find(a => a.id === id);
                if (event) {
                    handleSelect(event);
                }
            }
        }).catch(ignoreAxios);
    }, [type, prev, handleSelect]);


    return (
        <MainLayout logged={true}>
            <LoggedPage builder={() => {
                return (
                    <>
                        <Container>
                            <PageTitle text={TITLES[type]}/>
                            <DownloadTheApp text="Tenha todos os ingressos sempre com você, mesmo sem internet."/>

                            <Grid container spacing={2}>
                                {(events || []).map(a => (<Grid key={a.id} item xs={12} md={4}>
                                    <TicketGroupItem event={a} onClick={() => handleSelect(a)}/>
                                </Grid>))}
                            </Grid>

                            {events && !events.length && (
                                <Box paddingTop='30px'>
                                    <EmptyList title={EMPTY_TITLES[type]}
                                               text={EMPTY_DESCRIPTIONS[type]}/>
                                </Box>
                            )}

                            {selected && tickets && (
                                <TicketsModal event={selected} onCancel={() => setSelected(undefined)}
                                              tickets={tickets}/>
                            )}
                        </Container>
                    </>
                )
            }}/>
        </MainLayout>
    )
}

const TicketsPage = () => {
    return <TicketsViewPage type={ItemType.TICKET}/>
}

export const TablesPage = () => {
    return <TicketsViewPage type={ItemType.TABLE}/>
}

export default TicketsPage;
