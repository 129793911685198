import {Control, Controller} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import {findAddressByCep} from '@/business/location/service';
import {ignoreAxios} from '@/utils/errors';
import {AddressBase} from '@/business/location/model';
import {maskCep} from '@/utils/masks/cep';
import {maskInt} from '@/utils/masks/number';

interface Props {
    control: Control<any>,
    error: boolean,
    helperText: string | null,
    addressLoaded: (address: AddressBase) => void
}


const CepField = ({control, error, helperText, addressLoaded}: Props) => {

    const handleChange = (value: string, onChange: any) => {
        onChange(maskCep(value));
        if (value.length === 9) {
            findAddressByCep(maskInt(value))
                .then(address => addressLoaded(address))
                .catch(ignoreAxios);
        }
    }

    return (
        <Controller
            control={control}
            name="cep"
            rules={{required: true, minLength: 9}}
            render={({field: {onChange, name, value}}) => (
                <TextField
                    name={name}
                    value={value ?? ''}
                    onChange={(e) => handleChange(e.target.value, onChange)}
                    error={error}
                    helperText={helperText}
                    margin="normal"
                    required
                    fullWidth
                    label="CEP"
                    inputProps={{maxLength: 9}}
                />
            )}
        />
    )
}

export default CepField;
