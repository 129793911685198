export enum Gender {
    MASCULINO = 'MASCULINO',
    FEMININO = 'FEMININO',
    OUTRO = 'OUTRO'
}

export interface UserUpdateData {
    name: string;
    email: string;
    birthDate?: Date;
    gender?: Gender;
    phone?: number;
    cpf: string;
}

export interface User {
    id: number;
    name: string;
    email: string;
    avatar?: string;
    birthDate?: Date;
    gender?: Gender;
    phone?: number;
    cpf?: string;
    deleted: boolean;
}

export interface Authentication {
    adminToken?: string;
    token: string;
    subject: User;
}

export interface TokenMetadata {
    session: string;
    digits: number;
}

export interface NotificationOptions {
    emailsFromVisitedClients: boolean;
    emailsFromStaredClients: boolean;
}
