import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, Container, Grid} from '@mui/material';
import MainLayout from '@/layouts/main';
import DownloadTheApp from '@/layouts/banners/downloadTheApp';
import {VipListName} from '@/business/tickets/model';
import LoggedPage from '@/layouts/logged';
import PageTitle from '@/features/components/PageTitle';
import {getAllVipList, leaveVipList} from '@/business/tickets/service';
import {ignoreAxios} from '@/utils/errors';
import EmptyList from '@/features/components/EmptyList';
import VipListItem from '@/features/tickets/components/VipListItem';
import Confirm from '@/features/components/Confirm';
import {formatDateTime} from '@/utils/formatters';
import Typography from '@mui/material/Typography';
import {toast} from 'react-toastify';

const VipListsPage = () => {

    const [selected, setSelected] = useState<VipListName | undefined>();
    const [tickets, setTickets] = useState<VipListName[] | undefined>();

    useEffect(() => {
        getAllVipList().then(value => {
            setTickets(value);
        }).catch(ignoreAxios);
    }, []);

    const leave = (item: VipListName) => {
        leaveVipList(item.event.id).then(() => {
            toast.success("Nome removido da lista VIP do evento " + item.event.name);
            setSelected(undefined);
            setTickets(
                tickets?.filter(a => a.id !== item.id)
            );
        }).catch(ignoreAxios)
    }

    return (
        <MainLayout logged={true}>
            <LoggedPage builder={() => {
                return (
                    <>
                        <Container>
                            <PageTitle text="Listas VIP"/>
                            <DownloadTheApp text="Tenha todos os ingressos sempre com você, mesmo sem internet."/>
                            <Grid container spacing={2}>
                                {(tickets || []).map(a => (<Grid key={a.id} item xs={12} md={4}>
                                    <VipListItem item={a} onRemoveRequested={setSelected}/>
                                </Grid>))}
                            </Grid>
                            {tickets && !tickets.length && (
                                <Box paddingTop='30px'>
                                    <EmptyList title="Nenhuma Lista VIP disponível"
                                               text="Parece que você ainda não incluiu seu nome na lista VIP de nenhum evento, mas ainda dá tempo de aproveitar essa super vantagem, corre lá no seu evento favorito."/>
                                </Box>
                            )}
                        </Container>
                        {selected && (
                            <Confirm
                                title="Sair da Lista VIP?"
                                text={(
                                    <Typography component="span">
                                        Sentimos muito em vê-lo partir! Mas lembre-se: Caso mude de ideia, você pode
                                        incluir seu nome novamente
                                        até <b> {formatDateTime(selected.insertDateLimit)}</b> . Remover seu nome da
                                        lista VIP?
                                    </Typography>
                                )}
                                onConfirm={() => leave(selected)} onCancel={() => setSelected(undefined)}/>
                        )}
                    </>
                )
            }}/>
        </MainLayout>
    )
}

export default VipListsPage;
