import {FieldError, Merge} from 'react-hook-form';
import {MessageUtils} from '@/utils/message';

export interface ExtraInfo {
    patternMsg?: string,
    minLength?: number;
}


const message = (error: FieldError | Merge<FieldError, any> | undefined, extra?: ExtraInfo): string | null => {
    if (!error) {
        return null
    }

    const type = error.type

    if (error.message) {
        return error.message;
    }

    if (type === 'required') {
        return 'Este campo é obrigatório'
    }
    if (type === 'pattern') {
        return extra?.patternMsg ?? 'Formato do campo inválido';
    }
    if (type === 'minLength') {
        if (extra?.minLength) {
            return `O campo deve ter ${extra?.minLength} caracteres ou mais.`;
        }
        return 'O campo não atingiu a quantidade mínima de caracteres';
    }
    return type;
}

export function ignoreAxios(error: any) {
    if (error.isBusinessError) {
        MessageUtils.notifyError(error.message);
        return;
    }
    if (!error.isAxiosError) {
        throw error;
    }
}

export default message
