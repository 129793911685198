import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import message, {ignoreAxios} from '@/utils/errors';
import {InvoiceAddress} from '@/business/payment/model';
import {useFormContext} from 'react-hook-form';
import ControlledTextField from '@/features/components/ControlledTextField';
import {Grid} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ControlledSelect from '@/features/components/ControlledSelect';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/redux';
import {getInvoiceAddress} from '@/business/payment/service';
import {set} from '@/features/checkout/stores/address-store';
import Patterns from '@/utils/patterns';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CreateInvoiceAddressModal from '@/features/checkout/components/CreateInvoiceAddressModal';
import {upperCase} from '@/utils/masks/text';
import {maskCardNumber, maskValidTrough} from '@/utils/masks/card';
import {maskInt} from '@/utils/masks/number';
import {formatInvoiceAddress} from '@/utils/formatters';
import Typography from '@mui/material/Typography';

export const DEFAULTS = {
    alias: '',
    number: '',
    validTrough: '',
    code: '',
    holder: '',
    address: ''
}

export interface Props {
    onSubmit: (data: any) => void,
}

const InvoiceAddressLabel = ({address}: { address: InvoiceAddress }) => {
    const formatted = formatInvoiceAddress(address);
    return (
        <Box>
            <Typography variant="body1">
                <b>
                    {formatted.line1}
                </b>
            </Typography>
            <Typography variant="body2">
                {formatted.line2}
            </Typography>
        </Box>
    )
}


const CreateCardForm = ({onSubmit}: Props) => {
    const {control, setValue, handleSubmit, formState: {errors, isSubmitted}} = useFormContext();
    const {items: addressList} = useSelector((state: RootState) => state.address);
    const dispatch = useDispatch()
    const [addressModal, setAddressModal] = useState(false);

    const handleNewAddress = (address: InvoiceAddress) => {
        setValue('address', address.id);
        setAddressModal(false);
    }

    useEffect(() => {
        if (!addressList) {
            getInvoiceAddress().then(value => {
                dispatch(set(value))
            }).catch(ignoreAxios)
        }
    }, [addressList, dispatch]);

    return (
        <Box>
            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Grid container columnSpacing={2}>
                    <Grid item xs={12} md={6}>
                        <ControlledTextField
                            control={control}
                            name="number"
                            formatter={maskCardNumber}
                            rules={{required: true, pattern: Patterns.creditCard}}
                            error={isSubmitted && !!errors.number}
                            helperText={message(errors.number, {patternMsg: 'Cartão de Crédito Inválido/Incompleto'})}
                            margin="normal"
                            required
                            fullWidth
                            label="Número do Cartão"
                            inputProps={{maxLength: 19}}
                        />
                    </Grid>
                    <Grid item xs={8} md={4}>
                        <ControlledTextField
                            control={control}
                            name="validTrough"
                            formatter={maskValidTrough}
                            rules={{required: true, pattern: Patterns.creditCardDueDate}}
                            error={isSubmitted && !!errors.validTrough}
                            helperText={message(errors.validTrough, {patternMsg: 'Deve estar no formado DD/MM'})}
                            margin="normal"
                            required
                            fullWidth
                            label="Validade"
                            inputProps={{maxLength: 5}}
                        />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <ControlledTextField
                            control={control}
                            name="code"
                            formatter={maskInt}
                            rules={{required: true, minLength: 3}}
                            error={isSubmitted && !!errors.code}
                            helperText={message(errors.code, {minLength: 3})}
                            margin="normal"
                            required
                            fullWidth
                            label="CVV"
                            inputProps={{maxLength: 4}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ControlledTextField
                            control={control}
                            name="holder"
                            formatter={upperCase}
                            rules={{required: true, pattern: Patterns.asciiChar}}
                            error={isSubmitted && !!errors.holder}
                            helperText={message(errors.holder, {patternMsg: 'Não deve conter caracteres especiais ou números'})}
                            margin="normal"
                            required
                            fullWidth
                            label="Nome do Portador"
                            inputProps={{maxLength: 250}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ControlledTextField
                            control={control}
                            name="alias"
                            error={isSubmitted && !!errors.alias}
                            helperText={message(errors.alias)}
                            margin="normal"
                            fullWidth
                            label="Apelido (Opcional)"
                            inputProps={{maxLength: 250}}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ControlledSelect
                            control={control}
                            name="address"
                            label="Endereço de Fatura"
                            error={isSubmitted && !!errors.address}
                            helperText={message(errors.address)}
                            rules={{required: true}}>
                            {addressList?.map(value => <MenuItem key={value.id} value={value.id}>
                                <InvoiceAddressLabel address={value}/>
                            </MenuItem>)}
                        </ControlledSelect>
                        <Box textAlign="right">
                            <Button variant="text" startIcon={<AddIcon/>} onClick={() => setAddressModal(true)}>
                                Cadastrar endereço
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <input type="submit" style={{display: 'none'}}/>
            </Box>
            {addressModal && (
                <CreateInvoiceAddressModal onSave={handleNewAddress} onCancel={() => setAddressModal(false)}/>
            )}
        </Box>
    )
}

export default CreateCardForm
