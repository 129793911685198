import * as React from 'react';
import MainLayout from '@/layouts/main';
import Featured from '@/features/event/components/listing/Featured';
import EventList from '@/features/event/components/listing/EventList';
import DownloadTheApp from '@/layouts/banners/downloadTheApp';
import {Container} from '@mui/material';
import Box from '@mui/material/Box';

const HomePage = () => {
    return (
        <MainLayout>
            <Container>
                <DownloadTheApp/>
                <Featured></Featured>
                <Box sx={{paddingTop: '40px'}}>
                    <EventList></EventList>
                </Box>
            </Container>
        </MainLayout>
    )
}

export default HomePage
