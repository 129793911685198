import {TicketDisplay} from '@/business/tickets/model';
import {Tooltip} from '@mui/material';
import {deleteTicket, exportTicketById} from '@/business/tickets/service';
import {download} from '@/utils/download';
import {ignoreAxios} from '@/utils/errors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ValueLabel from '@/features/components/ValueLabel';
import Link from '@mui/material/Link';
import {preventDefault} from '@/utils/functions';
import * as React from 'react';
import Swal from 'sweetalert2';
import {variables} from '@/config';
import {MessageUtils} from '@/utils/message';
import TicketGuests from '@/features/tickets/components/tickets-modal/TicketGuests';
import QRCode from 'react-qr-code';
import {upperCase} from '@/utils/masks/text';

interface Props {
    ticket: TicketDisplay,
    onEdit: (ticket: TicketDisplay) => void,
    onDelete: VoidFunction,
    disabled: boolean,
    display?: {
        name?: boolean;
        document?: boolean;
        download?: boolean
    }
}

const TicketItem = ({
                        ticket,
                        onEdit,
                        disabled,
                        onDelete,
                        display = {name: true, document: true, download: true}
                    }: Props) => {
    const user = ticket.ticketUser!;

    const handleDownload = () => {
        const ids = [ticket.id, ...((ticket?.guests ?? []).map(value => value.id))];
        exportTicketById(ids).then(value => {
            download(value, `Ticket ${ticket.code} - ${ticket.label}.pdf`).catch(ignoreAxios);
        }).catch(ignoreAxios);
    }

    const handleDelete = () => {
        if (!ticket.freeTicket) {
            Swal.fire({
                title: 'Ingresso Pago',
                text: 'Parece que o ingresso que você está tentando cancelar foi adquirido através de uma compra pelo aplicativo, ainda não conseguimos excluir esse tipo de ingresso automáticamente, mas você pode entrar em contato com a gente para podermos te ajudar.',
                icon: 'error',
                confirmButtonText: 'Entrar em Contato',
                cancelButtonText: 'Cancelar',
                showCancelButton: true,
            }).then(value => {
                if (value.isConfirmed) {
                    window.open(variables.contactUrl, '_blank');
                }
            })
            return;
        }
        deleteTicket(ticket.id).then(() => {
            MessageUtils.notifySuccess('Ingresso cancelado com sucesso');
            onDelete();
        }).catch(ignoreAxios)
    }

    return (
        <Box sx={{border: '1px solid #F5F7F8'}}>
            <Box sx={{
                display: 'flex',
                flexFlow: 'wrap',
                backgroundColor: '#F5F7F8',
            }}>
                <Box sx={{
                    m: 'auto',
                    padding: '10px 15px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center'
                }}>
                    {ticket.qrCodeUrl && (
                        <img src={ticket.qrCodeUrl} style={{width: 128}} alt=""/>
                    )}
                    {!ticket.qrCodeUrl && (
                        <>
                            USING CODE FALLBACK
                            <QRCode value={ticket.code} size={128}/>
                        </>
                    )}
                    <Typography variant="h6">
                        <b>
                            {ticket.code}
                        </b>
                    </Typography>
                </Box>
                <Box sx={{padding: '10px 15px', flex: 1, backgroundColor: 'white', minWidth: '200px'}}>
                    <ValueLabel label="Lote" value={upperCase(ticket.label ?? 'Promocional')}/>
                    {display?.name && (
                        <ValueLabel label="Nome" value={user.name} marginTop="5px"/>
                    )}
                    {display?.document && (
                        <ValueLabel label="Documento" value={user.document} marginTop="5px"
                                    valueSx={{whiteSpace: 'nowrap'}}/>
                    )}
                    {(display?.download || !disabled) && (
                        <Box sx={{pt: '5px', display: 'flex', flexWrap: 'wrap', gap: '5px'}}>
                            {display?.download && (
                                <Link onClick={preventDefault(handleDownload)} href="#" sx={{mr: '5px'}}>
                                    Baixar Ingresso
                                </Link>
                            )}
                            {!disabled && (
                                <Tooltip arrow
                                         title={ticket.usedAt ? 'O ingresso já foi validado na portaria, não é possível alterar o usuário' : ''}>
                                    <Link onClick={preventDefault(() => onEdit(ticket))} href="#" component="button"
                                          disabled={!!ticket.usedAt} sx={{mr: '5px'}}>
                                        Alterar Usuário
                                    </Link>
                                </Tooltip>
                            )}
                            {!disabled && (
                                <Tooltip arrow
                                         title={ticket.usedAt ? 'O ingresso já foi validado na portaria, não é possível cancelar o ingresso' : ''}>
                                    <Link onClick={preventDefault(handleDelete)} href="#" color="error.light"
                                          component="button" disabled={!!ticket.usedAt}>
                                        Cancelar
                                    </Link>
                                </Tooltip>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
            <TicketGuests initialTickets={ticket.guests ?? []} disabled={disabled}/>
        </Box>
    )
}

export default TicketItem;
