import * as React from 'react';
import {useState} from 'react';
import {Checkbox, Dialog, DialogContent, FormControlLabel} from '@mui/material';
import {Event} from '@/business/event/model';
import Typography from '@mui/material/Typography';
import {formatDateTime, formatDecimal} from '@/utils/formatters';
import Button from '@mui/material/Button';
import {joinVipList} from '@/business/tickets/service';
import {ignoreAxios} from '@/utils/errors';
import Link from '@mui/material/Link';
import DialogHeader from '@/features/components/DialogHeader';
import {preventDefault} from '@/utils/functions';
import VipListRules from '@/features/event/components/commons/VipListRules';
import EventCover from '@/features/components/EventCover';

export interface Props {
    visible: boolean;
    event: Event,
    onClose: () => void;
    onJoined: () => void;
    link?: string | null
}

const JoinVipListModal = ({visible, event, onClose, onJoined, link}: Props) => {

    const [accepted, setAccepted] = useState(false);
    const [rules, setRules] = useState(false);

    const handleJoinList = () => {
        joinVipList(event.id, link).then(() => {
            onJoined();
        }).catch(ignoreAxios);
    }

    const rolesText = () => {
        return (
            <Typography variant="subtitle1">
                Estou de acordo com as <Link href="#" onClick={preventDefault(() => setRules(true))}>
                Regras de Utilização</Link>
            </Typography>
        )

    }

    return (
        <Dialog onClose={onClose} open={visible}>
            <DialogHeader onClose={onClose}>
                Lista VIP
            </DialogHeader>
            <DialogContent>
                <EventCover src={event.cover} label={event.name}/>

                <Typography variant="subtitle1">
                    Antes de adicionarmos seu nome, temos algumas informações importantes:
                </Typography>

                <Typography variant="subtitle1" marginTop="10px">
                    <b>Data do Evento:</b> {formatDateTime(event.date)}
                </Typography>

                <Typography variant="subtitle1">
                    <b>Valor promocional do ingresso: </b> R$ {formatDecimal(event.vipListConfiguration?.value!)}
                </Typography>

                <Typography variant="subtitle1">
                    <b>Horário limite de chegada no
                        Evento: </b> {formatDateTime(event.vipListConfiguration?.useDateLimit!)}
                </Typography>

                <FormControlLabel required control={<Checkbox onChange={(_, checked) => setAccepted(checked)}/>}
                                  label={rolesText()}/>


                <Button variant="contained" onClick={handleJoinList} sx={{width: '100%', marginTop: '10px'}}
                        disabled={!accepted}>
                    Incluir Nome na Lista
                </Button>
            </DialogContent>
            <VipListRules open={rules} onClose={() => setRules(false)}/>
        </Dialog>
    )
}

export default JoinVipListModal
