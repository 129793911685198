export enum CardType {
    AMERICAN_EXPRESS = 'AMERICAN_EXPRESS',
    VISA = 'VISA',
    MASTERCARD = 'MASTERCARD',
    DINERS = 'DINERS',
    DISCOVER = 'DISCOVER',
    RUPAY = 'RUPAY',
    JCB = 'JCB',
    MAESTRO = 'MAESTRO',
    OUTROS = 'OUTROS'
}

export interface CreditCard {
    id: number;
    number: string;
    alias?: string;
    type: CardType;
}

export interface InvoiceAddress {
    id: number;
    cep: number;
    street?: string;
    number?: string;
    complement?: string;
    city?: string;
    uf?: string;
}

export interface PaymentSettings {
    key: string;
    api: string;
}

export interface UnTokenizedCard {
    number: string;
    validTrough: string;
    code: string;
    holder: string;
    address: InvoiceAddress;
    alias: string;
}

export interface PaymentMethod {
    type: PaymentMethodType;
    installments: number;
    processingFee: number;
    taxRate: number;
    minimumValue: number;
}

export enum PaymentMethodType {
    PIX = "PIX",
    CREDIT_CARD = "CREDIT_CARD"
}
