import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {deleteCreditCard, getCreditCards} from '@/business/payment/service';
import {ignoreAxios} from '@/utils/errors';
import CreditCardItem from '@/features/checkout/components/payment/CreditCardItem';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/redux';
import {removeCard, setCards} from '@/features/checkout/stores/card-store';
import {CreditCard} from '@/business/payment/model';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Confirm from '@/features/components/Confirm';
import {toast} from 'react-toastify';
import Typography from '@mui/material/Typography';

const UserCards = () => {
    const [confirm, setConfirm] = useState<CreditCard | undefined>();
    const {items: cards} = useSelector((state: RootState) => state.card);
    const dispatch = useDispatch()

    useEffect(() => {
        if (!cards) {
            getCreditCards()
                .then(value => dispatch(setCards(value)))
                .catch(ignoreAxios)
        }
    }, [cards, dispatch]);

    const deleteCard = () => {
        if (!confirm) {
            return;
        }
        deleteCreditCard(confirm.id)
            .then(() => {
                dispatch(removeCard(confirm.id));
                toast.success("Cartão excluído com sucesso");
                setConfirm(undefined);
            })
            .catch(ignoreAxios)
    }

    return (
        <Box>
            {(cards ?? []).map((card) => (<Box key={card.id} sx={{paddingBottom: '10px'}}>
                <CreditCardItem card={card} extra={(
                    <IconButton aria-label="delete" onClick={() => setConfirm(card)}>
                        <DeleteIcon/>
                    </IconButton>
                )}/>
            </Box>))}

            {cards && !cards.length && (
                <Typography>
                    Nenhum Cartão cadastrado.
                </Typography>
            )}

            {confirm && (
                <Confirm
                    title="Excluir Cartão?"
                    text="Você não será capaz de fazer novas compras com o cartão, mas as compras já realizadas não serão canceladas."
                    onConfirm={() => deleteCard()} onCancel={() => setConfirm(undefined)}/>
            )}
        </Box>
    )
}

export default UserCards
