import {useForm} from 'react-hook-form';
import {validateToken} from '@/business/security/service';
import message, {ignoreAxios} from '@/utils/errors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as React from 'react';
import {TokenMetadata} from '@/business/security/model';

export interface Props {
    onValidated: (token: string) => void,
    metadata: TokenMetadata,
}

const RecoveryTokenForm = ({onValidated, metadata}: Props) => {

    const {register, handleSubmit, formState: {errors, isSubmitted}} = useForm({
        defaultValues: {token: ''}
    });

    const onSubmit = (data: any) => {
        validateToken(metadata.session, data.token).then(() => {
            onValidated(data.token);
        }).catch(ignoreAxios)
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>

            <Typography variant="subtitle1" sx={{pt: '10px', textAlign: 'center', pb: '10px'}}>
                Enviamos um código de verificação para o Email cadastrado. Agora basta informar o código recebido.
            </Typography>

            <TextField
                error={isSubmitted && !!errors.token}
                helperText={message(errors.token)}
                margin="normal"
                required
                fullWidth
                label="Código de Verificação"
                autoFocus
                inputProps={{
                    maxLength: metadata.digits
                }}
                {...register("token", {required: true})}
            />

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
            >
                Validar Token
            </Button>

        </Box>
    )
}


export default RecoveryTokenForm;
