import {useTaxRate} from "@/features/checkout/hooks/useTaxRate";
import * as React from "react";
import {useMemo} from "react";
import {formatDecimal} from "@/utils/formatters";
import Typography from "@mui/material/Typography";

interface Props {
    items: { unitValue: number; quantity: number; }[]
}

export const OrderTotalValue = ({items}: Props) => {

    const {applyTaxRate, processingFee} = useTaxRate();

    const total = useMemo(() => {
        let val = items
            .map(item => applyTaxRate(item.unitValue) * item.quantity)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

        if (processingFee) {
            val += processingFee;
        }
        return val;
    }, [items, applyTaxRate, processingFee]);

    return <Typography sx={{color: 'white', fontWeight: 'bold !important'}}>
        Total: R$ {formatDecimal(total)}
    </Typography>
}