import * as React from 'react';
import Button from '@mui/material/Button';
import {Dialog, DialogActions, DialogContent} from '@mui/material';
import {useForm} from 'react-hook-form';
import TextField from '@mui/material/TextField';
import message, {ignoreAxios} from '@/utils/errors';
import Box from '@mui/material/Box';
import {ValidateResult} from 'react-hook-form/dist/types/validator';
import {changePassword} from '@/business/security/service';
import {toast} from 'react-toastify';
import DialogHeader from '@/features/components/DialogHeader';

export interface Props {
    onClose: () => void
}

const ChangePasswordModal = ({onClose}: Props) => {

    const {
        handleSubmit,
        register,
        formState: {errors, isSubmitted}
    } = useForm({defaultValues: {password: '', current: '', verify: ''}});

    const submit = (data: { password: string, current: string }) => {
        changePassword(data.password, data.current)
            .then(() => {
                toast.success("Senha alterada com sucesso");
                onClose();
            })
            .catch(ignoreAxios)
    }

    const validateConfirm = (value: any, values: any): ValidateResult => {
        const {password} = values;
        if (!password || !value || password === value) {
            return undefined;
        }
        return "As senhas não conferem"
    }

    return (
        <Dialog onClose={onClose} open={true} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <DialogHeader onClose={onClose}>
                Alterar Senha
            </DialogHeader>
            <DialogContent>
                <Box component="form" onSubmit={handleSubmit(submit)} noValidate>
                    <TextField
                        error={isSubmitted && !!errors.current}
                        helperText={message(errors.current)}
                        margin="normal"
                        required
                        fullWidth
                        label="Senha Atual"
                        type="password"
                        autoComplete="current-password"
                        {...register("current", {required: true})}
                    />
                    <TextField
                        error={isSubmitted && !!errors.password}
                        helperText={message(errors.password, {minLength: 5})}
                        margin="normal"
                        required
                        fullWidth
                        label="Nova Senha"
                        type="password"
                        {...register("password", {required: true, minLength: 5})}
                    />
                    <TextField
                        error={isSubmitted && !!errors.verify}
                        helperText={message(errors.verify)}
                        margin="normal"
                        required
                        fullWidth
                        label="Confirme a Nova Senha"
                        type="password"
                        {...register("verify", {required: true, validate: validateConfirm})}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={handleSubmit(submit)} variant="contained" autoFocus>
                    Alterar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChangePasswordModal
