import {TokenMetadata} from '@/business/security/model';
import {useForm} from 'react-hook-form';
import {sendToken} from '@/business/security/service';
import message, {ignoreAxios} from '@/utils/errors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {preventDefault} from '@/utils/functions';
import TextField from '@mui/material/TextField';
import Patterns from '@/utils/patterns';
import Button from '@mui/material/Button';
import * as React from 'react';

export interface Props {
    onSent: (meta: TokenMetadata) => void,
    onCreateAccount: () => void,
    email?: string
}

const RecoveryEmailForm = ({onSent, onCreateAccount, email}: Props) => {

    const {register, handleSubmit, formState: {errors, isSubmitted}} = useForm({
        defaultValues: {email: email ?? ''}
    });

    const onSubmit = (data: any) => {
        sendToken(data.email).then((meta) => {
            onSent(meta);
        }).catch(ignoreAxios)
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>

            <Typography variant="subtitle1" sx={{pt: '10px', textAlign: 'center', pb: '10px'}}>
                Primeiro, nos informe o seu email cadastrado na plataforma. Não tem cadastro?
                <Link href="#" onClick={preventDefault(onCreateAccount)}>
                    <b>
                        {' '} Criar uma conta.
                    </b>
                </Link>
            </Typography>

            <TextField
                error={isSubmitted && !!errors.email}
                helperText={message(errors.email, {patternMsg: 'O email informado é inválido'})}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                autoComplete="email"
                autoFocus
                {...register("email", {required: true, pattern: Patterns.email})}
            />

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
            >
                Confirmar Email
            </Button>

        </Box>
    )
}


export default RecoveryEmailForm;
