import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

export const VipListStatusExpired = () => {
    return <Box>
        <Typography variant="body1">
            Neste evento você pode pôr seu nome na lista e garantir a melhor experiência com preços especiais.
        </Typography>
        <Box textAlign="center" sx={{marginTop: '15px'}}>
            <Typography variant="body1" color="error.light" fontSize="0.8rem">
                <b>
                    O período para incluir o nome na lista esgotou, mas não desanime, fique de olho nos próximos eventos
                    para aproveitar esta super vantagem.
                </b>
            </Typography>

        </Box>
    </Box>
}
