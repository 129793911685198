import * as React from 'react';

export interface Props {
    text?: string
}

const DownloadTheApp = ({text}: Props) => {

    return <></>

    /*const isMobile = useMediaQuery('(max-width:475px)');
    const isCollapsed = useMediaQuery('(max-width:1000px)');

    const appButtons = (
        <Box textAlign="center">
            {(isMobile || !isCollapsed) && (
                <Typography variant="subtitle1" sx={{color: 'white'}}>
                    <b>BAIXE AGORA O APP</b>
                </Typography>
            )}
            <Box>
                <a href="https://apps.apple.com/br/app/mypromoter/id1576110172" target="_blank"
                   rel="noreferrer">
                    <img src={app_store_badge} style={{maxHeight: '30px'}} alt="Baixar para IOS"/>
                </a>
                <a style={{marginLeft: isCollapsed ? 0 : '10px'}}
                   href="https://play.google.com/store/apps/details?id=br.com.mypromoter.mypromoter"
                   target="_blank" rel="noreferrer">
                    <img src={play_store_badge} style={{maxHeight: '30px'}} alt="Baixar para Android"/>
                </a>
            </Box>
        </Box>
    );

    return (
        <Box paddingBottom="20px">
            <Box sx={{backgroundColor: '#8031FF'}} padding="15px" borderRadius="5px">
                <table width="100%">
                    <tbody>
                    <tr>
                        <td>
                            <Typography variant="h5" color="white">
                                {text ?? 'Não perca nada! Os melhores eventos, na palma da sua mão.'}
                            </Typography>
                        </td>
                        {!isMobile && (
                            <td style={{width: isCollapsed ? '100px' : '213px'}}>
                                {appButtons}
                            </td>
                        )}
                    </tr>
                    </tbody>
                </table>
                {isMobile && (<Box marginTop="10px">{appButtons}</Box>)}
            </Box>
        </Box>
    )*/
}

export default DownloadTheApp;
