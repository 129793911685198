import image from '@/assets/img/not_logged.png';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {User} from '@/business/security/model';
import React from 'react';
import {useAccountContext} from "@/utils/context/AccountContext";

export interface Props {
    builder: (user: User) => React.ReactNode,
    iconSize?: number,
}

const LoggedPage = ({builder, iconSize}: Props) => {

    const {user, loading} = useAccountContext();
    const {login} = useAccountContext();

    const handleLogin = () => {
        login.show();
    }

    if (loading) {
        return null
    }

    if (!user) {
        return (
            <>
                <Box textAlign="center" padding="30px">
                    <img src={image} alt="Carrinho Vazio"
                         style={{maxHeight: `${iconSize ?? 300}px`, maxWidth: '100%'}}/>
                    <Typography variant="h3">
                        Você não está logado
                    </Typography>
                    <Typography variant="subtitle1" sx={{marginTop: '20px', maxWidth: '600px', margin: 'auto'}}>
                        Você está tentando acessar uma funcionalidade autenticada, mas parece que ainda não fez login na
                        plataforma. Entre com seu email e senha ou crie uma nova conta para acessar essa página.
                    </Typography>
                    <Button variant="contained" onClick={handleLogin} sx={{marginTop: '30px'}}>
                        Entrar
                    </Button>
                </Box>
            </>
        )
    }

    return <>{builder(user)}</>

}


export default LoggedPage;
