export interface SentryConfig {
    TracesSampleRate: number;
    ReplaysSessionSampleRate: number;
    ReplaysOnErrorSampleRate: number;
    Environment: string;
    DSN: string;
}

export interface Environment {
    BackOfficeUrl: string;
    PlatformUrl: string;
    ApiUrl: string;
    BaseUrl: string;
    Features: {
        Coupon: boolean
    };
    Sentry?: SentryConfig
}

export const Environments: { [key: string]: Environment } = {
    'LOCAL': {
        BackOfficeUrl: "http://localhost:4200",
        PlatformUrl: "http://localhost:3002",
        ApiUrl: 'https://api.mypromoter.com.br/api/app/',
        Features: {
            Coupon: true
        },
        BaseUrl: 'localhost:3002',
    },
    'TEST': {
        BackOfficeUrl: "https://admin.mypromoter.dev.br",
        PlatformUrl: "https://mypromoter.dev.br",
        ApiUrl: 'https://api.mypromoter.dev.br/api/app/',
        Features: {
            Coupon: true
        },
        Sentry: {
            TracesSampleRate: .5,
            ReplaysOnErrorSampleRate: 1,
            ReplaysSessionSampleRate: .1,
            Environment: 'test',
            DSN: "https://6ba26829cd75f0d86680094f7f0e4587@o921746.ingest.sentry.io/4505955087417344"
        },
        BaseUrl: 'mypromoter.dev.br'
    },
    'PROD': {
        BackOfficeUrl: "https://admin.mypromoter.com.br",
        PlatformUrl: "https://mypromoter.com.br",
        ApiUrl: 'https://api.mypromoter.com.br/api/app/',
        Features: {
            Coupon: true
        },
        Sentry: {
            TracesSampleRate: .1,
            ReplaysOnErrorSampleRate: 1,
            ReplaysSessionSampleRate: .05,
            Environment: 'production',
            DSN: "https://6ba26829cd75f0d86680094f7f0e4587@o921746.ingest.sentry.io/4505955087417344"
        },
        BaseUrl: 'mypromoter.com.br'
    },
}

export function getEnv(envName: string | undefined): Environment {
    if (!envName) {
        throw Error(`Environment name not configured`);
    }
    const env = Environments[envName];
    if (!env) {
        throw Error(`Environment ${envName} not configured`);
    }
    return env;
}
