import {AddressBase} from '@/business/location/model';
import {trackPromise} from 'react-promise-tracker';
import axios from 'axios';

export function findAddressByCep(cep: string): Promise<AddressBase> {
    return trackPromise(
        axios.get<AddressBase>(`address?cep=${cep}`).then(response => {
            return response.data;
        })
    );
}
