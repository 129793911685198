const Patterns = {
    creditCard: /^\d{4} \d{4} \d{4} \d{4}$/,
    creditCardDueDate: /^\d{2}\/\d{2}$/,
    asciiChar: /^[a-zA-Z ]+$/,
    email: /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/,
    phone: /^\(\d{2}\)?\s*\d{4,5}-?\d{4}$/,
    cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
    cnpj: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
    date: /^\d{2}\/\d{2}\/\d{4}$/
}

export default Patterns;
