import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import AccountDataForm from '@/features/account/components/AccountDataForm';
import LoggedPage from '@/layouts/logged';
import {Accordion, AccordionDetails, AccordionSummary, Alert} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

export interface Props {
    onComplete: () => void,
    onBack: () => void,
}

const BuyerDateForm = ({onComplete, onBack}: Props) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <LoggedPage iconSize={100} builder={user => {
            const valid = !!user?.name && !!user?.email && !!user?.cpf && !!user?.phone && !!user?.birthDate;
            if (valid) {
                onComplete();
            }

            if (!valid) {
                return (
                    <Box>
                        <Typography variant="subtitle1">
                            <b>Dados do Comprador</b>
                        </Typography>
                        <Alert severity="info">
                            Complete seu cadastro para pagar usando Cartão de Crédito.
                        </Alert>
                        <AccountDataForm user={user} enabled={!valid} onSaved={onComplete} cancelText="Voltar"
                                         onCancel={onBack}/>
                    </Box>
                )
            }

            return (
                <Box>
                    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant="subtitle1">
                                <b>Dados do Comprador</b>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AccountDataForm user={user} enabled={!valid} onSaved={onComplete}/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )
        }}/>
    )
}

export default BuyerDateForm
