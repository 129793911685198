import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import {EventFilters, list, pages} from '@/business/event/service';
import {EventListing} from '@/business/event/model';
import EventItem from '@/features/event/components/listing/EventItem';
import Button from '@mui/material/Button';
import {ignoreAxios} from '@/utils/errors';
import {CircularProgress, Grid} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Typography from '@mui/material/Typography';
import EmptyList from '@/features/components/EmptyList';

export interface Props {
    loader?: boolean;
    filters?: EventFilters
}

const EventList = ({filters, loader = true}: Props) => {
    const [events, setEvents] = useState<EventListing[]>([]);
    const availablePages = useRef(0);
    const currentPage = useRef(-1);
    const [loading, setLoading] = useState(false);
    const useSystemLoader = loader && !events.length;
    const hasMore = currentPage.current < availablePages.current - 1;

    const loadMore = () => {
        setLoading(true);
        list(useSystemLoader, currentPage.current + 1, filters).then(value => {
            setEvents([...events, ...value]);
            currentPage.current++;
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            ignoreAxios(e);
        });
    }

    useEffect(() => {
        setLoading(true);
        pages(useSystemLoader).then(value => {
            availablePages.current = value;
            if (availablePages.current) {
                loadMore();
            } else {
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false);
        });
        // TODO: Review This
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!!events.length && (
                <Typography variant="h1" sx={{marginBottom: '20px'}}>
                    <b>Próximos Eventos</b>
                </Typography>
            )}
            <Grid container spacing={2}>
                {events.map(event =>
                    <Grid item xs={12} sm={6} md={4} lg={3} key={event.id}>
                        <EventItem event={event}></EventItem>
                    </Grid>
                )}
            </Grid>
            {loading && !useSystemLoader && (
                <Box textAlign="center" padding="90px 30px">
                    <CircularProgress size={150}/>
                    <Typography variant="h1">
                        Carregando os melhores eventos pra você
                    </Typography>
                </Box>
            )}

            {hasMore && (
                <Box textAlign='center' marginTop='10px'>
                    <Button variant="contained" disabled={loading} onClick={loadMore} startIcon={<RefreshIcon/>}>
                        Carregar Mais
                    </Button>
                </Box>
            )}

            {!loading && !events.length && (
                <EmptyList title="Nenhum evento encontrado"
                           text="Não encontramos nenhum evento com as opções informadas, mas não se preocupe ainda tem muita coisa pra você aproveitar e se divertir, corre lá."/>
            )}
        </>
    )
}

export default EventList
