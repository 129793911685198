import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import {useForm} from 'react-hook-form';
import message, {ignoreAxios} from '@/utils/errors';
import Patterns from '@/utils/patterns';
import {login as loginReq} from '@/business/security/service';
import {preventDefault} from '@/utils/functions';
import Typography from '@mui/material/Typography';
import LoginWithGoogle from '@/features/login/components/LoginWithGoogle';
import ControlledTextField from '@/features/components/ControlledTextField';
import {trim} from '@/utils/masks/text';
import {useAccountContext} from "@/utils/context/AccountContext";

export interface Props {
    onLogin?: () => void,
    onCreateAccount: () => void
    onForgetPassword: () => void,
    email?: string
}

const LoginForm = ({onLogin, onCreateAccount, onForgetPassword, email}: Props) => {

    const {control, register, handleSubmit, formState: {errors, isSubmitted}} = useForm({
        defaultValues: {
            email: email ?? "",
            password: ""
        }
    });
    const {setUser} = useAccountContext();

    const trigger = () => {
        if (onLogin) {
            onLogin()
        }
    }

    const onSubmit = (data: any) => {
        loginReq(data.email, data.password).then((user) => {
            setUser(user);
            trigger();
        }).catch(ignoreAxios)
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <ControlledTextField
                control={control}
                name="email"
                error={isSubmitted && !!errors.email}
                helperText={message(errors.email, {patternMsg: 'O email informado é inválido'})}
                margin="normal"
                required
                fullWidth
                label="Email"
                id="email"
                autoComplete="email"
                autoFocus
                formatter={trim}
                rules={{required: true, pattern: Patterns.email}}
            />
            <TextField
                error={isSubmitted && !!errors.password}
                helperText={message(errors.password)}
                margin="normal"
                required
                fullWidth
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                {...register("password", {required: true})}
            />
            <Box textAlign='right'>
                <Link href="#" variant="body2" onClick={preventDefault(onForgetPassword)}>
                    Esqueceu sua senha?
                </Link>
            </Box>

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
            >
                Entrar
            </Button>
            <Box mb={2}>
                <LoginWithGoogle onLogged={() => trigger()}/>
            </Box>
            <Box textAlign='right' paddingTop={2}>
                <Typography variant="body2" component="div">
                    Novo por aqui? &nbsp;
                    <Link href="#" onClick={preventDefault(onCreateAccount)}>
                        <b>Cadastre-se</b>
                    </Link>
                </Typography>
            </Box>

        </Box>
    )
}

export default LoginForm
