import {CreditCard} from '@/business/payment/model';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActionArea} from '@mui/material';
import {CardTypeLabels} from '@/business/payment/utils';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import * as React from 'react';
import Box from '@mui/material/Box';

export interface Props {
    card?: CreditCard,
    onSelect?: () => void,
    selected?: boolean,
    children?: React.ReactNode,
    extra?: React.ReactNode
}

const CreditCardItem = ({card, onSelect, selected, children, extra}: Props) => {
    const sx = selected ? {border: "2px solid", borderColor: 'success.light'} : {};

    const content = () => {
        return <CardContent sx={{p: '16px !important'}}>
            <table style={{width: '100%'}}>
                <tbody>
                <tr>
                    <td width="50px" style={{textAlign: 'center'}}>
                        {card && (
                            <img src={`/card_provider/${card.type}.png`} alt={card.type}
                                 style={{width: '100%'}}/>
                        )}
                        {!card && (
                            <AddCircleOutlineIcon/>
                        )}
                    </td>
                    <td style={{paddingLeft: '10px'}} valign="middle">
                        {!card && (
                            <Typography gutterBottom variant="subtitle1" component="span">
                                <b>
                                    Cadastrar Novo Cartão
                                </b>
                            </Typography>
                        )}

                        {card && (
                            <Box sx={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                                <Typography gutterBottom variant="subtitle1" component="div"
                                            sx={{fontWeight: 'bold', m: 0, marginRight: '10px'}}>
                                    {card.alias ?? CardTypeLabels[card.type]}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" component="div">
                                    {card.number}
                                </Typography>
                            </Box>
                        )}
                    </td>
                    {selected && (
                        <td width="1px" style={{whiteSpace: 'nowrap'}} valign="middle">
                            <Typography color="success.main" sx={{display: {xs: 'none', sm: 'block'}}}>
                                Selecionado
                            </Typography>
                        </td>
                    )}
                    {extra && (
                        <td width="1px" style={{whiteSpace: 'nowrap'}} valign="middle">
                            {extra}
                        </td>
                    )}
                </tr>
                </tbody>
            </table>

            {children}

        </CardContent>;
    }

    return (
        <>
            <Card sx={sx} variant="outlined">
                {onSelect ? (
                    <CardActionArea onClick={onSelect}>
                        {content()}
                    </CardActionArea>
                ) : content()}
            </Card>
        </>
    )
}

export default CreditCardItem;
