import * as React from "react";
import {useEffect, useState} from "react";
import {getLinkData} from "@/business/event/service";
import {CodeName} from "@/business/commons/model";
import Card from "@mui/material/Card";
import {CardHeader} from "@mui/material";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

interface Props {
    link: string;
}

export const YourPromoter = ({link}: Props) => {
    const [promoter, setPromoter] = useState<CodeName | undefined>();

    useEffect(() => {
        getLinkData(link).then(value => {
            setPromoter(value?.promoter)
        });
    }, [link]);


    if (!promoter) {
        return null;
    }

    const names = promoter.name.split(" ");
    let name = names[0];
    if (names.length > 1 && names[1].length > 3) {
        name = `${name} ${names[1]}`;
    }

    return <Card sx={{borderRadius: '10px', mb: '20px'}}>
        <CardHeader title={
            <Typography sx={{color: 'white', fontWeight: 'bold'}}>
                SEU PROMOTER
            </Typography>
        } sx={{backgroundColor: "#4C576C", color: 'white', fontWeight: 'bold !important'}}/>
        <CardContent>
            <Typography textAlign="center" textTransform="uppercase">
                <b>{name}</b>
            </Typography>
        </CardContent>
    </Card>
}
