import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {DialogTitle} from '@mui/material';
import * as React from 'react';

export interface Props {
    children: React.ReactNode,
    onClose: VoidFunction
}

const DialogHeader = ({children, onClose}: Props) => {
    return (
        <DialogTitle id="alert-dialog-title">
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Box sx={{flex: 1}}>{children}</Box>
                <IconButton onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            </Box>
        </DialogTitle>
    )
}


export default DialogHeader;
