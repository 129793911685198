import * as React from 'react';
import {OrderBase} from '@/business/order/model';
import {formatDateTime, formatDecimal} from '@/utils/formatters';
import {
    Box,
    List,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme
} from '@mui/material';
import OrderListItem from '@/features/orders/components/OrderListItem';
import IconButton from '@mui/material/IconButton';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {OrderStatus} from '@/business/order/domain';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Button from '@mui/material/Button';
import PagedComponent from '@/features/components/PagedComponent';
import {OrderStatusColors} from '@/business/order/utils';
import Typography from '@mui/material/Typography';
import OrderStatusTag from '@/features/orders/components/OrderStatusTag';

interface Props {
    orders: OrderBase[],
    onSelect: (item: OrderBase) => void,
    onCheckoutClick: (item: number) => void
}

const valueDisplay = {
    xs: 'none',
    md: 'table-cell'
};

const OrdersTable = ({orders, onSelect, onCheckoutClick}: Props) => {

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('sm'));

    const getCell = (order: OrderBase, value: string) => {
        if (order.status !== OrderStatus.OPEN) {
            return value;
        }
        return (<Tooltip
            title="Pedido em Aberto. Podidos não pagos podem ser cancelados depois de um tempo, finalize o pedido para garantir seus ingressos.">
            <Typography variant="body1" sx={{fontWeight: 'bold'}} color={OrderStatusColors[order.status] + ".main"}>
                {value}
            </Typography>
        </Tooltip>)
    }

    if (isXs) {
        return <PagedComponent items={orders} pageSize={5} render={page => (
            <List sx={{width: '100%'}}>
                {page.map(item => <OrderListItem order={item} key={item.id} onDetailsClick={() => onSelect(item)}
                                                 onCheckoutClick={() => onCheckoutClick(item.id)}/>)}
            </List>
        )}/>
    }

    return (
        <PagedComponent items={orders} render={page => (
            <TableContainer component={Paper}>
                <Table sx={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Evento</TableCell>
                            <TableCell align="center">Data do Pedido</TableCell>
                            <TableCell align="right" sx={{display: valueDisplay}}>Valor</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell>Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {page.map((row) =>
                            <TableRow key={row.id}
                                      sx={{':hover': {backgroundColor: '#F5F7F8', cursor: 'pointer'}}}>
                                <TableCell>
                                    {getCell(row, row.event.name)}
                                </TableCell>
                                <TableCell align="center">
                                    {getCell(row, formatDateTime(row.date))}
                                </TableCell>
                                <TableCell align="right" sx={{display: valueDisplay}}>
                                    {getCell(row, formatDecimal(row.totalValue, 'R$ '))}
                                </TableCell>
                                <TableCell align="center" sx={{width: '1px', whiteSpace: 'nowrap'}}>
                                    {row.status === OrderStatus.OPEN ? (
                                        <Box sx={{pt: '5px'}}>
                                            <Button variant="contained" startIcon={<ShoppingCartIcon/>}
                                                    onClick={() => onCheckoutClick(row.id)}>
                                                Finalizar Compra
                                            </Button>
                                        </Box>
                                    ) : <OrderStatusTag status={row.status}/>}
                                </TableCell>
                                <TableCell sx={{width: '1px', whiteSpace: 'nowrap'}}>
                                    <Box>
                                        <IconButton onClick={() => onSelect(row)}>
                                            <FindInPageIcon/>
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        )}/>
    );
}

export default OrdersTable;
