import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {EventListing} from '@/business/event/model';
import {formatDateTime} from '@/utils/formatters';
import {CardActionArea} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import EventCover from '@/features/components/EventCover';


export interface Props {
    event: EventListing
}

const FeaturedItem = ({event}: Props) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/evento/${event.key}`);
    }

    return (
        <Card sx={{width: '100%', marginBottom: '20px', p: 0}} variant="outlined">
            <CardActionArea onClick={handleClick}>
                <Box sx={{position: 'retive'}}>
                    <EventCover src={event.banner_url} radius="10px"/>
                </Box>
                <Box>
                    <Box sx={{textAlign: 'left', p: '10px'}}>
                        <Typography variant="body1" color="info.light">
                            <b>{formatDateTime(event.date)}</b>
                        </Typography>
                        <Typography variant="h2" sx={{minHeight: '60px'}}>
                            {event.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {event.client.name}
                        </Typography>
                    </Box>
                </Box>
            </CardActionArea>
        </Card>
    )
}

export default FeaturedItem
