import {TicketDisplay, TicketUser} from '@/business/tickets/model';
import {ListItem, ListItemAvatar, ListItemText, Tooltip, useMediaQuery, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import {green} from '@mui/material/colors';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import Link from '@mui/material/Link';
import {preventDefault} from '@/utils/functions';
import * as React from 'react';
import {useState} from 'react';
import {deleteTicketUser, exportTicketById} from '@/business/tickets/service';
import {ignoreAxios} from '@/utils/errors';
import {MessageUtils} from '@/utils/message';
import EditUser from '@/features/tickets/components/EditUser';
import {download} from '@/utils/download';

interface Props {
    initialTickets: TicketDisplay[];
    disabled: boolean;
}

const TicketGuests = ({initialTickets, disabled}: Props) => {

    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:475px)');
    const [tickets, setTickets] = useState(initialTickets);
    const [editing, setEditing] = useState<TicketDisplay | undefined>(undefined);

    if (!tickets.length) {
        return <></>
    }

    const setUser = (id: number, user: TicketUser) => {
        tickets.find(a => a.id === id)!.ticketUser = user;
        setTickets([...tickets]);
    }

    const handleDelete = (id: number) => {
        deleteTicketUser(id).then(() => {
            setUser(id, {valid: false});
            MessageUtils.notifySuccess("Usuário removido com sucesso");
        }).catch(ignoreAxios)
    }

    const handleEdited = (id: number, user: TicketUser) => {
        setUser(id, {...user, valid: true});
        MessageUtils.notifySuccess("Usuário atualizado com sucesso");
        setEditing(undefined)
    }

    const openEdit = (ticket: TicketDisplay) => {
        let user = ticket.ticketUser;
        if (!user.valid) {
            user = {valid: false};
        }
        setEditing({...ticket, ticketUser: user});
    }

    const handleDownload = (ticket: TicketDisplay) => {
        exportTicketById([ticket.id]).then(value => {
            download(value, `Ticket ${ticket.code} - ${ticket.ticketUser.name}.pdf`).catch(ignoreAxios);
        }).catch(ignoreAxios);
    }

    return (
        <Box sx={{pl: '5px', pr: '5px'}}>
            <Typography variant="subtitle1">
                <b>Ingressos para convidados</b>
            </Typography>
            <List sx={{width: '100%'}}>
                {tickets.map((guest, index) => {
                    const blockChangeGuestMsg = guest.usedAt ? 'O ingresso já foi validado na portaria, não é possível alterar o usuário' : '';
                    return (
                        <ListItem key={guest.id} sx={{
                            borderBottom: index < tickets.length - 1 ? '1px solid ' + theme.palette.divider : undefined,
                            p: 0
                        }}>
                            {!isMobile && (
                                <ListItemAvatar>
                                    {guest.ticketUser?.valid ? <Avatar sx={{bgcolor: green[500]}}>
                                        <HowToRegIcon/>
                                    </Avatar> : <Avatar>
                                        <Diversity1Icon/>
                                    </Avatar>}
                                </ListItemAvatar>
                            )}
                            <Box sx={{flex: '1'}}>
                                <ListItemText primary={<Box component="span">
                                    <Box component="span" display="block"><b>Código:</b> {guest.code}</Box>
                                    <Box component="span">
                                        <b>Nome:</b> {guest.ticketUser?.valid ? guest.ticketUser?.name : 'LIVRE'}
                                    </Box>
                                </Box>
                                } secondary={<Box component="span">
                                    {guest.ticketUser?.valid && (
                                        <Box component="span" display="block"><b>CPF:</b> {guest.ticketUser?.document}
                                        </Box>
                                    )}
                                    <Box component="span"
                                         sx={{pt: '5px', display: 'flex', flexWrap: 'wrap', gap: '5px', pb: '10px'}}>
                                        <Link onClick={preventDefault(() => handleDownload(guest))} href="#"
                                              sx={{mr: '5px'}}>
                                            Baixar Ingresso
                                        </Link>
                                        {!disabled && (
                                            <Tooltip arrow
                                                     title={blockChangeGuestMsg}>
                                                <Link onClick={preventDefault(() => openEdit(guest))} href="#"
                                                      component="button" sx={{mr: '5px'}}
                                                      disabled={!!blockChangeGuestMsg}>
                                                    {guest.ticketUser?.valid ? 'Alterar Usuário' : 'Definir Usuário'}
                                                </Link>
                                            </Tooltip>
                                        )}
                                        {!disabled && guest.ticketUser?.valid && (
                                            <Tooltip arrow
                                                     title={blockChangeGuestMsg}>
                                                <Link onClick={preventDefault(() => handleDelete(guest.id))} href="#"
                                                      color="error.light" component="button"
                                                      disabled={!!blockChangeGuestMsg}>
                                                    Remover Convidado
                                                </Link>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </Box>}/>
                            </Box>
                        </ListItem>
                    )
                })}
            </List>
            {editing && (
                <EditUser ticket={editing} onCancel={() => setEditing(undefined)}
                          onUpdated={(user) => handleEdited(editing!.id, user)}/>
            )}
        </Box>
    )
}

export default TicketGuests;
