import * as React from 'react';
import {useEffect, useState} from 'react';
import MainLayout from '@/layouts/main';
import PageTitle from '@/features/components/PageTitle';
import {UseTerms} from '@/business/commons/model';
import {getUseTerms} from '@/business/commons/service';
import {ignoreAxios} from '@/utils/errors';
import Accordion from '@mui/material/Accordion';
import {AccordionDetails, AccordionSummary, Container} from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const UseTermsPage = () => {

    const [terms, setTerms] = useState<UseTerms[]>([]);

    useEffect(() => {
        getUseTerms().then(setTerms).catch(ignoreAxios);
    }, [])

    return (
        <MainLayout>
            <>
                <Container>
                    <PageTitle text="Termos de Uso"/>
                    {terms.map(value =>
                        <Accordion key={value.order}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>{`${value.order} - ${value.title}`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {value.text}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Container>
            </>
        </MainLayout>
    )
}

export default UseTermsPage
