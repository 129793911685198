import {maskInt} from '@/utils/masks/number';

export function maskCpf(value?: string): string {
    let cpf = maskInt(value);
    if (cpf) {
        if (cpf.length > 11) {
            cpf = cpf.substring(0, 11)
        }

        if (cpf.length > 9) {
            return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3-$4');
        }
        if (cpf.length > 6) {
            return cpf.replace(/^(\d{3})(\d{3})(\d)/, '$1.$2.$3');
        } else if (cpf.length > 3) {
            return cpf.replace(/^(\d{3})(\d)/, '$1.$2');
        }
    }
    return cpf;
}

export function maskPhone(value?: string | number): string {
    value = maskInt(`${value}`);
    if (value) {
        if (value.length > 11) {
            value = value.substring(0, 11);
        }

        if (value.length === 11) {
            return value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        }

        if (value.length === 10) {
            return value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        }

        if (value.length > 6) {
            return value.replace(/(\d{2})(\d{4})(\d)/, '($1) $2-$3');
        }

        if (value.length > 2) {
            return value.replace(/(\d{2})(\d)/, '($1) $2');
        }
    }
    return value;
}
