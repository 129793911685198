import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {User} from "@/business/security/model";
import {me, setAdmin, setToken} from "@/business/security/service";
import {ignoreAxios} from "@/utils/errors";
import {setSentryUser} from "@/config/sentry";

type UserData = {
    name: string;
    email: string;
    cpf?: string;
}

export interface AccountContextType {
    user: User | null,
    loading: boolean;
    setUser: (user: User | null) => void,
    login: {
        visible: boolean;
        user: UserData | null;
        show: (state?: UserData) => void;
        hide: VoidFunction
    },
    logout: VoidFunction
}

export interface AccountContextProps {
}

export const AccountContext = createContext<AccountContextType | null>(null);

export const AccountProvider = ({children}: PropsWithChildren<AccountContextProps>) => {

    const [user, setUser] = useState<User | null>(null);
    const [loginState, setLoginState] = useState<UserData | null>(null);
    const [loginVisible, setLoginVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        me()
            .then(value => {
                setUser(value ?? null);
            })
            .catch(ignoreAxios)
            .finally(() => setLoading(false));
    }, []);

    const showLogin = useCallback((state?: UserData) => {
        setLoginState(state ?? null);
        setLoginVisible(true);
    }, []);

    const hideLogin = useCallback(() => {
        setLoginState(null);
        setLoginVisible(false);
    }, []);

    const logout = useCallback(() => {
        setUser(null)
        setSentryUser(undefined);
        setToken();
        setAdmin();
    }, []);

    const state: AccountContextType = {
        user,
        login: {
            visible: loginVisible,
            user: loginState,
            show: showLogin,
            hide: hideLogin
        },
        logout,
        setUser,
        loading
    }

    return (
        <AccountContext.Provider value={state}>{children}</AccountContext.Provider>
    );
};

export const useAccountContext = () => {
    const context = useContext(AccountContext);
    if (!context) {
        throw new Error(
            "useAccountContext must be used within an AccountProvider"
        );
    }
    return context;
};
