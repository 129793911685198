import * as React from 'react';
import Box from '@mui/material/Box';
import {Container, Divider, useTheme} from '@mui/material';
import logo from "@/assets/img/logo-full.png";
import Link from '@mui/material/Link';
import {variables} from '@/config';
import {useWhiteLabelClientContext} from "@/layouts/contexts/white-label-client/WhiteLabelClientContext";
import SocialButton, {Network} from "@/features/components/SocialButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

const Logo = () => {
    const {client} = useWhiteLabelClientContext();
    const navigate = useNavigate();

    if (!client) {
        return <img src={logo} style={{maxHeight: '45px'}} alt="myPromoter - A sua plataforma de eventos"/>
    }
    return <div>
        <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            <img src={client.logo} style={{maxHeight: '65px'}} className="sm" alt={client.name}/>
            <Box display="flex" justifyContent="space-between" gap="10px" flexDirection="column">
                <div style={{fontWeight: 'bold', fontSize: '1.2rem'}}>{client.name}</div>
                <Button variant="outlined" startIcon={<CalendarMonthIcon/>}
                        onClick={() => navigate(`/organizador/${client.id}`)}>
                    Mais Eventos
                </Button>
            </Box>
        </div>
    </div>
}

const Social = () => {
    const {client} = useWhiteLabelClientContext();

    const instagram = client ? client.instagram : 'https://www.instagram.com/mypromoterbnu/';
    const linkedin = client ? null : 'https://www.linkedin.com/company/mypromoter/about/';
    const tiktok = client ? client.tiktok : null;
    const facebook = client ? client.facebook : null;
    const website = client ? client.website : null;

    return <table>
        <tbody>
        <tr>
            {instagram && (
                <td>
                    <SocialButton network={Network.INSTAGRAM} link={instagram}/>
                </td>
            )}
            {linkedin && (
                <td>
                    <SocialButton network={Network.LINKED_IN} link={linkedin}/>
                </td>
            )}
            {tiktok && (
                <td>
                    <SocialButton network={Network.TIKTOK} link={tiktok}/>
                </td>
            )}
            {facebook && (
                <td>
                    <SocialButton network={Network.FACEBOOK} link={facebook}/>
                </td>
            )}
            {website && (
                <td>
                    <SocialButton network={Network.WEBSITE} link={website}/>
                </td>
            )}
        </tr>
        </tbody>
    </table>
}

const Footer = () => {
    const theme = useTheme();
    return (
        <footer>
            <Box sx={{backgroundColor: "#F5F7F8", paddingY: '20px'}}>
                <Container>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                        <Logo/>
                        {/*<Box style={{display: 'flex', alignItems: 'self-end'}}>
                            <Typography variant="body1" style={{color: theme.palette.text.primary}}>
                                Compre pelo APP
                            </Typography>
                            <Box sx={{height: '30px', width: 'auto', marginX: '10px'}}>
                                <Link href="https://apps.apple.com/br/app/mypromoter/id1576110172" target="_blank"
                                      rel="noreferrer">
                                    <img src={app_store_badge} style={{maxHeight: '30px'}} alt="Baixar para IOS"/>
                                </Link>
                            </Box>
                            <Box sx={{height: '30px', width: '110px'}}>
                                <Link href="https://play.google.com/store/apps/details?id=br.com.mypromoter.mypromoter"
                                      target="_blank" rel="noreferrer">
                                    <img src={play_store_badge} style={{maxHeight: '30px'}} alt="Baixar para Android"/>
                                </Link>
                            </Box>
                        </Box>*/}
                    </Box>
                    <Divider sx={{marginY: '20px'}}></Divider>
                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <Link href="/" style={{color: theme.palette.text.secondary}}>Home</Link>
                                </td>
                                <td style={{paddingLeft: '15px'}}>
                                    <Link href="/termos" style={{color: theme.palette.text.secondary}}>
                                        Termos e Políticas
                                    </Link>
                                </td>
                                <td style={{paddingLeft: '15px'}}>
                                    <Link href={variables.contactUrl} style={{color: theme.palette.text.secondary}}
                                          target="_blank" rel="noreferrer">Tenho um Problema
                                    </Link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <Social/>
                    </Box>
                </Container>
            </Box>
        </footer>
    )
}

export default Footer;
