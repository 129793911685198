import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useForm} from 'react-hook-form';
import ControlledTextField from '@/features/components/ControlledTextField';
import message from '@/utils/errors';
import Patterns from '@/utils/patterns';
import {maskCpf} from '@/utils/masks/user-data';
import LoginWithGoogle from '@/features/login/components/LoginWithGoogle';
import {User} from '@/business/security/model';
import {useAccountContext} from "@/utils/context/AccountContext";

export interface BuyerData {
    name: string;
    email: string;
    document: string;
}

interface Props {
    initial?: BuyerData;
    onReady: (data: BuyerData) => void;
}

const BuyerDataForm = ({onReady, initial}: Props) => {
    const {user} = useAccountContext();
    const {
        setValue,
        control,
        handleSubmit,
        formState: {errors, isSubmitted}
    } = useForm({
        defaultValues: {
            name: initial?.name ?? user?.name ?? '',
            email: initial?.email ?? user?.email ?? '',
            document: initial?.document ?? user?.cpf ?? '',
        }
    });

    const afterLogin = (user: User) => {
        setValue('name', user.name);
        setValue('email', user.email);
        setValue('document', user.cpf ?? '');
    }

    return (
        <Box>

            {!user && (
                <>
                    <Box mt={2}>
                        <LoginWithGoogle onLogged={afterLogin}/>
                    </Box>

                    <Box textAlign="center" fontWeight="bold">
                        OU
                    </Box>
                </>
            )}

            <Box component="form" onSubmit={handleSubmit(onReady)} noValidate>
                <ControlledTextField
                    control={control}
                    name="name"
                    error={isSubmitted && !!errors.name}
                    helperText={message(errors.name)}
                    margin="normal"
                    required
                    fullWidth
                    label="Nome Completo"
                    inputProps={{maxLength: 250}}
                    rules={{required: true}}
                />
                <ControlledTextField
                    control={control}
                    name="email"
                    error={isSubmitted && !!errors.email}
                    helperText={message(errors.email, {patternMsg: 'O email informado é inválido'})}
                    margin="normal"
                    required
                    fullWidth
                    label="Email"
                    inputProps={{maxLength: 250}}
                    rules={{required: true, pattern: Patterns.email}}
                />
                <ControlledTextField
                    control={control}
                    name="document"
                    error={isSubmitted && !!errors.document}
                    helperText={message(errors.document, {patternMsg: 'O CPF informado é inválido'})}
                    margin="normal"
                    required
                    fullWidth
                    label="CPF"
                    formatter={maskCpf}
                    inputProps={{maxLength: 14}}
                    rules={{pattern: Patterns.cpf, required: true}}
                />

                <Box sx={{paddingTop: '15px'}}>
                    <Button variant="contained" onClick={handleSubmit(onReady)} sx={{width: '100%'}}>ESCOLHER
                        INGRESSOS</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default BuyerDataForm
