import {Ticket2, TicketUser} from '@/business/tickets/model';
import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {StepLabel} from '@mui/material';
import StepContent from '@mui/material/StepContent';
import TicketUserForm from '@/features/checkout/components/TicketUserForm';
import {ItemType} from '@/business/order/domain';
import TicketGuests, {TicketGuestsLabels} from '@/features/checkout/components/TicketGuests';
import {CheckoutTicket} from '@/features/checkout/models/checkout';
import TicketGuestsCreate from '@/features/checkout/components/TicketGuestsCreate';

interface Props {
    item: CheckoutTicket,
    index: number,
    onComplete: (user: TicketUser) => void,
    active: boolean,
    onBack?: VoidFunction
}


const OrderItemStep = ({item, index, onComplete, active, onBack}: Props) => {

    const [guests, setGuests] = useState(item.ticket.guests ?? []);
    const [guestModalItem, setGuestModalItem] = useState<Ticket2 | undefined>();

    let autoFillLabel = 'Este ingresso é pra você?';
    if (item.item.itemType === ItemType.TABLE) {
        autoFillLabel = 'Você é o responsável pela mesa?';
    }

    const updateGuestList = (guest: Ticket2) => {
        const updatedList = [...guests.filter(value => value.id !== guest.id), guest];
        setGuests(updatedList);
    }

    const handleGuestCreated = (guest: Ticket2) => {
        updateGuestList(guest);
        setGuestModalItem(undefined);
    }

    return <>
        <StepLabel>
            <Box>
                <Typography variant={active ? 'h5' : undefined} component="span">
                    DADOS DO INGRESSO {index + 1}
                </Typography>
                <Typography variant='body1' sx={{textTransform: 'uppercase'}}>
                    <i>{item.item.label}</i>
                </Typography>
            </Box>

            {!active && item.ticket.ticketUser?.name && (
                <Typography variant="body2">
                    {item.ticket.ticketUser?.name}
                </Typography>
            )}
        </StepLabel>
        <StepContent>
            <Box sx={{paddingTop: '10px'}}>
                <TicketUserForm user={item.ticket.ticketUser}
                                id={item.ticket.id}
                                onBack={onBack}
                                onComplete={onComplete}
                                autoFillLabel={autoFillLabel}
                >
                    <>
                        {!!guests.length && (
                            <Box mt="10px">
                                <Typography variant="subtitle1">
                                    <b>Convidados</b>
                                </Typography>
                                <fieldset>
                                    <TicketGuestsLabels numberOfGuests={guests.length}></TicketGuestsLabels>
                                    <TicketGuests guests={guests} onAdd={setGuestModalItem} onDelete={updateGuestList}/>
                                </fieldset>
                            </Box>
                        )}
                    </>
                </TicketUserForm>
            </Box>

            {guestModalItem && (
                <TicketGuestsCreate ticket={guestModalItem} onCreated={handleGuestCreated}
                                    onCancel={() => setGuestModalItem(undefined)}/>
            )}
        </StepContent>
    </>
}

export default OrderItemStep


