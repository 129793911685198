import * as React from 'react';
import {usePromiseTracker} from 'react-promise-tracker';
import Box from '@mui/material/Box';
import {CircularProgress} from '@mui/material';

const LoadingIndicator = () => {
    const {promiseInProgress} = usePromiseTracker();
    if (!promiseInProgress) {
        return <></>
    }
    return (
        <Box className="http-loader">
            <CircularProgress size={70}/>
        </Box>
    );
}

export default LoadingIndicator
