import React from 'react';
import {VideoUrl} from '@/business/commons/model';

interface Props {
    url?: VideoUrl;
}

const VideoPlayer = ({url}: Props) => {
    if (!url) {
        return (<></>);
    }
    return (
        <div className="video-container" style={{position: 'relative', paddingBottom: '56.25%', height: 0}}>
            <iframe width="560" height="315" src={url.url} frameBorder="0"
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%'
                    }}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    title="Embedded youtube"
                    allowFullScreen>
            </iframe>
        </div>
    );
}

export default VideoPlayer;
