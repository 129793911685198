import Swal from 'sweetalert2';
import {toast} from 'react-toastify';

export interface MessageOpts {
    title?: string,
    okText?: string,
    cancelText?: string,
    showCancelText?: boolean,
}

export interface AlertResult {
    isConfirmed: boolean
}

export class MessageUtils {
    static showError(message: string, title: string | undefined = undefined): Promise<void> {
        return Swal.fire({
            title: title,
            text: message,
            icon: 'error',
            confirmButtonText: 'OK',
            showCancelButton: false,
        }).then(() => {
        })
    }

    static showInfo(message: string, opts?: MessageOpts): Promise<AlertResult> {
        return Swal.fire({
            title: opts?.title,
            text: message,
            icon: 'info',
            confirmButtonText: opts?.okText ?? 'OK',
            cancelButtonText: opts?.cancelText ?? 'Cancelar',
            showCancelButton: opts?.showCancelText === undefined ? !!opts?.cancelText : opts?.showCancelText,
        }).then((value) => {
            return {
                isConfirmed: value.isConfirmed
            };
        })
    }

    static notifySuccess(message: string): void {
        toast.success(message, {pauseOnFocusLoss: false, autoClose: 1500});
    }

    static notifyError(message: string): void {
        toast.error(message, {autoClose: 3000});
    }

    static notifyWarn(message: string): void {
        toast.warn(message, {autoClose: 3000});
    }
}
