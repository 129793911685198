import * as React from 'react';
import {useState} from 'react';
import {Dialog, DialogContent, DialogContentText} from '@mui/material';
import LoginForm from '@/features/login/components/LoginForm';
import logo from '@/assets/img/logo-full-alt.png';
import Box from '@mui/material/Box';
import CreateAccountForm from '@/features/login/components/CreateAccountForm';
import PasswordRecoveryFlow from '@/features/login/components/PasswordRecoveryFlow';
import {useWhiteLabelClientContext} from "@/layouts/contexts/white-label-client/WhiteLabelClientContext";
import Typography from "@mui/material/Typography";
import {useAccountContext} from "@/utils/context/AccountContext";

enum Flow {
    LOGIN,
    SIGN_UP,
    RECOVERY,
}

const FlowLabels: { [key in Flow]: any } = {
    [Flow.LOGIN]: 'Informe os dados de acesso da sua conta para entrar.',
    [Flow.SIGN_UP]: 'Informe seus dados e para criarmos sua conta.',
    [Flow.RECOVERY]: 'Esqueceu sua senha? não tem problemas, informe as informações abaixo para gerarmos uma nova.',
};

const Logo = () => {
    const {client} = useWhiteLabelClientContext();
    if (!client) {
        return <img src={logo} style={{maxHeight: '70px'}} alt="myPromoter Logo"/>;
    }

    return <Box textAlign="center" mb="25px">
        <img src={client.logo} style={{maxHeight: '70px'}} alt=""/>
        <Typography variant="h5">
            {client.name}
        </Typography>
    </Box>
}

const LoginModal = () => {

    const {login} = useAccountContext();
    const [flow, setFlow] = useState(Flow.LOGIN);

    const close = () => {
        setFlow(Flow.LOGIN);
        login.hide();
    }

    if (!login.visible) {
        return null
    }

    return (
        <Dialog onClose={close} open={true} fullWidth={true} maxWidth="xs">
            <DialogContent>
                <Box textAlign={'center'}>
                    <Logo/>
                </Box>
                <DialogContentText sx={{textAlign: 'center'}}>
                    {FlowLabels[flow]}
                </DialogContentText>
                {flow === Flow.LOGIN &&
                    <LoginForm onLogin={close} onCreateAccount={() => setFlow(Flow.SIGN_UP)}
                               onForgetPassword={() => setFlow(Flow.RECOVERY)} email={login.user?.email}/>}
                {flow === Flow.SIGN_UP &&
                    <CreateAccountForm onLogin={() => setFlow(Flow.LOGIN)} onCreated={close}/>}
                {flow === Flow.RECOVERY &&
                    <PasswordRecoveryFlow onCancel={() => setFlow(Flow.LOGIN)} onComplete={() => setFlow(Flow.LOGIN)}
                                          onCreateAccount={() => setFlow(Flow.SIGN_UP)} email={login.user?.email}/>}
            </DialogContent>
        </Dialog>
    )
}

export default LoginModal
