import {maskMonth} from '@/utils/masks/date';
import {maskInt} from '@/utils/masks/number';

export function maskValidTrough(value?: string): string {
    value = maskInt(value);
    if (!value) {
        return ''
    }
    let month = `${maskMonth(value)}`;
    if (month) {
        const year = value.substring(2);
        if (year) {
            month += `/${year}`
        }
    }
    return month;
}

export function maskCardNumber(value?: string | number): string {
    value = maskInt(`${value}`);
    if (value) {
        const groupSize = 4;
        const groups = [];
        for (let i = 0; i < value.length; i += groupSize) {
            groups.push(value.substring(i, i + groupSize));
        }
        value = groups.join(' ');
    }
    return value;
}
