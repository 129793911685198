import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {EventListing} from '@/business/event/model';
import {formatDateTime} from '@/utils/formatters';
import {useNavigate} from 'react-router-dom';
import {CardActionArea} from '@mui/material';
import EventCover from '@/features/components/EventCover';

export interface Props {
    event: EventListing
}

const EventItem = ({event}: Props) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/evento/${event.key}`);
    }

    return (
        <Card sx={{width: '100%'}}>
            <CardActionArea onClick={handleClick}>
                <EventCover src={event.banner_small ?? event.banner_url}/>
                <CardContent sx={{textAlign: 'left'}}>
                    <Typography variant="body1" color="info.light">
                        <b>{formatDateTime(event.date)}</b>
                    </Typography>
                    <Typography component="div" variant="h3" sx={{minHeight: '65px'}}>
                        <b>{event.name}</b>
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {event.client.name}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default EventItem
