import * as React from 'react';
import {useState} from 'react';
import {Event} from '@/business/event/model';
import Card from '@mui/material/Card';
import {CardHeader} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import VipListRules from '@/features/event/components/commons/VipListRules';
import {preventDefault} from '@/utils/functions';
import {VipListStatus} from "@/features/event/components/details/EventVipList/VipListStatus";

export interface Props {
    event: Event,
    link?: string | null
}

const EventVipList = ({event, link}: Props) => {

    const [rules, setRules] = useState(false);

    const onChange = (reserved: boolean) => {
        event.userStatus.vipListReserved = reserved;
    }

    if (!event.services.vipList || !event.vipListConfiguration) {
        return null
    }

    return (
        <Card sx={{borderRadius: '10px'}}>
            <CardHeader title={
                <Typography sx={{color: 'white', fontWeight: 'bold'}}>
                    LISTA VIP
                </Typography>
            } sx={{backgroundColor: "#4C576C", color: 'white', fontWeight: 'bold !important'}}/>
            <CardContent>
                <VipListStatus event={event} onChange={onChange}
                               link={link}/>
                <Typography variant="body2" textAlign="center" marginTop="10px">
                    Quer saber mais sobre a lista VIP?
                    <Link href="#" onClick={preventDefault(() => setRules(true))}> Clique aqui!</Link>
                </Typography>
            </CardContent>
            <VipListRules open={rules} onClose={() => setRules(false)}/>
        </Card>
    )
}

export default EventVipList
