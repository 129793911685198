import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {TicketUser} from '@/business/tickets/model';
import {deleteTicketUser} from '@/business/tickets/service';
import {ignoreAxios} from '@/utils/errors';
import List from '@mui/material/List';
import {ListItem, ListItemAvatar, ListItemText, Tooltip, useMediaQuery, useTheme} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {preventDefault} from '@/utils/functions';
import {MessageUtils} from '@/utils/message';

export interface TicketBase {
    id: number;
    ticketUser?: TicketUser;
}

interface Props<T extends TicketBase> {
    guests: T[];
    disabled?: boolean;
    onAdd: (guest: T) => void;
    onDelete: (guest: T) => void;
}

export const TicketGuestsLabels = ({numberOfGuests}: { numberOfGuests?: number }) => {

    const label = numberOfGuests ? (<>Seu ingresso permite a inclusão
        de <b>{numberOfGuests} convidados</b></>) : (<>Alguns dos seus ingressos permitem a inclusão de convidados</>);

    return <>
        <Typography variant="body1">
            {label}. Cadastrar seus convidados simplifica a
            entrada deles no evento, permitindo que o organizador do evento os identifique e libere a
            entrada sem necessidade de confirmação do responsável pela mesa.
        </Typography>
        <Typography variant="body2" sx={{mt: '10px'}}>
            Ainda não decidiu quem chamar? Não se preocupe, você pode cadastrar seus convidados até 1 hora antes do
            evento.
        </Typography>
    </>
}

export default function TicketGuests<T extends TicketBase>({guests, disabled, onAdd, onDelete}: Props<T>) {
    const theme = useTheme();
    const isMobile = useMediaQuery('(max-width:475px)');

    const openModal = () => {
        const freeGuest = guests.find((a: TicketBase) => !a.ticketUser);
        if (!freeGuest) {
            MessageUtils.notifySuccess("Quantidade de convidados máximos já incluídos");
            return;
        }
        onAdd(freeGuest);
    }

    const handleDelete = (guest: T) => {
        deleteTicketUser(guest.id).then(() => {
            guest.ticketUser = undefined;
            onDelete(guest);
        }).catch(ignoreAxios)
    }

    const filled: T[] = guests.filter(a => a.ticketUser);
    const hasMore = filled.length < guests.length;

    return <>
        <List sx={{width: '100%'}}>
            {filled.map((guest, index) => (
                <ListItem key={guest.id} secondaryAction={
                    (isMobile || disabled) ? undefined :
                        <IconButton aria-label="Excluir Convidado" onClick={() => handleDelete(guest)}>
                            <DeleteIcon/>
                        </IconButton>
                } sx={{
                    borderBottom: index < filled.length - 1 ? '1px solid ' + theme.palette.divider : undefined,
                    p: 0
                }}>
                    {!isMobile && (
                        <ListItemAvatar>
                            <Avatar>
                                <Diversity1Icon/>
                            </Avatar>
                        </ListItemAvatar>
                    )}
                    <Box sx={{flex: '1'}}>
                        <ListItemText primary={guest.ticketUser?.name} secondary={guest.ticketUser?.document}/>
                        {isMobile && !disabled && (
                            <Box sx={{textAlign: 'center', width: '100%', pb: '10px'}}>
                                <Link href="#" onClick={preventDefault(() => handleDelete(guest))}>Excluir
                                    Convidado</Link>
                            </Box>
                        )}
                    </Box>
                </ListItem>
            ))}
        </List>
    {!filled.length && (
        <Box textAlign="center" sx={{mb: '10px'}}>
            <Typography variant="body2">
                Nenhum convidado adicionado
            </Typography>
        </Box>
    )}
    {!disabled && (
        <Box textAlign="center">
            {hasMore ? (
                <Button variant="outlined" onClick={openModal}>Adicionar
                    Convidado ({filled.length + 1} / {guests.length})</Button>
            ) : (
                <Tooltip title="Número máximo de convidados atingido">
                    <Button variant="outlined" disabled={true}>Adicionar Convidado</Button>
                </Tooltip>
            )}
        </Box>
    )}
    </>
}
