import * as React from 'react';
import {useState} from 'react';
import {Event} from '@/business/event/model';
import Card from '@mui/material/Card';
import {CardHeader} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EventTablesModal from '@/features/event/components/details/EventTablesModal';
import TablesRules from '@/features/event/components/commons/TablesRules';
import {preventDefault} from '@/utils/functions';
import {useAccountContext} from "@/utils/context/AccountContext";

export interface Props {
    event: Event,
    link: string | null
}

const EventTables = ({event, link}: Props) => {

    const [modal, setModal] = useState(false);
    const [rules, setRules] = useState(false);
    const {login, user} = useAccountContext();

    const handleOpenReserve = () => {
        if (!user) {
            login.show();
        }
        setModal(true);
    }

    if (!event.services.tables || !event.tablesConfiguration) {
        return <></>
    }

    const expired = event.tablesConfiguration.expired || event.closed;

    return (
        <Card sx={{borderRadius: '10px'}}>
            <CardHeader title={
                <Typography sx={{color: 'white', fontWeight: 'bold'}}>
                    RESERVA DE MESAS
                </Typography>
            } sx={{backgroundColor: "#4C576C", color: 'white', fontWeight: 'bold !important'}}/>
            <CardContent>
                <Typography variant="body1">
                    A Reserva de Mesa é a melhor forma de você curtir o evento do jeito que você quer, a vontade e com o
                    conforto de ter uma mesa só para você e seus amigos. Vai ficar de fora?
                </Typography>
                {expired && (
                    <Box sx={{marginTop: '15px'}} textAlign="center">
                        <Typography variant="body1" color="error.light" fontSize="0.8rem">
                            <b>O período para reserva de mesas esgotou, mas não desanime, fique de olho nos próximos
                                eventos para aproveitar esta super vantagem.</b>
                        </Typography>
                    </Box>
                )}

                {!expired && (
                    <>
                        <Button variant="contained" onClick={handleOpenReserve} sx={{width: '100%', marginTop: '10px'}}>
                            Reservar Mesa
                        </Button>
                        {modal && user &&
                            <EventTablesModal event={event} onClose={() => setModal(false)} link={link}/>}
                    </>
                )}

                <Typography variant="body2" textAlign="center" marginTop="15px">
                    Quer saber mais sobre a reserva de Mesas?
                    <Link href="#" onClick={preventDefault(() => setRules(true))}> Clique aqui</Link>
                </Typography>
            </CardContent>
            <TablesRules open={rules} onClose={() => setRules(false)}/>
        </Card>
    )
}

export default EventTables
