import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/styles/ckeditor.css';
import reportWebVitals from './reportWebVitals';
import {RouterProvider} from 'react-router-dom';
import routes from './routes';
import config from './config';
import {ToastContainer} from 'react-toastify';
import {Provider} from 'react-redux';
import {store} from '@/config/redux';
import 'react-toastify/dist/ReactToastify.css';
import LoadingIndicator from '@/layouts/loadingIndicator';
import Box from '@mui/material/Box';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {WhiteLabelClientProvider} from "@/layouts/contexts/white-label-client/WhiteLabelClientContext";
import {AccountProvider} from "@/utils/context/AccountContext";

config();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <Provider store={store}>
        <AccountProvider>
            <GoogleOAuthProvider clientId="887039463684-kj2if9pmes1oea3h5s1tm7pen3q43nfi.apps.googleusercontent.com">
                <ToastContainer theme="colored"/>
                <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                    <WhiteLabelClientProvider>
                        <RouterProvider router={routes}/>
                    </WhiteLabelClientProvider>
                </Box>
                <LoadingIndicator/>
            </GoogleOAuthProvider>
        </AccountProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
