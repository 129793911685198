import {Event} from '@/business/event/model';

export function setTrackedEvent(event: Event) {
    const w: any = window;
    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push({
        'eventName': event.name,
        'eventId': event.id
    });
}
