import * as React from 'react';
import Card from '@mui/material/Card';
import {CardHeader, Divider, List, ListItem, ListItemText} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import {Order} from '@/business/order/model';
import Typography from '@mui/material/Typography';
import TicketBlockDisplay from '@/features/event/components/commons/TicketBlockDisplay';
import {ProcessingFeeInfo} from "@/features/checkout/components/utils/ProcessingFeeInfo";
import {OrderTotalValue} from "@/features/checkout/components/utils/OrderTotalValue";

export interface Props {
    order: Order
}

const OrderSummary = ({order}: Props) => {
    return (
        <>
            <Card sx={{borderRadius: '10px'}}>
                <CardHeader
                    title={<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Typography sx={{color: 'white', fontWeight: 'bold !important'}}>
                            INGRESSOS
                        </Typography>
                        <OrderTotalValue items={order.items}/>
                    </Box>}
                    sx={{backgroundColor: "#4C576C"}}/>
                <CardContent sx={{paddingTop: 0, paddingBottom: '0!important'}}>
                    <List sx={{width: '100%', m: 0, p: 0}}>
                        {order.items.map((a, index) =>
                            <Box key={a.id}>
                                <ListItem sx={{paddingX: 0}}>
                                    <ListItemText
                                        primary={
                                            <TicketBlockDisplay disabled={false}
                                                                label={a.label}
                                                                value={a.unitValue}
                                                                discountPercent={order.coupon?.discount}
                                                                discount={a.discount}
                                                                actionAlign="middle"
                                                                action={<Typography variant="h4">
                                                                    <b>
                                                                        {a.quantity}
                                                                    </b>
                                                                </Typography>}/>
                                        }
                                    />

                                </ListItem>

                                {index < order.items.length - 1 && (
                                    <Divider></Divider>
                                )}
                            </Box>
                        )}

                        <ProcessingFeeInfo/>
                    </List>
                </CardContent>
            </Card>
        </>
    )
}

export default OrderSummary
