export enum EventStatus {
    CONFIRMED = 'CONFIRMADO',
    CANCELLED = 'CANCELADO',
    INACTIVE = 'INACTIVE'
}

export enum TicketType {
    MASCULINO = 'MASCULINO',
    FEMININO = 'FEMININO',
    ESTUDANTE = 'ESTUDANTE'
}

export enum TicketBlockType {
    SEQUENCIAL = "SEQUENCIAL",
    PARALELO = "PARALELO",
    SEQUENCIAL_GENERO = "SEQUENCIAL_GENERO"
}

export enum TicketBlockStatus {
    DISPONIVEL = 'DISPONIVEL',
    ESGOTADO = 'ESGOTADO',
    PREVIEW = 'PREVIEW'
}

export enum CHECKOUT_TYPE {
    FAST_CHECKOUT = 'FAST_CHECKOUT',
    DEFAULT = 'DEFAULT'
}
