import * as React from 'react';
import {useState} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {formatEventAddress} from '@/utils/formatters';
import {Client} from '@/business/client/model';
import image from '@/assets/img/party.jpg';
import {Container, Grid, SwipeableDrawer} from '@mui/material';
import {styled} from '@mui/system';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import SocialButton, {Network} from '@/features/components/SocialButton';

export interface Props {
    client: Client;
}

const ClientInfo = ({client}: Props) => {

    const [open, setOpen] = useState(false);

    let line1 = formatEventAddress(client.address)
    return (
        <Box>
            <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                <img src={client.cover ?? image} alt=""
                     style={{
                         aspectRatio: 3.5,
                         objectFit: 'contain',
                         width: '100%',
                         borderRadius: "20px",
                         backgroundColor: 'black'
                     }}/>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <ClientAvatar src={client.logo}></ClientAvatar>
                </Grid>
                <ClientDataGrid item xs={12} sm={9}>
                    <Typography variant="h1" sx={{pt: '10px'}}>
                        {client.name}
                    </Typography>
                    <Typography variant="body2" sx={{marginTop: '10px'}}>
                        {line1}
                    </Typography>
                    <Button
                        href={`https://www.google.com/maps/search/?api=1&query=${client.address.lat},${client.address.lng}`}
                        target="_blank" rel="noreferrer"
                        size="small">Mostrar no mapa
                    </Button>

                    <Box>
                        {client.instagram && (
                            <SocialButton network={Network.INSTAGRAM} link={client.instagram} margin="0 5px 0 0"/>
                        )}
                        {client.tiktok && (
                            <SocialButton network={Network.TIKTOK} link={client.tiktok} margin="0 5px 0 0"/>
                        )}
                        {client.facebook && (
                            <SocialButton network={Network.FACEBOOK} link={client.facebook} margin="0 5px 0 0"/>
                        )}
                        {client.website && (
                            <SocialButton network={Network.WEBSITE} link={client.website}/>
                        )}
                    </Box>

                    {client.about && (
                        <>
                            <Button variant="outlined" onClick={() => setOpen(true)}>Sobre o Organizador</Button>

                            <SwipeableDrawer
                                anchor='bottom'
                                open={open}
                                onOpen={() => console.log('OPEN')}
                                onClose={() => setOpen(false)}
                            >
                                <Container>
                                    <div style={{padding: '30px 10px'}}
                                         dangerouslySetInnerHTML={{__html: client.about}}></div>
                                </Container>
                            </SwipeableDrawer>
                        </>
                    )}
                </ClientDataGrid>
            </Grid>
        </Box>
    )
}

export default ClientInfo;

const ClientAvatar = styled(Avatar)(({theme}) => ({
    width: 136,
    height: 136,
    margin: "auto",
    marginTop: "-50px",
    border: "3px white solid",

    [theme.breakpoints.down('sm')]: {
        marginTop: "0 !important",
    },
}));

const ClientDataGrid = styled(Grid)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
    },
}));
