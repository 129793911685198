import {useCallback} from "react";
import {useSelectedPaymentMethodContext} from "@/features/checkout/contexts/selected-payment-method";

export const useTaxRate = () => {

    const {selectedPayment, showTaxes} = useSelectedPaymentMethodContext();

    const getTaxValue = useCallback((value: number) => {
        if (!selectedPayment || !showTaxes) {
            return 0;
        }
        const {taxRate, minimumValue} = selectedPayment;
        const taxValue = value * taxRate;
        if (taxValue < minimumValue) {
            return minimumValue;
        }
        return taxValue;
    }, [selectedPayment, showTaxes]);

    const applyTaxRate = useCallback((value: number) => {
        const tax = getTaxValue(value);
        return value + tax;
    }, [getTaxValue]);

    return {getTaxValue, applyTaxRate, processingFee: selectedPayment?.processingFee}
}