import TextField from '@mui/material/TextField';
import {TextFieldProps} from '@mui/material/TextField/TextField';
import React from 'react';
import {Control, Controller} from 'react-hook-form';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';

export type ControlledTextFieldProps = {
    name: string,
    control: Control<any>,
    formatter?: (raw: string) => string,
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
} & Omit<TextFieldProps, 'name'>

const ControlledTextField = (props: ControlledTextFieldProps) => {
    const {name, control, formatter, rules, ...textFieldProps} = props;
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({field: {onChange, name, value}}) => (
                <TextField
                    name={name}
                    value={value ?? ''}
                    onChange={(e) => formatter ? onChange(formatter(e.target.value)) : onChange(e.target.value)}
                    {...textFieldProps}
                />
            )}
        />
    )
}

export default ControlledTextField;
