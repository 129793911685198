import {VipListName} from '@/business/tickets/model';
import EventCover from '@/features/components/EventCover';
import CardContent from '@mui/material/CardContent';
import {Alert, CardActionArea} from '@mui/material';
import Typography from '@mui/material/Typography';
import {formatDateTime, formatDecimal} from '@/utils/formatters';
import Button from '@mui/material/Button';
import CelebrationIcon from '@mui/icons-material/Celebration';
import Card from '@mui/material/Card';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    item: VipListName;
    onRemoveRequested: (item: VipListName) => void,
}

const VipListItem = ({item, onRemoveRequested}: Props) => {

    const titleSx = {
        fontWeight: 'bold',
        mt: '10px',
    };

    return (
        <Card sx={{width: '100%'}}>
            <EventCover src={item.event.coverSmall ?? item.event.cover}/>
            <CardContent sx={{textAlign: 'left'}}>
                <CardActionArea href={`/evento/${item.event.key}`}>
                    <Typography variant="subtitle1" color="info.light" component="div">
                        <b>
                            {formatDateTime(item.event.date)}
                        </b>
                    </Typography>
                    <Typography component="div" variant="h6">
                        <b>
                            {item.event.name}
                        </b>
                    </Typography>
                </CardActionArea>

                <Typography variant="subtitle2" sx={titleSx}>
                    Valor Promocional do Ingresso:
                </Typography>
                <Typography variant="h6">
                    R$ {formatDecimal(item.value)}
                </Typography>
                <Typography variant="subtitle2" sx={titleSx}>
                    Horário Limite de Chegada no Evento:
                </Typography>
                <Typography variant="h6">
                    {formatDateTime(item.useDateLimit)}
                </Typography>

                <Typography variant="subtitle2" sx={titleSx}>
                    Código da Lista:
                </Typography>
                <Typography variant="h6">
                    {item.code}
                </Typography>

                <Alert icon={false} severity="info" sx={{mt: '10px'}}>Para converter a lista em ingresso, você deve
                    apresentar o código
                    abaixo na bilheteria do evento e pagar o valor promocional. Atenção ao horário limite de
                    chegada.</Alert>

                <Button variant="text" color="error" startIcon={<CloseIcon/>} sx={{width: '100%', mt: '20px'}}
                        onClick={() => onRemoveRequested(item)}>
                    Remover Nome da Lista
                </Button>

                <Button variant="text" startIcon={<CelebrationIcon/>} sx={{width: '100%', mt: '10px'}}
                        href={`/evento/${item.event.key}`}>
                    Ver Evento
                </Button>

            </CardContent>
        </Card>
    )
}

export default VipListItem;
