import axios from 'axios';
import {CouponResponse, Event, EventListing} from '@/business/event/model';
import {trackPromise} from 'react-promise-tracker';
import {CHECKOUT_TYPE} from '@/business/event/domain';
import {getWithExpiry, setWithExpiry} from '@/utils/storage';
import {CodeName} from "@/business/commons/model";

const PAGE_SIZE = 15;

export interface EventFilters {
    client?: number;
    past?: boolean;
}

function buildParams(featured: boolean | undefined, page = 0, filters?: EventFilters): any {
    return {
        'page-size': PAGE_SIZE,
        'page': page,
        'featured': featured,
        ...filters,
    }
}

export function featuredList(): Promise<EventListing[]> {
    return trackPromise(
        axios.get<EventListing[]>(`event`, {params: buildParams(true)}).then(value => {
            return value.data;
        })
    );
}

export function pages(loader: boolean): Promise<number> {
    const call = () => axios.get<number>('event/count').then(value => {
        return Math.ceil(value.data / PAGE_SIZE)
    });
    if (loader) {
        return trackPromise(call());
    }
    return call();
}

export function list(loader: boolean, page = 0, filter?: EventFilters): Promise<EventListing[]> {
    const call = () => {
        return axios.get<EventListing[]>('event', {params: buildParams(undefined, page, filter)}).then(value => {
            return value.data;
        });
    }
    if (loader) {
        return trackPromise(call())
    }
    return call();
}

export function findById(id: string, link?: string | null): Promise<Event> {

    const viewed = !!getWithExpiry("VIEWED_" + id);
    if (!viewed) {
        setWithExpiry("VIEWED_" + id, "TRUE", 600);
    }

    return trackPromise(
        axios.get<Event>(`event/${id}`, {params: {link, 'register-view': !viewed}}).then(value => {
            return value.data;
        })
    );
}

export function getReservedTables(id: number): Promise<number[]> {
    return trackPromise(
        axios.get<number[]>(`event/${id}/reserved-tables`).then(value => {
            return value.data
        })
    );
}

export function createLink(id: number): Promise<string> {
    return trackPromise(
        axios.post<{ value: string }>(`event/${id}/link`).then(value => {
            return value.data.value
        })
    );
}

export function getLinkData(code: string): Promise<{
    eventKey: string,
    checkout: CHECKOUT_TYPE,
    promoter?: CodeName
} | undefined> {
    return axios.get<{ eventKey: string, checkout: CHECKOUT_TYPE } | undefined>(`link/${code}`).then(value => {
        return value.data
    });
}

export function applyCoupon(event: number, code: string, blocks: number[]): Promise<CouponResponse> {
    return trackPromise(
        axios.post<CouponResponse>(`event/${event}/coupon`, {code, blocks}).then(value => {
            return value.data
        })
    );
}
