import * as React from 'react';
import {useEffect, useState} from 'react';
import MainLayout from '@/layouts/main';
import {Container, Grid, Step, StepLabel, Stepper} from '@mui/material';
import Box from '@mui/material/Box';
import OrderSummary from '@/features/checkout/components/OrderSummary';
import {Order} from '@/business/order/model';
import Typography from '@mui/material/Typography';
import StepContent from '@mui/material/StepContent';
import OrderPayment from '@/features/checkout/components/OrderPayment';
import {useNavigate, useParams} from 'react-router-dom';
import {findOrderById} from '@/business/order/service';
import {ignoreAxios} from '@/utils/errors';
import {findTicketsByOrderItems} from '@/business/tickets/service';
import {Ticket2, TicketUser} from '@/business/tickets/model';
import {Event} from '@/business/event/model';
import {findById} from '@/business/event/service';
import EventInfo from '@/features/event/components/details/EventInfo';
import Swal from 'sweetalert2';
import {ItemType, OrderStatus} from '@/business/order/domain';
import ClosedOrder from '@/features/checkout/components/ClosedOrder';
import OrderItemStep from '@/features/checkout/components/OrderItemStep';
import {CheckoutTicket} from '@/features/checkout/models/checkout';
import {SelectedPaymentMethodProvider} from "@/features/checkout/contexts/selected-payment-method";

const CheckoutPage = () => {

    const {id} = useParams();
    const [order, setOrder] = useState<Order | undefined>();
    const [items, setItems] = useState<CheckoutTicket[]>([]);
    const [step, setStep] = useState(0);
    const [event, setEvent] = useState<Event | undefined>();
    const navigate = useNavigate();

    useEffect(() => {
        findOrderById(parseInt(id!, 10)).then(order => {
            const itemIds = order.items.map(item => item.id);

            findTicketsByOrderItems(itemIds).then(tickets => {

                const removeInvalidTicketUser = (ticket: Ticket2) => {
                    if (!ticket.ticketUser?.valid) {
                        ticket.ticketUser = undefined;
                    }
                    if (ticket.guests) {
                        ticket.guests.forEach(removeInvalidTicketUser);
                    }
                }
                tickets.forEach(removeInvalidTicketUser);

                const items: CheckoutTicket[] = [];
                tickets.filter(value => !value.ticketUser?.valid).forEach(value => value.ticketUser = undefined);
                order.items.forEach(orderItem => {
                    const itemTickets = tickets.filter(ticket => ticket.orderItem === orderItem.id);
                    itemTickets.forEach((ticket) => {
                        items.push({
                            ticket: ticket,
                            item: orderItem
                        });
                    });
                })
                setOrder(order);
                setItems(items);
                if (order.openPayment) {
                    const paymentStep = items.length + 1;
                    setStep(paymentStep);
                }
            }).catch(ignoreAxios);
            findById(order.event.key).then(e => setEvent(e)).catch(ignoreAxios);
        }).catch(ignoreAxios);
    }, [id]);

    const onTicketUserComplete = (user: TicketUser, index: number) => {
        const item = items[index];
        item.ticket.ticketUser = user;
        setStep(step + 1);
        setItems(items);
    }

    const onBack = () => {
        setStep(step - 1);
    }

    const handleOrderCompleted = () => {
        const isTables = !!(order!.items.find(i => i.itemType === ItemType.TABLE));
        Swal.fire({
            title: 'Pedido criado com sucesso',
            text: 'Já processamos a sua compra e liberamos seus ingressos, agora é se preparar e aproveitar a festa.',
            icon: 'success',
            confirmButtonText: isTables ? 'Ver Reservas' : 'Ver Ingressos',
            cancelButtonText: 'Ir para a tela inicial',
            showCancelButton: true,
        }).then(value => {
            if (value.isConfirmed) {
                navigate(isTables ? `/mesas?event=${order?.event.id}` : `/ingressos?event=${order?.event.id}`);
            } else {
                navigate("/");
            }
        })
    }

    return (
        <MainLayout>
            <>
                <Container>
                    {order?.status === OrderStatus.OPEN && (
                        <SelectedPaymentMethodProvider event={order.event.id} showTaxes={!order.event.taxPaidByClient}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={7} lg={8}>
                                    {event && (
                                        <Box sx={{marginTop: '15px'}}>
                                            <EventInfo {...event!} host={event.client.name}/>
                                        </Box>
                                    )}
                                    <Box sx={{display: {xs: 'inherit', md: 'none'}, mb: '20px'}}>
                                        <OrderSummary order={order}/>
                                    </Box>
                                    <Stepper activeStep={step} orientation="vertical">
                                        {items.map((item, index) => (
                                            <Step key={index}>
                                                <OrderItemStep item={item} index={index} active={index === step}
                                                               onComplete={user => onTicketUserComplete(user, index)}
                                                               onBack={index > 0 ? onBack : undefined}/>
                                            </Step>
                                        ))}
                                        <Step>
                                            <StepLabel>
                                                <Typography sx={{textTransform: 'uppercase'}}>
                                                    Pagamento
                                                </Typography>
                                            </StepLabel>
                                            <StepContent>
                                                <OrderPayment onComplete={handleOrderCompleted} onBack={onBack}
                                                              order={order!} onNewOrderSet={setOrder}
                                                              defaultDocument={items[0].ticket.ticketUser?.document}/>
                                            </StepContent>
                                        </Step>
                                    </Stepper>
                                </Grid>
                                <Grid item xs={12} md={5} lg={4} sx={{display: {xs: 'none', md: 'block'}}}>
                                    <OrderSummary order={order}/>
                                </Grid>
                            </Grid>
                        </SelectedPaymentMethodProvider>
                    )}
                    {(order?.status === OrderStatus.CANCELLED || order?.status === OrderStatus.PAID) && (
                        <ClosedOrder order={order!}/>
                    )}
                </Container>
            </>
        </MainLayout>
    )
}

export default CheckoutPage
