import * as React from 'react';
import {useState} from 'react';
import {Box, Pagination} from '@mui/material';

interface Props<T> {
    items: T[],
    render: (page: T[]) => React.ReactNode,
    subheader?: React.ReactNode,
    pageSize?: number,
}

function getPage<T>(items: T[], pageSize: number, page: number): T[] {
    const skipped = pageSize * page;
    return items.slice(skipped, skipped + pageSize);
}

function PagedComponent<T>({items, render, subheader, pageSize = 10}: Props<T>) {
    const [pageNumber, setPageNumber] = useState(0);
    const [page, setPage] = useState<T[]>(getPage(items, pageSize, pageNumber));
    const pages = Math.ceil(items.length / pageSize);

    const handlePageChange = (value: number) => {
        setPageNumber(value);
        setPage(getPage(items, pageSize, value));
    }

    return <Box>
        <Box>
            {render(page)}
        </Box>
        <Box sx={{pt: '10px'}}>
            <Pagination page={pageNumber + 1} count={pages} onChange={(_, p) => handlePageChange(p - 1)}
                        sx={{display: 'flex', justifyContent: 'space-around'}}/>
        </Box>
    </Box>
}

export default PagedComponent;
