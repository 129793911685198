import image from '@/assets/img/maintenance.png';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MainLayout from '@/layouts/main';

const MaintenancePage = () => {
    return (
        <MainLayout>
            <Box textAlign="center">
                <img src={image} alt="Carrinho Vazio" style={{maxHeight: '300px', maxWidth: '100%'}}/>
                <Typography variant="h3">
                    Estamos em manutenção
                </Typography>
                <Typography variant="subtitle1" sx={{marginTop: '20px', maxWidth: '600px', margin: 'auto'}}>
                    Estamos realizando alguns ajustes na nossa aplicação, já já voltamos com os melhores eventos pra
                    você.
                </Typography>
                <Button variant="outlined" href="/" sx={{marginTop: '30px'}}>
                    Recarregar
                </Button>
            </Box>
        </MainLayout>
    )
}

export default MaintenancePage;
