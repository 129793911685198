import * as React from 'react';
import {useEffect, useState} from 'react';
import {Event, TicketBlockGroup} from '@/business/event/model';
import {TicketBlockStatus} from '@/business/event/domain';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Order} from '@/business/order/model';
import {Step, StepLabel, Stepper} from '@mui/material';
import BuyerDataForm, {BuyerData} from '@/features/fast-checkout/components/checkout/BuyerData';
import SelectTickets from '@/features/fast-checkout/components/checkout/SelectTickets';
import Button from '@mui/material/Button';
import EmptyList from '@/features/components/EmptyList';

interface Props {
    event: Event
    link: string | null;
    onReady: (buyer: BuyerData, order: Order) => void
}

const FastCheckoutPlaceOrder = ({onReady, event, link}: Props) => {

    const [blocks, setBlocks] = useState<TicketBlockGroup[]>([]);
    const [step, setStep] = useState(0);
    const [buyer, setBuyer] = useState<BuyerData | undefined>();

    useEffect(() => {
        if (event.services.tickets && event.ticketsConfiguration) {
            const validBlocks: TicketBlockGroup[] = [];
            event.ticketsConfiguration.ticketBlocks.forEach(bg => {
                bg.configurations = bg.configurations.filter(a => a.status === TicketBlockStatus.DISPONIVEL);
                if (bg.configurations.length) {
                    validBlocks.push(bg)
                }
            });
            setBlocks(validBlocks);
        }
    }, [event]);

    const onBuyerFilled = (data: BuyerData) => {
        setBuyer(data);
        setStep(step + 1);
    }

    const onOrderCreated = (order: Order) => {
        onReady(buyer!, order);
    }

    if (!blocks.length) {
        return <EmptyList title="Nenhum Bloco Disponível" imageStyle={{maxHeight: '100px', marginTop: '15px'}}
                          text="O evento não tem nenhum ingresso disponível para checkout rápido"/>
    }

    return (
        <Box>
            <Stepper activeStep={step} alternativeLabel>
                <Step onClick={() => setStep(0)}>
                    <StepLabel>
                        <Typography sx={{textTransform: 'uppercase'}}>
                            Comprador
                        </Typography>
                        <Box>
                            {step > 0 && buyer?.name && (
                                <Typography variant="body2">
                                    {buyer.name}
                                </Typography>
                            )}
                        </Box>
                    </StepLabel>
                </Step>
                <Step>
                    <StepLabel>
                        <Typography sx={{textTransform: 'uppercase'}}>
                            Ingressos
                        </Typography>
                    </StepLabel>
                </Step>
            </Stepper>

            {step === 0 && (
                <BuyerDataForm onReady={onBuyerFilled} initial={buyer}/>
            )}
            {step === 1 && (
                <SelectTickets event={event} blocks={blocks} buyer={buyer!}
                               onReady={onOrderCreated} link={link}/>

            )}

            <Box textAlign="center" mt="15px">
                <Box>
                    <Button variant="outlined" href="/">
                        Ver todos os eventos
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default FastCheckoutPlaceOrder
