import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';
import {CreditCard, InvoiceAddress, PaymentMethod, PaymentSettings, UnTokenizedCard} from '@/business/payment/model';

export function getCreditCards(): Promise<CreditCard[]> {
    return trackPromise(
        axios.get<CreditCard[]>(`credit-card`).then(response => {
            return response.data;
        })
    );
}

export function createCreditCard(token: string, card: UnTokenizedCard): Promise<CreditCard> {
    const payload = {
        token,
        alias: card.alias,
        number: card.number.substring(0, 4) + " XXXX XXXX " + card.number.substring(card.number.length - 4),
        address: card.address.id,
        holder: card.holder
    };
    return trackPromise(
        axios.post<CreditCard>(`credit-card`, payload).then(response => {
            return response.data;
        })
    )
}

export function deleteCreditCard(id: number): Promise<void> {
    return trackPromise(
        axios.delete<void>(`credit-card/${id}`).then(response => {
            return response.data;
        })
    )
}

export function getInvoiceAddress(): Promise<InvoiceAddress[]> {
    return trackPromise(
        axios.get<InvoiceAddress[]>(`invoice-address`).then(response => {
            return response.data;
        })
    );
}

export function createInvoiceAddress(address: InvoiceAddress): Promise<InvoiceAddress> {
    return trackPromise(
        axios.post<InvoiceAddress>(`invoice-address`, address).then(response => {
            return response.data;
        })
    );
}

export function deleteInvoiceAddress(id: number): Promise<void> {
    return trackPromise(
        axios.delete<void>(`invoice-address/${id}`).then(response => {
            return response.data;
        })
    )
}

export async function getPaymentSettings(): Promise<PaymentSettings> {
    let response = await axios.get<PaymentSettings>(`settings/payment`);
    return response.data;
}

export async function getPaymentMethods(event: number): Promise<PaymentMethod[]> {
    return trackPromise(
        axios.get<PaymentMethod[]>(`event/${event}/payment-methods`).then(value => value.data)
    )
}

export async function getDefaultPaymentMethod(event: number, fastCheckout: boolean): Promise<PaymentMethod> {
    return trackPromise(
        axios.get<PaymentMethod>(`event/${event}/payment-methods/default`, {params: {'fast-checkout': fastCheckout}}).then(value => value.data)
    )
}
