import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {deleteInvoiceAddress, getInvoiceAddress} from '@/business/payment/service';
import {ignoreAxios} from '@/utils/errors';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/redux';
import {InvoiceAddress} from '@/business/payment/model';
import Confirm from '@/features/components/Confirm';
import {toast} from 'react-toastify';
import {remove, set} from '@/features/checkout/stores/address-store';
import List from '@mui/material/List';
import {ListItem, ListItemText} from '@mui/material';
import {formatInvoiceAddress} from '@/utils/formatters';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';

const UserInvoiceAddresses = () => {
    const [confirm, setConfirm] = useState<InvoiceAddress | undefined>();
    const {items: addresses} = useSelector((state: RootState) => state.address);
    const dispatch = useDispatch()

    useEffect(() => {
        if (!addresses) {
            getInvoiceAddress()
                .then(value => dispatch(set(value)))
                .catch(ignoreAxios)
        }
    }, [addresses, dispatch]);

    const deleteAddress = () => {
        if (!confirm) {
            return;
        }
        deleteInvoiceAddress(confirm.id)
            .then(() => {
                dispatch(remove(confirm.id));
                toast.success("Endereço excluído com sucesso");
                setConfirm(undefined);
            })
            .catch(ignoreAxios)
    }

    return (
        <Box>

            {!!addresses?.length && (
                <List sx={{width: '100%'}}>
                    {(addresses ?? []).map((address) => {
                        const formatted = formatInvoiceAddress(address);
                        return (
                            <ListItem alignItems="flex-start" key={address.id} sx={{paddingBottom: '10px'}}
                                      secondaryAction={
                                          <IconButton edge="end" aria-label="delete"
                                                      onClick={() => setConfirm(address)}>
                                              <DeleteIcon/>
                                          </IconButton>
                                      }>
                                <ListItemText
                                    primary={formatted.line1}
                                    secondary={formatted.line2}
                                />
                            </ListItem>
                        )
                    })}
                </List>
            )}

            {addresses && !addresses.length && (
                <Typography>
                    Nenhum Endereço de Faturamento cadastrado.
                </Typography>
            )}


            {confirm && (
                <Confirm
                    title="Excluir Endereço?"
                    text="Para excluir endereços com cartões cadastrados, primeiro exclua os cartões."
                    onConfirm={() => deleteAddress()} onCancel={() => setConfirm(undefined)}/>
            )}
        </Box>
    )
}

export default UserInvoiceAddresses
