import * as React from 'react';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {findById} from '@/business/event/service';
import {Event} from '@/business/event/model';
import EmptyList from '@/features/components/EmptyList';
import CenteredLayout from '@/layouts/centered';
import EventCover from '@/features/components/EventCover';
import Box from '@mui/material/Box';
import logo from '@/assets/img/logo-full.png';
import Typography from '@mui/material/Typography';
import {Order, PixPayment} from '@/business/order/model';
import FastCheckoutPlaceOrder from '@/features/fast-checkout/components/PlaceOrder';
import {BuyerData} from '@/features/fast-checkout/components/checkout/BuyerData';
import {Ticket2} from '@/business/tickets/model';
import {FastCheckoutPayment} from '@/features/fast-checkout/components/Payment';
import {findFastCheckoutOrder, payWithPix} from '@/business/order/service';
import {OrderStatus} from '@/business/order/domain';
import {findTicketsByOrderPublicKey} from '@/business/tickets/service';
import {ignoreAxios} from '@/utils/errors';
import FastCheckoutTickets from '@/features/fast-checkout/components/Tickets';
import Button from '@mui/material/Button';
import {useQuery} from '@/utils/hooks/useQuery';
import {getLink} from '@/utils/link-storage';
import {SelectedPaymentMethodProvider} from "@/features/checkout/contexts/selected-payment-method";

const STORAGE_KEY = "FCS";

const persistState = (event: number, order?: string) => {
    if (order) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify({event, order}));
    } else {
        localStorage.removeItem(STORAGE_KEY);
    }
}

const recoverState = async (event: number): Promise<Order | null> => {
    const stateStr = localStorage.getItem(STORAGE_KEY);
    if (!stateStr) {
        return null;
    }
    try {
        const {event: evt, order: oid} = JSON.parse(stateStr);
        if (event !== evt || !oid) {
            return null;
        }
        const order = await findFastCheckoutOrder(oid);
        if (!order) {
            return null;
        }
        if (order.status === OrderStatus.CANCELLED) {
            return null;
        }
        if (!order.openPayment && order.status === OrderStatus.OPEN) {
            order.openPayment = await payWithPix(order.id);
        }
        return order;
    } catch (e) {
        console.error(e);
        return null;
    }
}

const FastCheckoutPage = () => {
    const [loaded, setLoaded] = useState(false);
    const [event, setEvent] = useState<Event | undefined>();
    const [order, setOrder] = useState<Order | undefined>();
    const [payment, setPayment] = useState<PixPayment | undefined>();
    const [tickets, setTickets] = useState<Ticket2[] | undefined>();

    let {id} = useParams();
    const link = useQuery().get('link') ?? getLink(id ?? "");

    useEffect(() => {
        setLoaded(false);
        if (id) {
            findById(id, link).then(async (value) => {
                setEvent(value);
                if (value) {
                    const order = await recoverState(value.id);
                    if (order) {
                        setOrder(order);
                        setPayment(order.openPayment);
                        if (order.status === OrderStatus.PAID) {
                            findTicketsByOrderPublicKey(order.publicKey!).then(value => {
                                setTickets(value);
                            }).catch(ignoreAxios);
                        }
                    }
                }
            }).finally(() => setLoaded(true));
        } else {
            setEvent(undefined);
            setLoaded(true);
        }
    }, [id, link]);

    const onOrderCreated = (_: BuyerData, order: Order) => {
        persistState(event?.id!, order.publicKey);
        setOrder(order);
        setPayment(order.openPayment);
    }

    const onOrderPaid = () => {
        if (!order) {
            return;
        }
        order.status = OrderStatus.PAID;
        findTicketsByOrderPublicKey(order.publicKey!).then(value => {
            setTickets(value);
        }).catch(ignoreAxios);
    }

    const handleNewOrder = () => {
        persistState(event!.id);
        setOrder(undefined);
        setPayment(undefined);
        setTickets(undefined);
    }

    return (
        <CenteredLayout>
            {loaded && (
                <Box maxWidth="350px">
                    <Box textAlign="center" mb="15px">
                        <img src={logo} style={{maxHeight: '45px'}}
                             alt="myPromoter - A sua plataforma de eventos"/>
                        <Typography variant="h2">
                            Checkout Rápido
                        </Typography>
                    </Box>

                    {event && (
                        <SelectedPaymentMethodProvider event={event?.id} fastCheckout={true}
                                                       showTaxes={!event.taxPaidByClient}>
                            <Box>
                                <Box margin="auto" marginBottom="10px">
                                    <EventCover src={event.coverSmall ?? event.cover} label={event.name}/>
                                </Box>

                                {!order && (
                                    <FastCheckoutPlaceOrder event={event} onReady={onOrderCreated} link={link}/>
                                )}

                                {order?.status === OrderStatus.OPEN && payment && (
                                    <FastCheckoutPayment payment={payment} order={order} onReady={onOrderPaid}/>
                                )}

                                {order?.status === OrderStatus.PAID && tickets && (
                                    <FastCheckoutTickets order={order} tickets={tickets}/>
                                )}

                                {order && (
                                    <Box marginTop="15px" textAlign="center">
                                        <Button variant="text" onClick={handleNewOrder}>
                                            Fazer uma nova compra
                                        </Button>
                                    </Box>
                                )}
                            </Box>
                        </SelectedPaymentMethodProvider>
                    )}

                    {!event && (
                        <Box maxWidth="350px" minWidth="200px">
                            <EmptyList title="Evento não encontrado" imageStyle={{maxHeight: '100px'}}
                                       text="O evento que você está procurando não existe, ou não está mais disponível na plataforma"/>
                        </Box>
                    )}
                </Box>

            )}
        </CenteredLayout>
    )
}

export default FastCheckoutPage
