import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import {useForm} from 'react-hook-form';
import message, {ignoreAxios} from '@/utils/errors';
import Patterns from '@/utils/patterns';
import {signup} from '@/business/security/service';
import {preventDefault} from '@/utils/functions';
import Typography from '@mui/material/Typography';
import {toast} from 'react-toastify';
import {trim} from '@/utils/masks/text';
import ControlledTextField from '@/features/components/ControlledTextField';
import {useAccountContext} from "@/utils/context/AccountContext";

export interface Props {
    onCreated?: () => void,
    onLogin: () => void
}

const CreateAccountForm = ({onCreated, onLogin}: Props) => {

    const {setUser, login} = useAccountContext();

    const {control, register, handleSubmit, formState: {errors, isSubmitted}} = useForm({
        defaultValues: {
            name: login.user?.name,
            email: login.user?.email,
            password: ''
        }
    });

    const onSubmit = (data: any) => {
        const payload = {
            ...data,
            cpf: login.user?.cpf
        }
        signup(payload).then((user) => {
            setUser(user);
            toast.success("Conta criada com sucesso.");
            if (onCreated) {
                onCreated()
            }
        }).catch(ignoreAxios)
    };

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <TextField
                error={isSubmitted && !!errors.name}
                helperText={message(errors.name)}
                margin="normal"
                required
                fullWidth
                label="Nome Completo"
                autoComplete="name"
                autoFocus
                {...register("name", {required: true})}
            />
            <ControlledTextField
                control={control}
                name="email"
                error={isSubmitted && !!errors.email}
                helperText={message(errors.email, {patternMsg: 'O email informado é inválido'})}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                autoComplete="email"
                formatter={trim}
                rules={{required: true, pattern: Patterns.email}}
            />
            <TextField
                error={isSubmitted && !!errors.password}
                helperText={message(errors.password)}
                margin="normal"
                required
                fullWidth
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                {...register("password", {required: true})}
            />

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
            >
                Criar Conta
            </Button>

            <Box textAlign='right' paddingTop={2}>
                <Typography variant="body2" component="div">
                    Já possui conta? &nbsp;
                    <Link href="#" onClick={preventDefault(onLogin)}>
                        <b>Entrar</b>
                    </Link>
                </Typography>
            </Box>

        </Box>
    )
}

export default CreateAccountForm
