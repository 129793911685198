import * as React from 'react';
import {useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {formatDecimal} from '@/utils/formatters';
import {TaxLabel} from "@/features/checkout/components/utils/TaxLabel";

export interface Props {
    disabled: boolean,
    value: number,
    action: React.ReactNode,
    actionAlign?: 'bottom' | 'middle',
    label: string,
    discount?: number,
    discountPercent?: number;
}

const TicketBlockDisplay = ({
                                disabled,
                                label,
                                value,
                                action,
                                actionAlign = 'bottom',
                                discount,
                                discountPercent
                            }: Props) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
                width: '100%'
            }}>

            <table width='100%'>
                <tbody>
                <tr>
                    <td colSpan={actionAlign === 'middle' ? 1 : 2} style={{paddingRight: '10px'}}>
                        <Typography variant="subtitle1"
                                    sx={{textTransform: 'uppercase', fontWeight: 'bold', lineHeight: 1}}>
                            {label}
                        </Typography>
                    </td>
                    {actionAlign === 'middle' && (
                        <td style={{whiteSpace: 'nowrap', width: '1px'}} valign='middle' rowSpan={2}>
                            {action}
                        </td>
                    )}
                </tr>
                <tr>
                    <td>
                        <Box display="flex" alignItems="center" gap="5px">
                            {!discount && (
                                <Typography variant="body1" component="span"
                                            sx={{color: disabled ? theme.palette.text.disabled : theme.palette.text.primary}}>
                                    R$ {formatDecimal(value)}
                                </Typography>
                            )}
                            {discount && (
                                <Typography variant="body1" component="span"
                                            sx={{color: disabled ? theme.palette.text.disabled : theme.palette.text.primary}}>
                                    R$ <span
                                    style={{textDecoration: 'line-through'}}>{formatDecimal(value)}</span> {formatDecimal(value - discount)}
                                </Typography>
                            )}
                            <TaxLabel value={value}/>
                        </Box>
                        {discount && discountPercent && (
                            <Box>
                                <Typography variant="body1" color="info.light">
                                    Cupom Aplicado: {discountPercent}%
                                </Typography>
                            </Box>
                        )}
                    </td>
                    {actionAlign === 'bottom' && (
                        <td style={{whiteSpace: 'nowrap', width: '1px'}} valign='middle'>
                            {action}
                        </td>
                    )}
                </tr>
                </tbody>
            </table>
        </Box>
    )
}

export default TicketBlockDisplay
