import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import {CreditCard} from '@/business/payment/model';

const tag = "MP_SEL_CARD";

export interface State {
    items?: CreditCard[],
    selected: number;
}

export const cardSlice = createSlice({
    name: 'card',
    initialState: {selected: 0} as State,
    reducers: {
        setCards: (state: State, action: PayloadAction<CreditCard[]>) => {
            state.items = action.payload;
            const saved = localStorage.getItem(tag);
            if (saved) {
                const cardId = parseInt(saved, 10);
                state.selected = action.payload.find(card => card.id === cardId)?.id ?? 0;
            }
        },
        selectCard: (state: State, action: PayloadAction<number>) => {
            state.selected = action.payload;
            localStorage.setItem(tag, `${action.payload}`);
        },
        addCard: (state: State, action: PayloadAction<CreditCard>) => {
            if (!state.items) {
                state.items = [];
            }
            state.items.push(action.payload);
            state.selected = action.payload.id;
        },
        removeCard: (state: State, action: PayloadAction<number>) => {
            if (!state.items) {
                return;
            }
            state.items = state.items.filter(a => a.id !== action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const {addCard, setCards, removeCard, selectCard} = cardSlice.actions

export default cardSlice.reducer
