import * as React from 'react';
import {useState} from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Tab,
    Tabs
} from '@mui/material';
import Button from '@mui/material/Button';
import {Order} from '@/business/order/model';
import ValueLabel from '@/features/components/ValueLabel';
import EventCover from '@/features/components/EventCover';
import {formatDateTime, formatDecimal} from '@/utils/formatters';
import OrderItemDetails from '@/features/orders/components/OrderItemDetails';
import {OrderStatus} from '@/business/order/domain';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DialogHeader from '@/features/components/DialogHeader';

interface Props {
    order: Order,
    onClose: VoidFunction,
    onCheckoutClick: VoidFunction,
}

const OrderDetails = ({order, onClose, onCheckoutClick}: Props) => {

    const [index, setIndex] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setIndex(newValue);
    };

    return (
        <Dialog
            open={true}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogHeader onClose={onClose}>
                Detalhes do Pedido
            </DialogHeader>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <EventCover src={order.event.cover} label={order.event.name}/>
                    {order.status === OrderStatus.OPEN && (
                        <Alert severity="warning">
                            <AlertTitle>Pedido em Aberto</AlertTitle>
                            Realize agora o pagamento para garantir os ingressos. O pedido poderá ser cancelado
                            automaticamente após 30 minutos sem pagamento.
                        </Alert>
                    )}

                    <Tabs value={index} onChange={handleChange}>
                        <Tab label="Pedidos"/>
                        <Tab label="Items do Pedido"/>
                    </Tabs>

                    <div role="tabpanel" hidden={index !== 0}>
                        {0 === index && (
                            <Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <ValueLabel label="Código do Pedido" value={order.number} marginTop="15px"/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ValueLabel label="Data do Pedido" value={formatDateTime(order.date)}
                                                    marginTop="15px"/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ValueLabel label="Valor Total do Pedido"
                                                    value={formatDecimal(order.totalValue, 'R$ ')}
                                                    marginTop="15px"/>
                                    </Grid>
                                    {order.coupon && (
                                        <Grid item xs={12} md={6}>
                                            <ValueLabel label="Cupom Usado" value={`${order.coupon.code}`}
                                                        marginTop="15px"/>
                                        </Grid>
                                    )}
                                    {!!order.processingFee && (
                                        <Grid item xs={12} md={6}>
                                            <ValueLabel label="Taxa de Processamento"
                                                        value={formatDecimal(order.processingFee, 'R$ ')}
                                                        marginTop="15px"/>
                                        </Grid>
                                    )}
                                    {order.card && (
                                        <Grid item xs={12} md={6}>
                                            <ValueLabel label="Cartão utilizado para pagamento" value={order.card}
                                                        marginTop="15px"/>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        )}
                    </div>

                    <div role="tabpanel" hidden={index !== 1}>
                        {1 === index && (
                            <OrderItemDetails items={order.items}/>
                        )}
                    </div>

                    {order.status === OrderStatus.OPEN && (
                        <Box sx={{mt: '10px'}}>
                            <Button variant="contained" startIcon={<ShoppingCartIcon/>}
                                    onClick={onCheckoutClick} sx={{width: '100%'}}>
                                Finalizar Compra
                            </Button>
                        </Box>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>Fechar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default OrderDetails;
