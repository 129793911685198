import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useForm} from 'react-hook-form';
import ControlledTextField from '@/features/components/ControlledTextField';
import message, {ignoreAxios} from '@/utils/errors';
import Patterns from '@/utils/patterns';
import {Grid} from '@mui/material';
import {formatDate} from '@/utils/formatters';
import ControlledSelect from '@/features/components/ControlledSelect';
import MenuItem from '@mui/material/MenuItem';
import {Gender, User} from '@/business/security/model';
import TextField from '@mui/material/TextField';
import {updateUser} from '@/business/security/service';
import {toDate} from '@/utils/parsers/date';
import {toInt} from '@/utils/parsers/number';
import {maskCpf, maskPhone} from '@/utils/masks/user-data';
import {maskDate} from '@/utils/masks/date';
import {useMobile} from '@/utils/hooks/useMobile';
import {useAccountContext} from "@/utils/context/AccountContext";

export interface Props {
    user: User,
    enabled?: boolean,
    actions?: React.ReactNode,
    onSaved?: () => void;
    cancelText?: string;
    onCancel?: () => void
}

export const DEFAULT_DATA = (user: User) => {
    return {
        phone: maskPhone(user?.phone),
        gender: user?.gender ?? '',
        name: user?.name ?? '',
        email: user?.email ?? '',
        cpf: user?.cpf ?? '',
        birthDate: formatDate(user?.birthDate)
    }
}

const AccountDataForm = ({
                             user,
                             actions,
                             enabled = true,
                             onSaved,
                             cancelText = "Cancelar",
                             onCancel
                         }: Props) => {
    const isMobile = useMobile();
    const {setUser} = useAccountContext();

    const [disabled, setDisabled] = useState(!enabled);
    const {
        control,
        register,
        handleSubmit,
        formState: {errors, isSubmitted}
    } = useForm({defaultValues: DEFAULT_DATA(user)});

    const onSubmit = async (data: any) => {
        updateUser({
            name: data.name,
            cpf: data.cpf,
            email: data.email,
            phone: toInt(data.phone)!,
            gender: data.gender ? data.gender : null,
            birthDate: toDate(data.birthDate)!
        }).then(value => {
            setUser(value);
            setDisabled(true);
            if (onSaved) {
                onSaved();
            }
        }).catch(ignoreAxios)
    }

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        } else {
            setDisabled(true);
        }
    }

    return (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <TextField
                disabled={disabled}
                error={!disabled && isSubmitted && !!errors.name}
                helperText={disabled ? undefined : message(errors.name)}
                margin="normal"
                required
                fullWidth
                label="Nome Completo"
                {...register("name", {required: true})}
            />

            <TextField
                disabled={disabled}
                error={!disabled && isSubmitted && !!errors.email}
                helperText={disabled ? undefined : message(errors.email, {patternMsg: 'O email informado é inválido'})}
                margin="normal"
                required
                fullWidth
                label="Email"
                {...register("email", {required: true, pattern: Patterns.email})}
            />

            <Grid container columnSpacing={2}>
                <Grid item xs={12} md={6}>
                    <ControlledTextField
                        control={control}
                        name="cpf"
                        error={!disabled && isSubmitted && !!errors.cpf}
                        helperText={disabled ? undefined : message(errors.cpf, {patternMsg: 'O CPF informado é inválido'})}
                        margin="normal"
                        required
                        fullWidth
                        label="CPF"
                        formatter={maskCpf}
                        inputProps={{maxLength: 14}}
                        rules={{pattern: Patterns.cpf, required: true}}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ControlledTextField
                        control={control}
                        name="phone"
                        error={!disabled && isSubmitted && !!errors.phone}
                        helperText={disabled ? undefined : message(errors.phone, {patternMsg: 'O Telefone informado é inválido'})}
                        margin="normal"
                        required
                        fullWidth
                        label="Telefone"
                        formatter={maskPhone}
                        inputProps={{maxLength: 15}}
                        rules={{pattern: Patterns.phone, required: true}}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ControlledTextField
                        control={control}
                        name="birthDate"
                        error={!disabled && isSubmitted && !!errors.birthDate}
                        helperText={disabled ? undefined : message(errors.birthDate, {patternMsg: 'A Data de Nascimento informada é inválida'})}
                        margin="normal"
                        required
                        fullWidth
                        label="Data de Nascimento"
                        formatter={maskDate}
                        inputProps={{maxLength: 10}}
                        rules={{pattern: Patterns.date, required: true}}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ControlledSelect control={control} name="gender" label="Gênero" disabled={disabled}>
                        <MenuItem value={Gender.FEMININO}>Feminino</MenuItem>
                        <MenuItem value={Gender.MASCULINO}>Masculino</MenuItem>
                        <MenuItem value={Gender.OUTRO}>Outro/Prefiro na informar</MenuItem>
                    </ControlledSelect>
                </Grid>
            </Grid>

            {!disabled && (
                <Box sx={{textAlign: 'right', paddingTop: '15px'}}>
                    <Button sx={{marginRight: '15px'}} variant="outlined"
                            onClick={handleCancel}>{cancelText}</Button>
                    <Button variant="contained" type="submit">Salvar</Button>
                </Box>
            )}

            {disabled && (
                <Box
                    sx={{mt: '10px', display: isMobile ? undefined : 'flex', justifyContent: 'space-between'}}>
                    <Box>
                        <Button variant="contained" sx={isMobile ? {width: '100%', marginBottom: '10px'} : {}}
                                onClick={() => setDisabled(false)}>Editar Dados</Button>
                    </Box>
                    <Box sx={{textAlign: 'right'}}>
                        {actions}
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default AccountDataForm
