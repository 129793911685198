import * as React from 'react';
import {useEffect, useState} from 'react';
import {EventTickets, TicketDisplay} from '@/business/tickets/model';
import {Dialog, DialogActions, DialogContent} from '@mui/material';
import Button from '@mui/material/Button';
import {formatDateTime} from '@/utils/formatters';
import Typography from '@mui/material/Typography';
import {exportTicketById} from '@/business/tickets/service';
import {ignoreAxios} from '@/utils/errors';
import {download} from '@/utils/download';
import DialogHeader from '@/features/components/DialogHeader';
import {EventBase} from '@/business/event/model';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import FreeTickets from '@/features/tickets/components/tickets-modal/FreeTickets';
import OrderTickets from '@/features/tickets/components/tickets-modal/OrderTickets';
import {TicketGuestsLabels} from '@/features/checkout/components/TicketGuests';

interface Props {
    event: EventBase,
    tickets: EventTickets,
    onCancel: () => void
}

const TicketsModal = ({event, onCancel, tickets}: Props) => {

    const [hasGuests, setHasGuests] = useState(false);

    useEffect(() => {
        if (tickets.free.find(a => a.guests?.length)) {
            setHasGuests(true);
            return;
        }
        for (let order of tickets.orders) {
            for (let item of order.items) {
                if (item.tickets.find(a => a.guests?.length)) {
                    setHasGuests(true);
                    return;
                }
            }
        }
        setHasGuests(false);
    }, [tickets]);

    const getTicketIds = (ticket: TicketDisplay) => {
        const ids = [ticket.id];
        if (ticket.guests) {
            ids.push(...ticket.guests.map(a => a.id));
        }
        return ids;
    }

    const handleDownloadAll = () => {
        const ids: number[] = [];
        for (let ticket of tickets.free) {
            ids.push(...getTicketIds(ticket));
        }
        for (let order of tickets.orders) {
            for (let item of order.items) {
                for (let ticket of item.tickets) {
                    ids.push(...getTicketIds(ticket));
                }
            }
        }
        exportTicketById(ids).then(value => {
            download(value, `Ticket ${event.name}.pdf`).catch(ignoreAxios);
        }).catch(ignoreAxios);
    }

    return (
        <>
            <Dialog onClose={onCancel} open={true} fullWidth={true} maxWidth="md">
                <DialogHeader onClose={onCancel}>
                    {event.name}
                </DialogHeader>
                <DialogContent>

                    <Typography variant="h5" component="div">
                        <b>Data do Evento</b>
                    </Typography>

                    <Typography variant="subtitle2" color="info.light" component="div">
                        <b>
                            {formatDateTime(event.date)}
                        </b>
                    </Typography>

                    {hasGuests && (
                        <fieldset style={{margin: '10px 0 0 0'}}>
                            <TicketGuestsLabels/>
                        </fieldset>
                    )}

                    <FreeTickets initialTickets={tickets?.free ?? []} disabled={false}/>
                    <OrderTickets initialTickets={tickets?.orders ?? []} disabled={false}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDownloadAll} variant="outlined" startIcon={<ConfirmationNumberIcon/>}>Baixar
                        Ingressos</Button>
                    <Button onClick={onCancel} variant="contained">Fechar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default TicketsModal;
