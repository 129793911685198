import * as React from "react";
import {useEffect, useState} from "react";
import {getVipListState} from "@/business/tickets/service";
import {Skeleton} from "@mui/material";
import {Event} from "@/business/event/model";
import {VipListStatusExpired} from "@/features/event/components/details/EventVipList/VipListStatusExpired";
import {VipListStatusAvailable} from "@/features/event/components/details/EventVipList/VipListStatusAvailable";
import {VipListStatusAlreadyOnList} from "@/features/event/components/details/EventVipList/VipListStatusAlreadyOnList";
import Box from "@mui/material/Box";
import {useAccountContext} from "@/utils/context/AccountContext";

interface Props {
    event: Event;
    onChange: (value: boolean) => void;
    link?: string | null;
}

export const VipListStatus = ({onChange, event, link}: Props) => {

    const {user} = useAccountContext();
    const [loading, setLoading] = useState(false);
    const [loadedUser, setLoadedUser] = useState<number | undefined>(user?.id);
    const [reserved, setReserved] = useState(event.userStatus.vipListReserved);

    const handleChange = (reserved: boolean) => {
        onChange(reserved);
        setReserved(reserved);
    }

    useEffect(() => {
        if (user?.id === loadedUser) {
            return;
        }
        if (!user?.id) {
            handleChange(false);
            return;
        }

        setLoading(true);
        getVipListState(event.id).then(value => {
            handleChange(value);
            setLoadedUser(user.id);
        }).finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id, event.id]);

    if (loading) {
        return <Box>
            <Skeleton height={55}/>
            <Skeleton height={55}/>
        </Box>
    }
    if (event.vipListConfiguration?.expired || event.closed) {
        return <VipListStatusExpired/>
    }

    if (!user) {
        return <VipListStatusAvailable event={event} onStatusChange={() => handleChange(true)} link={link}/>;
    }
    if (reserved) {
        return <VipListStatusAlreadyOnList event={event} onStatusChange={() => handleChange(false)}/>
    }
    return <VipListStatusAvailable event={event} onStatusChange={() => handleChange(true)} link={link}/>;
}