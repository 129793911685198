import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useForm} from 'react-hook-form';
import message, {ignoreAxios} from '@/utils/errors';
import {Grid, Link} from '@mui/material';
import Patterns from '@/utils/patterns';
import ControlledTextField from '@/features/components/ControlledTextField';
import ControlledSelect from '@/features/components/ControlledSelect';
import MenuItem from '@mui/material/MenuItem';
import {Gender} from '@/business/security/model';
import Button from '@mui/material/Button';
import {maskCpf} from '@/utils/masks/user-data';
import {TicketUser} from '@/business/tickets/model';
import {updateTicketUser} from '@/business/tickets/service';
import {preventDefault} from '@/utils/functions';
import {useAccountContext} from "@/utils/context/AccountContext";

export interface Props {
    id: number,
    user?: TicketUser,
    onComplete: (user: TicketUser) => void,
    onBack?: () => void,
    autoFillLabel?: string,
    children?: React.ReactNode,
    nextText?: string
    backText?: string
}

const TicketUserForm = ({id, user, onComplete, onBack, autoFillLabel, children, nextText, backText}: Props) => {
    const {control, setValue, handleSubmit, formState: {errors, isSubmitted}} = useForm({
        defaultValues: {
            gender: user?.gender ?? '',
            name: user?.name ?? '',
            document: user?.document ?? ''
        }
    });
    const {login, user: current} = useAccountContext();

    const autoFill = () => {
        if (current) {
            setValue('name', current.name, {shouldDirty: true, shouldTouch: true});
            setValue('document', current.cpf ?? '', {shouldDirty: true, shouldTouch: true});
            setValue('gender', current.gender ?? '', {shouldDirty: true, shouldTouch: true});
        } else {
            login.show();
        }
    }

    const changed = (data: any) => {
        if (!user) {
            return true;
        }
        if (data.gender !== user?.gender) {
            return true;
        }
        if (data.name !== user?.name) {
            return true;
        }
        return data.document !== user?.document;
    }

    const onSubmit = (data: any) => {
        //Convert Empty String -> Undefined to avoid enum conversion error
        if (!data.gender)
            data.gender = undefined;

        if (!changed(data)) {
            onComplete(data);
        } else {
            updateTicketUser(id, data).then(() => {
                onComplete(data);
            }).catch(ignoreAxios);
        }
    }

    return (
        <Box>
            {autoFillLabel && (
                <Typography variant="body1">
                    {autoFillLabel} Preencha automaticamente <Link href="#" onClick={preventDefault(autoFill)}>clicando
                    aqui</Link>
                </Typography>
            )}

            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                <ControlledTextField
                    control={control}
                    name="name"
                    rules={{required: true}}
                    error={isSubmitted && !!errors.name}
                    helperText={message(errors.name)}
                    margin="normal"
                    required
                    fullWidth
                    label="Nome Completo"
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <ControlledTextField
                            control={control}
                            name="document"
                            error={isSubmitted && !!errors.document}
                            helperText={message(errors.document, {patternMsg: 'O CPF informado é inválido'})}
                            margin="normal"
                            required
                            fullWidth
                            label="CPF"
                            rules={{required: true, pattern: Patterns.cpf}}
                            formatter={maskCpf}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ControlledSelect control={control} name="gender" label="Gênero">
                            <MenuItem value={Gender.FEMININO}>Feminino</MenuItem>
                            <MenuItem value={Gender.MASCULINO}>Masculino</MenuItem>
                            <MenuItem value={Gender.OUTRO}>Outro/Prefiro na informar</MenuItem>
                        </ControlledSelect>
                    </Grid>
                </Grid>

                <Box>
                    {children}
                </Box>


                <Box sx={{textAlign: 'right', paddingTop: '15px'}}>
                    {onBack && (
                        <Button sx={{marginRight: '15px'}} variant="outlined" onClick={onBack}>
                            {backText ?? 'Voltar'}
                        </Button>
                    )}
                    <Button variant="contained" type="submit">{nextText ?? 'Próximo'}</Button>
                </Box>

            </Box>
        </Box>
    )
}

export default TicketUserForm
