import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice} from '@reduxjs/toolkit'
import {InvoiceAddress} from '@/business/payment/model';

export interface State {
    items?: InvoiceAddress[],
    loading: boolean;
}

export const addressSlice = createSlice({
    name: 'address',
    initialState: {} as State,
    reducers: {
        set: (state: State, action: PayloadAction<InvoiceAddress[]>) => {
            state.items = action.payload;
        },
        add: (state: State, action: PayloadAction<InvoiceAddress>) => {
            if (!state.items) {
                state.items = [];
            }
            state.items.push(action.payload);
        },
        remove: (state: State, action: PayloadAction<number>) => {
            if (!state.items) {
                return;
            }
            state.items = state.items.filter(a => a.id !== action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const {add, set, remove} = addressSlice.actions

export default addressSlice.reducer
