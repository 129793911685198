import * as React from 'react';
import Typography from '@mui/material/Typography';

interface Props {
    text: string,
}

const PageTitle = ({text}: Props) => {
    return (
        <Typography variant="h2" sx={{marginBottom: '20px'}}>
            {text}
        </Typography>
    )
}

export default PageTitle
