import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Button from '@mui/material/Button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Typography from '@mui/material/Typography';

export interface Props {
    text: string;
    copyText?: string;
    copiedText?: string;
}

const CopiableText = ({text, copyText = "Copiar", copiedText = "Copiado"}: Props) => {

    const [copied, setCopied] = useState<NodeJS.Timeout | undefined>();

    const handleCopy = () => {
        if (copied) {
            clearTimeout(copied);
        }
        setCopied(setTimeout(() => {
            clearTimeout(copied);
            setCopied(undefined);
        }, 5000));
    }

    return (
        <Box>
            <Box sx={{marginBottom: '10px',}}>
                <CopyToClipboard text={text} onCopy={handleCopy}>
                    <Typography component="span" variant="body2"
                                color="info.light"
                                sx={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    cursor: 'pointer'
                                }}>
                        {text}
                    </Typography>
                </CopyToClipboard>
            </Box>

            <CopyToClipboard text={text} onCopy={handleCopy}>
                <Box textAlign="center">
                    <Button variant="contained" startIcon={<ContentCopyIcon/>}
                            sx={{mb: '5px'}}>
                        {copied ? copiedText : copyText}
                    </Button>
                </Box>
            </CopyToClipboard>
        </Box>);
}

export default CopiableText
