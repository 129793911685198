import defaultImage from '@/assets/img/empty_list.png';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {CSSProperties, ReactNode} from 'react';

export interface Props {
    title: string,
    text: string,
    image?: string,
    imageStyle?: CSSProperties | undefined;
    button?: ReactNode | null
}

const EmptyList = ({
                       title,
                       text,
                       image,
                       imageStyle = {},
                       button = <Button variant="outlined" href="/" sx={{marginTop: '20px'}}>
                           Ver eventos
                       </Button>
                   }: Props) => {
    return (
        <>
            <Box textAlign="center"
                 sx={{
                     display: 'flex',
                     flexDirection: 'column',
                     height: '100%',
                     alignItems: 'center',
                     justifyContent: 'center'
                 }}>
                <Box>
                    <img src={image ?? defaultImage} alt="Carrinho Vazio"
                         style={{maxHeight: '300px', maxWidth: '100%', ...imageStyle}}/>
                    <Typography variant="h3" sx={{mt: '15px', mb: '20px'}}>
                        {title}
                    </Typography>
                    <Typography variant="subtitle1" sx={{maxWidth: '600px', margin: 'auto'}}>
                        {text}
                    </Typography>
                    {button}
                </Box>
            </Box>
        </>
    )
}


export default EmptyList;
