import * as React from 'react';

export interface Props {
    classification: number
}

const Colors: { [key: number]: string } = {
    0: '#00b050',
    10: '#47d5ff',
    12: '#f8da00',
    14: '#f88201',
    16: '#d00004',
    18: '#141514'
}

const Classification = ({classification}: Props) => {
    return (
        <span style={{
            backgroundColor: Colors[classification] ?? Colors[0],
            borderRadius: '5px',
            border: `2px solid black`,
            padding: '5px',
            color: 'white'
        }}>
            <b>
                {classification ? classification : 'L'}
            </b>
        </span>
    )
}

export default Classification
