import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {BuyerData} from '@/features/fast-checkout/components/checkout/BuyerData';
import {MessageUtils} from '@/utils/message';
import {updateUser} from '@/business/security/service';
import {ignoreAxios} from '@/utils/errors';
import {useAccountContext} from "@/utils/context/AccountContext";

interface Props {
    buyer: BuyerData;
    createOrder: VoidFunction;
    disabled: boolean;
}

const PayWithCreditCardButton = ({buyer, createOrder, disabled}: Props) => {

    const [waitingOrder, setWaitingOrder] = useState(false);
    const {login, user} = useAccountContext();

    const isLogged = useCallback(() => {
        return user?.email === buyer.email;
    }, [user, buyer.email]);

    useEffect(() => {
        if (user && isLogged() && waitingOrder) {
            setWaitingOrder(false);
            if (!user.cpf) {
                updateUser({
                    name: user.name,
                    email: user.email,
                    cpf: buyer.document,
                    birthDate: user.birthDate,
                    gender: user.gender,
                    phone: user.phone
                }).then(() => {
                    createOrder();
                }).catch(ignoreAxios)
            } else {
                createOrder();
            }
        }
    }, [user, waitingOrder, isLogged, createOrder, buyer.document]);

    const constHandlePayWithCreditCard = () => {
        MessageUtils.showInfo(
            "Por razões de segurança, as bandeiras do cartão exigem mais informações para pagamentos com cartão de crédito. Por isso, pagamentos com cartão precisam ser feitos por usuários logados e não podem ser realizados através de checkout rápido",
            {
                title: "Pagamento com Cartão de Crédito",
                okText: "Fazer Login e Usar Cartão",
                cancelText: "Cancelar"
            }).then(value => {
            if (value.isConfirmed) {
                setWaitingOrder(true);
                if (!isLogged()) {
                    login.show({
                        name: buyer.name,
                        email: buyer.email,
                        cpf: buyer.document
                    });
                }
            }
        });
    }

    return (
        <Box>
            <Button variant="text" sx={{width: '100%'}} onClick={constHandlePayWithCreditCard} disabled={disabled}>
                Pagar com Cartão de Crédito
            </Button>
        </Box>
    );
}

export default PayWithCreditCardButton
