import * as React from 'react';
import {CSSProperties} from 'react';
import MainLayout from '@/layouts/main';
import {Container} from '@mui/material';
import AccountData from '@/features/account/components/AccountData';
import DownloadTheApp from '@/layouts/banners/downloadTheApp';
import UserCards from '@/features/account/components/UserCards';
import UserInvoiceAddresses from '@/features/account/components/UserInvoiceAddresses';
import LoggedPage from '@/layouts/logged';
import PageTitle from '@/features/components/PageTitle';
import UserNotificationOptions from '@/features/account/components/UserNotificationOptions';

const sectionStyles: CSSProperties = {
    marginBottom: '15px'
}

const AccountPage = () => {
    return (
        <MainLayout logged={true}>
            <LoggedPage builder={user => {
                return (
                    <>
                        <Container>
                            <PageTitle text="Minha Conta"/>
                            <DownloadTheApp text="Controle suas informações, onde estiver!"/>
                            <fieldset style={sectionStyles}>
                                <legend>
                                    Dados da Conta
                                </legend>
                                <AccountData user={user}/>
                            </fieldset>
                            <fieldset style={sectionStyles}>
                                <legend>
                                    Configurações de Notificação
                                </legend>
                                <UserNotificationOptions user={user}/>
                            </fieldset>
                            <fieldset style={sectionStyles}>
                                <legend>
                                    Cartões
                                </legend>
                                <UserCards/>
                            </fieldset>
                            <fieldset style={sectionStyles}>
                                <legend>
                                    Endereços
                                </legend>
                                <UserInvoiceAddresses/>
                            </fieldset>
                        </Container>
                    </>
                )
            }}/>
        </MainLayout>
    )
}

export default AccountPage
