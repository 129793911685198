import * as React from 'react';
import {useState} from 'react';
import {ignoreAxios} from '@/utils/errors';
import {CouponResponse, TicketBlockGroup} from '@/business/event/model';
import CouponInput from '@/features/components/CouponInput';
import {applyCoupon} from '@/business/event/service';
import {SxProps} from '@mui/system/styleFunctionSx';
import {Theme} from '@mui/material/styles';

interface Props {
    event: number;
    blocks: TicketBlockGroup[];
    onCouponApplied: (data?: CouponResponse) => void;
    containerSx?: SxProps<Theme>;
}

const EventCoupon = ({event, blocks, onCouponApplied, containerSx}: Props) => {

    const [code, setCode] = useState("");

    const apply = (code: string) => {
        const blockIds: number[] = [];
        blocks.forEach(value => blockIds.push(...value.configurations.map(c => c.id)));
        applyCoupon(event, code, blockIds)
            .then(coupon => {
                setCode(coupon.code);
                onCouponApplied(coupon);
            })
            .catch(ignoreAxios)
    }

    const remove = () => {
        setCode("");
        onCouponApplied(undefined);
    }

    return <CouponInput onApply={apply} onRemove={remove} current={code} containerSx={containerSx}/>
}

export default EventCoupon
