import {createBrowserRouter} from 'react-router-dom';
import React from 'react';
import Home from './pages/Home';
import Event from '@/pages/Event';
import Checkout from '@/pages/Checkout';
import Tickets, {TablesPage} from '@/pages/Tickets';
import Account from '@/pages/Account';
import UseTermsPage from '@/pages/UseTerms';
import VipListsPage from '@/pages/VipLists';
import MaintenancePage from '@/pages/Maintenance';
import ClientPage from '@/pages/Client';
import Orders from '@/pages/Orders';
import LinkLoadingPage from '@/pages/Link';
import FastCheckout from '@/pages/FastCheckout';
import OAuthCallbackPage from "@/pages/OAuthCallback";

const routes = createBrowserRouter([
    {
        path: "",
        element: <Home/>,
    },
    {
        path: "/evento/:id",
        element: <Event/>,
    },
    {
        path: "/fast-checkout/:id",
        element: <FastCheckout/>,
    },
    {
        path: "/pedidos",
        element: <Orders/>,
    },
    {
        path: "/pedido/:id",
        element: <Checkout/>,
    },
    {
        path: "/ingressos",
        element: <Tickets/>,
    },
    {
        path: "/mesas",
        element: <TablesPage/>,
    },
    {
        path: "/conta",
        element: <Account/>,
    },
    {
        path: "/termos",
        element: <UseTermsPage/>,
    },
    {
        path: "/listas",
        element: <VipListsPage/>,
    },
    {
        path: "/organizador/:id",
        element: <ClientPage/>,
    },
    {
        path: "/code",
        element: <OAuthCallbackPage/>,
    },
    {
        path: "/link/:code",
        element: <LinkLoadingPage/>,
    },
    {
        path: "/503",
        element: <MaintenancePage/>,
    },
]);

export default routes;
