import theme from "@/config/theme/theme";
import Box from "@mui/material/Box";
import {ThemeProvider} from "@mui/material/styles";
import * as React from "react";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";

export const LoadingPage = () => {
    return <ThemeProvider theme={theme}>
        <Box sx={{flex: 1, display: 'flex', p: '10px'}}>
            <Box style={{maxWidth: '600px', margin: 'auto', textAlign: 'center'}}>
                <CircularProgress size={100}/>
                <Typography sx={{padding: '20px'}} variant="h1">
                    Estamos preparando tudo pra você, isso vai levar apenas 1 segundo...
                </Typography>
            </Box>
        </Box>
    </ThemeProvider>
}
