import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {Order, PixPayment} from '@/business/order/model';
import {Accordion, AccordionDetails, AccordionSummary, Divider, List, ListItem, ListItemText} from '@mui/material';
import TicketBlockDisplay from '@/features/event/components/commons/TicketBlockDisplay';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {formatDecimal} from '@/utils/formatters';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SecurityUpdateGoodIcon from '@mui/icons-material/SecurityUpdateGood';
import {MessageUtils} from '@/utils/message';
import Timer from '@/features/components/Timer';
import PixCode from '@/features/checkout/components/payment/PixCode';
import {getOrderStatus} from '@/business/order/service';
import {OrderStatus} from '@/business/order/domain';

const styles = {
    td: {
        paddingBottom: '10px'
    }
}

interface Props {
    order: Order;
    payment: PixPayment;
    onReady: () => void
}

export const FastCheckoutPayment = ({order, payment, onReady}: Props) => {

    const [expanded, setExpanded] = useState(false);
    const [waiting, setWaiting] = useState(true);
    const [expanded2, setExpanded2] = useState(false);

    const handleExpire = () => {
        MessageUtils.showError('O código do PIX expirou, você precisará solicitar um novo código, ou realizar novamente a compra caso ela tenha expirado.', 'PIX expirado').then(() => {
            window.location.reload();
        });
    }

    useEffect(() => {
        const interval = setInterval(() => checkOrderStatus(), 3000);
        return () => clearInterval(interval);
    });

    const checkOrderStatus = () => {
        if (!waiting) {
            return;
        }
        getOrderStatus(order.id).then((status) => {
            if (status === OrderStatus.PAID) {
                onReady();
                setWaiting(false);
                return;
            }
            if (status === OrderStatus.CANCELLED) {
                setWaiting(false);
                MessageUtils.showError("O pedido foi cancelado. Pedidos que ficam sem pagamento por muito tempo podem ser cancelados automaticamente pelo sistema, assim como pedidos com problemas de pagamento. Você pode tentar realizar novamente o pedido e revisar as informações de pafamento.", "Pedido Cancelado").then(() => {
                    window.location.reload();
                })
            }
        });
    }

    return (
        <Box>
            <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1bh-content" id="panel1bh-header">
                    <Box width="100%" justifyContent="space-between" display="flex">
                        <Typography>
                            <b>Valor Total do Pedido</b>
                        </Typography>
                        <Typography sx={{color: 'text.secondary'}}>R$ {formatDecimal(order.totalValue)}</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="subtitle2" sx={{mb: '5px'}}>
                        Itens do Pedido
                    </Typography>
                    <List sx={{width: '100%', m: 0, p: 0}}>
                        {order.items.map((a, index) =>
                            <Box key={a.id}>
                                <ListItem sx={{padding: 0}}>
                                    <ListItemText
                                        sx={{margin: 0}}
                                        primary={
                                            <TicketBlockDisplay disabled={false}
                                                                label={a.label}
                                                                value={a.unitValue}
                                                                discount={a.discount}
                                                                discountPercent={order.coupon?.discount}
                                                                actionAlign="middle"
                                                                action={<Typography variant="h4">
                                                                    <b>
                                                                        {a.quantity}
                                                                    </b>
                                                                </Typography>}/>
                                        }
                                    />

                                </ListItem>

                                {index < order.items.length - 1 && (
                                    <Divider sx={{m: '8px'}}></Divider>
                                )}
                            </Box>
                        )}
                    </List>
                </AccordionDetails>
            </Accordion>

            <Box sx={{
                textAlign: 'center',
                border: "1px lightgray solid",
                paddingX: '10px',
                paddingY: '5px',
                borderRadius: '8px',
                mt: '10px'
            }}>

                <Typography variant="subtitle2">
                    <b>Código para pagamento do Pedido</b>
                </Typography>

                <PixCode pix={payment}/>

                {waiting && (
                    <Box mt="10px">
                        <b>Fique Atento:</b> Este código expira em <Timer expiresIn={payment.expiresIn}
                                                                          onExpired={handleExpire}
                                                                          style={{
                                                                              fontSize: '2rem',
                                                                              fontWeight: 'bold',
                                                                              color: 'blue'
                                                                          }}/>
                    </Box>
                )}

                <hr/>

                <Box mb="10px">
                    <Typography variant="body1" color="text.secondary">
                        <b> Não feche essa janela</b>, seus ingressos serão apresentados aqui e enviados
                        para o seu Email após o pagamento.
                    </Typography>
                </Box>
            </Box>

            <Accordion expanded={expanded2} onChange={() => setExpanded2(!expanded2)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <b>Como pagar seu PIX?</b>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body1" color="text.secondary" component="div">
                        <table>
                            <tbody>
                            <tr>
                                <td style={styles.td}>
                                    <PhoneAndroidIcon/>
                                </td>
                                <td style={styles.td}>
                                    Escolha <b>pagar com Pix</b> no aplicativo do seu banco
                                </td>
                            </tr>
                            <tr>
                                <td style={styles.td}>
                                    <QrCode2Icon/>
                                </td>
                                <td style={styles.td}>
                                    Selecione a <i>PIX Copia e Cola </i> e <b>use o código acima</b>
                                </td>
                            </tr>
                            <tr>
                                <td style={styles.td}>
                                    <SecurityUpdateGoodIcon/>
                                </td>
                                <td style={styles.td}>
                                    Confirme as informações e <b>finalize o pagamento</b>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </Typography>

                    <Typography variant="body1" color="text.secondary" sx={{textAlign: 'center'}}>
                        Tudo pronto! Iremos reconhecer seu pagamento e entregamos seu ingresso aqui mesmo. Além
                        disso, os ingressos são enviados para o seu email e na plataforma, basta fazer login
                        usando o mesmo email informado aqui.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
