import * as React from 'react';
import {Box, ListItem} from '@mui/material';
import Button from '@mui/material/Button';
import EventCover from '@/features/components/EventCover';
import {OrderBase} from '@/business/order/model';
import {OrderStatus} from '@/business/order/domain';
import ValueLabel from '@/features/components/ValueLabel';
import {formatDateTime, formatDecimal} from '@/utils/formatters';
import OrderStatusTag from '@/features/orders/components/OrderStatusTag';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

interface Props {
    order: OrderBase,
    onCheckoutClick: VoidFunction,
    onDetailsClick: VoidFunction,
}

const OrderListItem = ({order, onCheckoutClick, onDetailsClick}: Props) => {
    return (
        <ListItem key={order.id} sx={{border: '1px solid #E0E0E0', p: 0, mb: '10px'}}>
            <Box>
                <EventCover src={order.event.cover} label={order.event.name}/>
                <Box sx={{p: '10px 20px'}}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            whiteSpace: 'nowrap',
                            flexWrap: 'wrap',
                            gap: '10px',
                            marginBottom: '10px'
                        }}>
                        <ValueLabel label="Data do Pedido" value={formatDateTime(order.date)}/>
                        <ValueLabel label="Valor Total" value={formatDecimal(order.totalValue, "R$ ")}/>
                    </Box>
                    <OrderStatusTag status={order.status}/>
                    <Box sx={{textAlign: 'center', mt: '10px'}}>
                        <Button variant="text" onClick={onDetailsClick}>
                            Detalhes do Pedido
                        </Button>
                        {order.status === OrderStatus.OPEN && (
                            <Box sx={{mt: '10px'}}>
                                <Button variant="contained" startIcon={<ShoppingCartIcon/>}
                                        onClick={onCheckoutClick}>
                                    Finalizar Compra
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </ListItem>
    )
}

export default OrderListItem;
