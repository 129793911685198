import React from 'react';
import {Control, Controller} from 'react-hook-form';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';
import {SelectProps} from '@mui/material/Select/Select';
import {FormControl, FormHelperText, InputLabel, Select} from '@mui/material';

export type ControlledSelectProps = {
    label: string,
    name: string,
    control: Control<any>,
    marginTop?: string
    helperText?: string | null,
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
} & Omit<SelectProps, 'name' | 'value' | 'onChange' | 'label'>

const ControlledSelect = (props: ControlledSelectProps) => {
    const {name, control, label, helperText, rules, marginTop, ...selectProps} = props;
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({field: {onChange, name, value}}) => (
                <FormControl fullWidth sx={{marginTop: marginTop ?? '16px'}}>
                    <InputLabel id="form-label">{label}</InputLabel>
                    <Select
                        labelId="form-label"
                        label={label}
                        name={name}
                        value={value}
                        onChange={onChange}
                        {...selectProps}
                    >
                    </Select>
                    {helperText && (
                        <FormHelperText error={selectProps.error}>{helperText}</FormHelperText>
                    )}
                </FormControl>
            )}
        />
    )
}

export default ControlledSelect;
