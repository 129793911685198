import * as React from 'react';
import {Event} from '@/business/event/model';
import Box from '@mui/material/Box';
import {Container, useMediaQuery} from '@mui/material';
import EventCover from '@/features/components/EventCover';
import image from '@/assets/img/party.jpg';


export interface Props {
    event: Event
}

const EventDetailsCover = ({event}: Props) => {
    const height = 540;
    const properties = {
        p: '0 !important',
        borderRadius: '10px',
        zIndex: '1',
    };
    const isSm = useMediaQuery('(max-width:950px)');

    const cover = () => {
        return (<Box sx={{padding: isSm ? null : '0 60px'}}>
            <EventCover src={event.cover} radius="10px"/>
        </Box>);
    }

    return (
        <Box>
            <Box sx={{display: {xs: 'none', lg: 'inherit'}}}>
                <Box height={height} sx={{overflow: 'hidden'}}>
                    <img src={event.cover ?? image} width='100%' height={height}
                         style={{
                             objectFit: 'cover',
                             filter: 'blur(15px)',
                             transform: 'scale(1.1)',
                             position: 'relative',
                             zIndex: '-1'
                         }} alt={event.name}/>
                </Box>
            </Box>
            <Container sx={{
                ...properties,
                marginTop: `-${height + 10}px`,
                display: {xs: 'none', lg: 'inherit'}
            }}>
                {cover()}
            </Container>
            <Container sx={{display: {xs: 'inherit', lg: 'none'}, marginTop: '20px'}}>
                {cover()}
            </Container>
        </Box>
    )
}

export default EventDetailsCover
