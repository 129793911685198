import * as React from 'react';
import {Event} from '@/business/event/model';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {AccordionDetails, AccordionSummary} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import VideoPlayer from '@/features/components/VideoPlayer';

export interface Props {
    event: Event
}

const EventDescription = ({event}: Props) => {
    const map = event.ticketsConfiguration?.map;

    return (
        <Box sx={{whiteSpace: 'pre-line'}}>
            {(event.description || map || event.videoUrl) && (
                <Typography variant="h5" sx={{marginBottom: '15px'}}>
                    <b>{event.title ?? 'Descrição do evento'}</b>
                </Typography>
            )}
            {map && (
                <Box sx={{paddingBottom: '20px', paddingTop: '10px'}}>
                    <Accordion defaultExpanded={false} sx={{border: '1px solid'}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>
                                Mapa do Evento
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <img src={map} alt="Mapa do Evento com a Distribuição dos Blocos" style={{width: '100%'}}/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}

            <Box mb="20px">
                <div className="ck-content " dangerouslySetInnerHTML={{__html: event.description ?? ''}}>
                </div>
            </Box>
            <VideoPlayer url={event.videoUrl}/>
        </Box>
    )
}

export default EventDescription
