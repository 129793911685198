import axios from 'axios';
import fileDownload from 'js-file-download';
import {trackPromise} from 'react-promise-tracker';

export function download(url: string, fileName: string): Promise<void> {
    return trackPromise(
        axios.get(url, {
            responseType: 'blob',
            headers: {'no_auth': true},
        }).then((res) => {
            fileDownload(res.data, fileName)
        })
    );
}
