import * as React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import Header from '@/layouts/header';
import Box from '@mui/material/Box';
import theme, {darkTheme} from '@/config/theme/theme';
import Footer from '@/layouts/footer';

interface Props {
    children: React.ReactNode,
    logged?: boolean;
    paddingTop?: string | number;
}

const MainLayout = (props: Props) => {
    return (
        <>
            <ThemeProvider theme={darkTheme}>
                <Header logged={props.logged}/>
            </ThemeProvider>
            <ThemeProvider theme={theme}>
                <Box sx={{paddingTop: props.paddingTop ?? '30px', paddingBottom: '30px', flex: 1}}>
                    {props.children}
                </Box>
                <Footer></Footer>
            </ThemeProvider>
        </>
    )
}

export default MainLayout
