import * as React from 'react';
import {Chip} from '@mui/material';
import {OrderStatus} from '@/business/order/domain';
import {OrderStatusColors, OrderStatusLabels} from '@/business/order/utils';

interface Props {
    status: OrderStatus
}

const OrderStatusTag = ({status}: Props) => {
    return (
        <Chip label={OrderStatusLabels[status]} color={OrderStatusColors[status]}
              sx={{width: '100%', fontWeight: 'bold'}}/>
    )
}

export default OrderStatusTag;
