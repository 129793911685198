import {User} from '@/business/security/model';
import GoogleIcon from '@mui/icons-material/Google';
import Button from '@mui/material/Button';
import * as React from 'react';
import {useWhiteLabelClientContext} from "@/layouts/contexts/white-label-client/WhiteLabelClientContext";
import {useGoogleAuth} from "@/features/login/hooks/useGoogleAuth";

interface Props {
    onLogged?: (user: User) => void
}

const LoginWithGoogle = ({onLogged}: Props) => {

    const {client} = useWhiteLabelClientContext();
    const {login} = useGoogleAuth(onLogged);

    if (client) {
        return null;
    }

    return (
        <Button variant="text" startIcon={<GoogleIcon/>} onClick={login} sx={{width: '100%'}}>
            Entrar com Google
        </Button>
    )
}

export default LoginWithGoogle;
