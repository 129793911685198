import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {Client} from "@/business/client/model";
import {Environment} from "@/config";
import {setClientId} from "@/config/axios";
import {findClientByWhiteLabelUrl} from "@/business/client/service";
import {ClientNotFound} from "@/layouts/contexts/white-label-client/ClientNotFound";
import {LoadingPage} from "@/utils/components/LoadingPage";

const tryGetCache = (host: string): Client | null => {
    try {
        const cache = localStorage.getItem(`MP_CL_${host}`);
        return cache ? JSON.parse(cache) : null;
    } catch (_) {
        return null;
    }
}

const setCache = (host: string, client: Client | null) => {
    try {
        if (client) {
            localStorage.setItem(`MP_CL_${host}`, JSON.stringify(client));
        } else {
            localStorage.removeItem(`MP_CL_${host}`);
        }
    } catch (_) {
    }
}

export interface WhiteLabelClientContextType {
    client: Client | null;
}

export interface WhiteLabelClientContextProps {
}

export const WhiteLabelClientContext = createContext<WhiteLabelClientContextType | null>(null);

export const WhiteLabelClientProvider = ({children}: PropsWithChildren<WhiteLabelClientContextProps>) => {

    const [loading, setLoading] = useState(true);
    const [invalid, setInvalid] = useState(false);
    const [client, setClient] = useState<Client | null>(null);
    const host = window.location.host;

    const handleSetClient = useCallback((client: Client | null) => {
        setClientId(client?.id ?? null);
        setClient(client);
        setLoading(false);
    }, []);

    useEffect(() => {
        const base = Environment.BaseUrl;
        setInvalid(false);
        setLoading(true);

        if (host === base) {
            handleSetClient(null);
        } else {
            const cached = tryGetCache(host);
            if (cached) {
                handleSetClient(cached);
            }
            findClientByWhiteLabelUrl(host).then(value => {
                setCache(host, value);
                setInvalid(!value);
                handleSetClient(value);
            }).catch(_ => {
                setInvalid(true);
                setCache(host, null);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [handleSetClient, host]);

    if (loading) {
        return <LoadingPage/>
    }
    if (invalid) {
        return <ClientNotFound/>
    }
    const state: WhiteLabelClientContextType = {client}
    return (
        <WhiteLabelClientContext.Provider value={state}>{children}</WhiteLabelClientContext.Provider>
    );
};

export const useWhiteLabelClientContext = () => {
    const context = useContext(WhiteLabelClientContext);
    if (!context) {
        throw new Error(
            "useWhiteLabelClientContext must be used within an WhiteLabelClientProvider"
        );
    }
    return context;
};
