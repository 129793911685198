import {CardType} from '@/business/payment/model';

export const CardTypeLabels: { [key in CardType]: any } = {
    [CardType.AMERICAN_EXPRESS]: 'Cartão American Express',
    [CardType.VISA]: 'Cartão Visa',
    [CardType.MASTERCARD]: 'Cartão Mastercard',
    [CardType.DINERS]: 'Cartão Diners',
    [CardType.DISCOVER]: 'Cartão Discover',
    [CardType.RUPAY]: 'Cartão Rupay',
    [CardType.JCB]: 'Cartão JBC',
    [CardType.MAESTRO]: 'Cartão Maestro',
    [CardType.OUTROS]: 'Cartão de Crédito',
};
