import {PaymentMethod, PaymentMethodType} from "@/business/payment/model";
import {createContext, PropsWithChildren, useCallback, useContext, useEffect, useState} from "react";
import {getDefaultPaymentMethod} from "@/business/payment/service";

export interface SelectedPaymentMethodContextType {
    selectedPaymentType: PaymentMethodType | null;
    selectedPayment: PaymentMethod | null;
    setSelectedPayment: (value: PaymentMethod | null) => void;
    setSelectedPaymentType: (value: PaymentMethodType | null) => void;
    showTaxes: boolean;
}

export interface SelectedPaymentMethodContextProps {
    event: number;
    fastCheckout?: boolean;
    showTaxes?: boolean;
}

export const SelectedPaymentMethodContext = createContext<SelectedPaymentMethodContextType | null>(null);

export const SelectedPaymentMethodProvider = (
    {
        event,
        fastCheckout = false,
        showTaxes = true,
        children
    }: PropsWithChildren<SelectedPaymentMethodContextProps>
) => {

    const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentMethodType | null>(null);
    const [selectedPayment, setSelectedPayment] = useState<PaymentMethod | null>(null);

    useEffect(() => {
        getDefaultPaymentMethod(event, fastCheckout).then(value => {
            setSelectedPayment(value);
            setSelectedPaymentType(value?.type);
        });
    }, [event, fastCheckout]);

    const handleSelectConfig = useCallback(((value: PaymentMethod | null) => {
        setSelectedPayment(value);
        if (value) {
            setSelectedPaymentType(value.type);
        }
    }), [setSelectedPaymentType, setSelectedPayment])

    const state: SelectedPaymentMethodContextType = {
        selectedPaymentType,
        setSelectedPaymentType,
        selectedPayment,
        setSelectedPayment: handleSelectConfig,
        showTaxes
    }

    return (
        <SelectedPaymentMethodContext.Provider value={state}>{children}</SelectedPaymentMethodContext.Provider>
    );
};

export const useSelectedPaymentMethodContext = () => {
    const context = useContext(SelectedPaymentMethodContext);
    if (!context) {
        throw new Error(
            "orderContext must be used within an OrderContextProvider"
        );
    }
    return context;
};
