import {Authentication, NotificationOptions, TokenMetadata, User, UserUpdateData} from '@/business/security/model';
import axios from 'axios';
import {trackPromise} from 'react-promise-tracker';

const TOKEN_KEY = "MPT_TK";
const ADMIN_TOKEN_KEY = "MPT_A_TK";

export function getToken(): string | null {
    try {
        return localStorage.getItem(TOKEN_KEY)
    } catch (e) {
        return null;
    }
}

export function getAdminToken(): string | null {
    return localStorage.getItem(ADMIN_TOKEN_KEY)
}

export function setToken(token: string | undefined = undefined) {
    if (token) {
        localStorage.setItem(TOKEN_KEY, token)
    } else {
        localStorage.removeItem(TOKEN_KEY);
    }
}

export function setAdmin(token: string | undefined = undefined) {
    if (token) {
        localStorage.setItem(ADMIN_TOKEN_KEY, token)
    } else {
        localStorage.removeItem(ADMIN_TOKEN_KEY);
    }
}

export function login(email: string, password: string): Promise<User> {
    const payload = {'username': email, 'password': password}
    return trackPromise(
        axios.post<Authentication>('sign-in', payload).then(value => {
            setToken(value.data.token);
            setAdmin(value.data.adminToken);
            return value.data.subject;
        })
    );
}

export function loginToken(token: string, provider: string): Promise<User> {
    const payload = {token, provider}
    return trackPromise(
        axios.post<Authentication>('token-sign-in', payload).then(value => {
            setToken(value.data.token);
            setAdmin(value.data.adminToken);
            return value.data.subject;
        })
    );
}

export function loginByAuthCode(code: string, redirectUrl: string, provider: string): Promise<User> {
    const payload = {code, provider, redirect_url: redirectUrl}
    return axios.post<Authentication>('token-sign-in', payload).then(value => {
        setToken(value.data.token);
        setAdmin(value.data.adminToken);
        return value.data.subject;
    });
}

export function signup(data: any): Promise<User> {
    return trackPromise(
        axios.post<Authentication>('sign-up', data).then(value => {
            setToken(value.data.token);
            return value.data.subject;
        })
    );
}

export function me(): Promise<User | undefined> {
    return axios.get<User>('account').then(value => {
        if (value.data.id) {
            return value.data;
        }
        return undefined
    });
}

export function updateUser(data: UserUpdateData): Promise<User> {
    return trackPromise(
        axios.post<User>('account', data).then(value => {
            return value.data;
        })
    )
}


export function changePassword(password: string, current: string): Promise<void> {
    return trackPromise(
        axios.post<void>('account/password', {password, current}).then(value => {
            return value.data;
        })
    )
}


export function sendToken(email: string): Promise<TokenMetadata> {
    return trackPromise(
        axios.get<TokenMetadata>('recovery', {params: {email}}).then(value => {
            return value.data;
        })
    )
}

export function validateToken(session: string, token: string): Promise<void> {
    return trackPromise(
        axios.post<void>('recovery/token', null, {params: {session, token}}).then(value => {
            return value.data;
        })
    )
}

export function resetPassword(session: string, token: string, password: string): Promise<void> {
    return trackPromise(
        axios.post<void>('recovery/password', {password}, {params: {session, token}}).then(value => {
            return value.data;
        })
    )
}

export function getNotificationOptions(): Promise<NotificationOptions> {
    return trackPromise(
        axios.get<NotificationOptions>('/account/notification-options').then(value => {
            return value.data;
        })
    )
}

export function saveNotificationOptions(options: NotificationOptions): Promise<void> {
    return trackPromise(
        axios.post<void>('/account/notification-options', options).then(() => {
        })
    )
}
