import * as React from 'react';
import {useState} from 'react';
import {TicketBlock} from '@/business/event/model';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TicketBlockDisplay from '@/features/event/components/commons/TicketBlockDisplay';
import {TicketBlockStatus} from '@/business/event/domain';

export interface Props {
    block: TicketBlock,
    onAdd: (block: number) => number,
    onRemove: (block: number) => number,
    initial?: number | undefined,
    discount?: number;
    discountPercent?: number
}

const Available = ({count, onAdd, onDelete}: { count: number, onAdd: () => void, onDelete: () => void }) => {
    return <Box>
        <Button variant="outlined" size="small" sx={{minWidth: '40px'}} onClick={onDelete}
                disabled={count === 0}>
            -
        </Button>
        <Typography component="span" variant="body2" sx={{p: '0 8px', fontWeight: 'bold'}}>
            {count}
        </Typography>
        <Button variant="outlined" size="small" sx={{minWidth: '40px'}} onClick={onAdd}>
            +
        </Button>
    </Box>
}


const TicketBlockDefaultItem = ({block, onAdd, onRemove, initial, discountPercent, discount}: Props) => {

    const [total, setTotal] = useState(initial ?? 0);

    const add = () => {
        setTotal(onAdd(block.id));
    }

    const remove = () => {
        setTotal(onRemove(block.id));
    }

    const getAction = () => {
        if (block.status === TicketBlockStatus.DISPONIVEL) {
            return <Available count={total} onAdd={add} onDelete={remove}/>;
        }
        if (block.status === TicketBlockStatus.ESGOTADO) {
            return <Typography component="span" variant="body1" color='error.light'>
                <b>Esgotado</b>
            </Typography>
        }
        return <Typography component="span" variant="body1" color='info.light'>
            <b>Em Breve</b>
        </Typography>;
    }

    return (
        <TicketBlockDisplay
            discount={discount}
            discountPercent={discountPercent}
            disabled={block.status !== TicketBlockStatus.DISPONIVEL}
            {...block}
            action={getAction()}/>
    )
}

export default TicketBlockDefaultItem
