import * as React from 'react';
import {useEffect, useState} from 'react';
import {Event, Table} from '@/business/event/model';
import {Dialog, DialogContent, Divider, List, ListItem, useMediaQuery} from '@mui/material';
import {getReservedTables} from '@/business/event/service';
import {reserveTable} from '@/business/tickets/service';
import {ignoreAxios} from '@/utils/errors';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogHeader from '@/features/components/DialogHeader';
import {formatDecimal} from '@/utils/formatters';
import {createOrder} from '@/business/order/service';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';

export interface Props {
    event: Event
    onClose: () => void;
    link: string | null;
}

const EventTablesModal = ({event, onClose, link}: Props) => {

    const [reserved, setReserved] = useState(event.tablesConfiguration?.tables.map(a => a.id) ?? []);
    const isMobile = useMediaQuery('(max-width:475px)');
    const navigate = useNavigate();

    useEffect(() => {
        getReservedTables(event.id).then(value => {
            setReserved(value);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleReserve = (table: Table) => {
        if (!table.value) {
            reserveTable(table.id).then(() => {
                setReserved([...reserved, table.id]);
                Swal.fire({
                    title: 'Mesa reservada com sucesso',
                    text: 'Já reservamos a sua mesa, agora é se preparar e aproveitar a festa. Se quiser, você também pode adicionar os convidados que irão dividir a mesa com você no menu de Reservas de Mesa',
                    icon: 'success',
                    confirmButtonText: 'Ver Reservas',
                    cancelButtonText: 'Fechar',
                    showCancelButton: true,
                }).then(value => {
                    if (value.isConfirmed) {
                        navigate(`/mesas?event=${event.id}`);
                    }
                })
            }).catch(ignoreAxios);
        } else {
            createOrder({event: event.id, tables: [table.id]}, link).then(value => {
                navigate(`/pedido/${value.id}`, {state: value});
            }).catch(ignoreAxios);
        }
    }

    return (
        <Dialog onClose={onClose} open={true}>
            <DialogHeader onClose={onClose}>
                Mesas do Evento {event.name}
            </DialogHeader>
            <DialogContent>
                {event.tablesConfiguration?.map && (
                    <Box sx={{marginBottom: '15px'}}>
                        <img src={event.tablesConfiguration?.map} style={{width: '100%'}} alt={event.name}/>
                    </Box>
                )}
                <List sx={{width: '100%'}}>
                    {event.tablesConfiguration?.tables.map((table, index) =>
                        <Box key={table.id}>
                            <ListItem sx={{paddingX: 0}}>
                                <Box sx={{width: '100%', display: isMobile ? 'block' : 'flex'}}>
                                    <Box flex={1} sx={{whiteSpace: 'no-wrap', mr: '10px'}}>
                                        <Typography variant="body1" component="div">
                                            {table.name}
                                            <Typography variant="body2" fontSize="1.2vh" color="text.secondary">
                                                {table.seats} CADEIRAS
                                            </Typography>
                                        </Typography>
                                        {!!table.value && (
                                            <Typography variant="subtitle1" component="div">
                                                <b>{formatDecimal(table.value, 'R$ ')}
                                                    {table.userTax && (
                                                        (<Typography variant="body2" component="span" sx={{
                                                            fontSize: '10px',
                                                            fontWeight: 300
                                                        }}>
                                                            &nbsp;+TAXA: {formatDecimal(table.userTax, 'R$ ')}
                                                        </Typography>)
                                                    )}
                                                </b>
                                            </Typography>
                                        )}
                                    </Box>
                                    <Box sx={{
                                        textAlign: 'right',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        {!reserved.includes(table.id) && (
                                            <Button variant="outlined" onClick={() => handleReserve(table)}
                                                    sx={{width: isMobile ? '100%' : '120px'}}>
                                                {'Reservar'}
                                            </Button>
                                        )}
                                        {reserved.includes(table.id) && (
                                            <Button variant="outlined" color="error" disabled={true}
                                                    sx={{width: isMobile ? '100%' : '120px'}}>
                                                Reservado
                                            </Button>
                                        )}
                                    </Box>
                                </Box>
                            </ListItem>
                            {index < event.tablesConfiguration!.tables.length - 1 && (
                                <Divider></Divider>
                            )}
                        </Box>
                    )}
                </List>
            </DialogContent>
        </Dialog>
    )
}

export default EventTablesModal
